import React, { useState } from "react";
import styles from "./ChangePassword.module.scss";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Link, useHistory  } from "react-router-dom";
import { validateAll } from 'indicative/validator';
import { Form, FormGroup, Label, Input, FormFeedback, FormText,Alert } from 'reactstrap';
import Constant from '../../constant.json';
import axios from 'axios';
import { useAuth } from "../../context/auth";
import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';
import MetaTags from 'react-meta-tags';
import Swal from 'sweetalert2';


const ChangePassword = () => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const { setAuthTokens } = useAuth();
  const [state, setState] = useState({
    password: '',
    password_confirmation: '',
    errors: {},
  })

  const reducers = {
    session: sessionReducer
  };
  const reducer = combineReducers(reducers);

  
  const {
    password_confirmation, password, errors,
  } = state;
  const onChangeConfirmPassword = (value) => {

    setState(prevState => ({
      ...prevState,
      password_confirmation: value
    }));
  };
  const onChangePassword = (value) => {

    setState(prevState => ({
      ...prevState,
      password: value
    }));
  };

  // Login button Click
  const onSubmit = (e) => {
    e.preventDefault();
  

    const messages = {
      'password.required': 'Password cannot be empty.',
      'password.confirmed': 'Password and Confirm Password cannot match.',
      'password_confirmation.required': 'Confirm Password cannot be empty.',
      
    };
    const rules = {
      password: 'required | confirmed',
      password_confirmation: 'required',
      
    };
    validateAll(state, rules, messages)
      .then(() => {
        setIsLoading(true);
        postChangePassword()
          .then(function (response) {
            
            if (response.data.status == "1") {
              Swal.fire({
                icon: 'success',
                title: 'Password reset successfully',
                showConfirmButton: false,
                timer: 1500
              });
              setLoggedIn(true);  
              setIsLoading(false);
              history.push("/dashboard");
            } else {
              const formattedErrors = {};
              formattedErrors['login_msg'] = response.data.message;
              setIsLoading(false);
            }
          })
          .catch(err => {
            
            const formattedErrors = {};
            formattedErrors['login_msg'] = "Email or Password not match."
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: formattedErrors['login_msg'],
            });
            setIsLoading(false);
          }) 

      })
      .catch(errors => {
        const formattedErrors = {
        };
        errors.forEach(error => formattedErrors[error.field] = error.message);
        setState({
          ...state,
          errors: formattedErrors
        });
      })

  }

  async function postChangePassword() {
    const query = new URLSearchParams(window.location.search);
    const id = query.get('id');
    const decodedEmail = Buffer.from(id, 'base64').toString();
    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/auth/resetpassword-frontend',
      data: {
        password: password,
        email: decodedEmail,
      }
    }
    let rs = await axios(config);
    return rs
  }

  const [focused, setFocus] = useState("");
  return (
    
    <React.Fragment>
      <MetaTags>
        <title>Change Password</title>
        <meta name="description" content="Blaze Broker" />
        <meta property="og:title" content="Blaze Broker - Change Password" />
      </MetaTags>
      <span className="d-none d-md-block">
        <Nav/>
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <section className={styles.register_container}>
        <div className={styles.shadow_box}>
          <div className={styles.content_container}>
            <h1 className={styles.top_heading}>Change Password</h1>
            
            <div
              className={`${
                focused === "password"
                  ? styles.custom_input_focus
                  : styles.custom_input
              }`}
            >
              <span>
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
              <Input
                invalid = {errors.password}
                onFocus={() => setFocus("password")}
                onBlur={() => setFocus("")}
                type="password"
                placeholder="Password"  
                onChange={(e) => onChangePassword(e.target.value)}
              />
            </div>
            <FormFeedback style={{display:'block',textAlign:'center'}}>{errors.password}</FormFeedback>

            <div
              className={`${
                focused === "confirmPassword"
                  ? styles.custom_input_focus
                  : styles.custom_input
              }`}
            >
              <span>
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </span>
              <Input
                invalid = {errors.password_confirmation}
                onFocus={() => setFocus("confirmPassword")}
                onBlur={() => setFocus("")}
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => onChangeConfirmPassword(e.target.value)}
              />
            </div>
            <FormFeedback style={{display:'block',textAlign:'center'}}>{errors.password_confirmation}</FormFeedback>  

            <div className={styles.btn_container} >
                <button className="btn" onClick={(e) => onSubmit(e)}>
                  <span>Submit</span>
                </button>
            </div>
          </div>
          
          
        </div>
      </section>
    </React.Fragment>
  );
};
export default ChangePassword;
