import React,  { useState,useEffect } from "react";
import styles from "./myOrder.module.scss";
import SideNav from "../../Components/Admin/SideNav/SideNav";
import TopNav from "../../Components/TopNav/TopNav";
import { Container, Row, Col, Button,Input } from "reactstrap";
import axios from "axios";
import Constant from '../../constant.json';
import { useAuth } from "../../context/auth";
import OrdersList from "../../Components/Admin/Orders/OrdersList";
import dp from "../../Assets/dp.jpg";
import NavVenderSm from "../../Components/Admin/NavVenderSm/NavVenderSm";
import { Card, CardBody, CardTitle } from "reactstrap";
import Pagination from '../../Components/paginetion';
const Orders = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [OrderData, setOrderData] = useState({});
  const [state, setState] = useState({
    
    pageSize : 10,
    pageOfItems: [],
    status_filter:''
    
    
  })
  const {
    pageSize,pageOfItems,status_filter
  } = state;
  useEffect(() => {
    GetOrderList();
    
 
  },[status_filter]);
  const handleChangestatus =  (e) => {
    let search = e.target.value;
    setState({
        ...state,
        status_filter: search
    })
}
  async function GetOrderList() {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/products/allorderlist',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    return await axios(config)
    .then(function (response) {
      let result = response.data;
      setOrderData(result.data);
      setIsLoading(false);
      
    }) 
    
  }
  let reloadtable = () => {
    GetOrderList();
  } 
  function onpageChange(pageOfItems) {
  
    setState({
      ...state,
      pageOfItems: pageOfItems
    });
    
  }
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div className={styles.main_div}>
      <span className="d-none d-md-block">
        <TopNav />
      </span>
      <span className="d-block d-md-none">
        <NavVenderSm />
      </span>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Container className={`${styles.container} pt-4 mb-4 mr-0 ml-0 p-0`} style={{ background: 'white' }}>
        <Row>
          <Col xs={2} className="d-none d-md-block">
            <SideNav />
          </Col>
          <Col xs={12} md={12} sm={12}>
            <div>
              <Card className={`${styles.card} mt-4  `} >
              <Row>
                <Col xs={12} md={7} sm={7}>
                <CardTitle className={`${styles.headingOrder} ml-4 mt-2`}>
                ORDERS
                  
                </CardTitle>
                </Col>
                <Col xs={12} md={4} sm={4}> 
                <Input
                      className={`${styles.input} mt-2`}
                      type="select"
                      name="order_status"
                      onChange={(e) => handleChangestatus(e) }
                      
                    >
                      <option value="">Select Order Status</option>
                      <option value="1">Confirm</option>
                      <option value="0">Pending</option>
                    </Input>
                    </Col>
                    </Row>

                <CardBody>
                  <Container className="p-0" className={styles.tablecontainer}>
                    <Row className={styles.row}>
                    {/* <Col xs={1} md={1}>
                        
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          No.
                        </h6>
                      </Col> */}
                      <Col xs={3} md={3}>
                        
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Product Name
                        </h6>
                      </Col>
                      <Col xs={2} md={2}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Seller Name
                        </h6>
                      </Col>
                      <Col xs={1} md={1}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Price
                        </h6>
                      </Col>

                      <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.productHead)}>
                        Email
                        </h6>
                      </Col>
                      {/* <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.productHead)}>
                        Contact
                        </h6>
                      </Col> */}
                      <Col xs={1} md={1}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Qty
                        </h6>
                      </Col>
                      <Col xs={3} md={3}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Confirm
                        </h6>
                      </Col>
                      
                    </Row>
                    {
                      
                      OrderData.length > 0 ?
                      pageOfItems.map((item) => (
                        <OrdersList image={dp} imgDetail="Mariuana" reloadtable={reloadtable}  OrderData={item} />
                      )
                      ) : "No data found"
                    }
                    
                  </Container>
                  {
                  OrderData.length > 0 ?
                  <div className="pagination-row">
                    <Pagination items={OrderData} onChangePage={onpageChange}  pageSize={pageSize} statusfilter={status_filter} />
                </div>
                : ''
                }
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Orders;
