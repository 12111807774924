import React, { useState, useEffect } from "react";
import trash from "../../Assets/delete.svg";
import styles from "./cart.module.scss";
import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import delivery from "../../Assets/delivery.svg";
import { useHistory } from "react-router-dom";
import ProductDetails from "../../Pages/ProductDetails/ProductDetails";
import NumericInputCart from "../NumericInputCart/NumericInputCart";
import update from 'immutability-helper';
import Constant from '../../constant.json';
import axios from "axios";
const Cart = (props) => {
  const [numericInput, setNumericInput] = useState(0);
  const [numerictest, setNumerictest] = useState(true);
  const history = useHistory();
  const [state, setState] = useState({
    products : []
    
  })
  const {
    products
  } = state;
  const product = props.product;
  useEffect(() => {
    const cart = localStorage.getItem("cart");
    setCartItems(JSON.parse(cart));
    
  }, [numerictest]);
  const [cartItems, setCartItems] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [id, setId] = useState(null);
  const toggle = () => setModal(!modal);
  const showDetails = (id) => {
    setisLoading(true);
    GetProductDetails(id).then(async () => {
      setId(id);
      setModal2(true); 
      setisLoading(false);     
    });
    
    // setId(id);
    // setModal2(true);
  };
  const GetProductDetails = async (productId) => {
    const config = {
      method: 'get',
      url: Constant.API_URL + '/api/products/'+productId,
      
    }
    await axios(config)
    .then( async (response) => {
      let result = response.data;
      if(result.status == 1){
        setState({
          ...state,
          products: result.data
        });
        //products.push(result.data);
        return true;
      }
    });
    
  }
  // useEffect(() => {
  // //  GetProductDetailssinle()
  // },[]);
  // const GetProductDetailssinle = async () => {
  //   const config = {
  //     method: 'get',
  //     url: Constant.API_URL + '/api/products/'+props.productId,
      
  //   }
  //   await axios(config)
  //   .then( async (response) => {
  //     let result = response.data;
  //     if(result.status == 1){
  //       setState({
  //         ...state,
  //         product: result.data
  //       });
        
  //     }
  //   });
    
  // }
  
  const removeProduct = async () => {
    await deleteCartItem().then( async function (response){
      let result = response.data;
      if(result.status == 1){
          const deleteIndex = cartItems.findIndex(
            (x,i) => i === props.rowid
          );
          if (deleteIndex > -1) {
            cartItems.splice(deleteIndex, 1);
            //history.go(0);
            //setNumerictest(!numerictest)
          }
          localStorage.setItem("cart", JSON.stringify(cartItems));
      
          const cart = localStorage.getItem("cart");
          setCartItems(JSON.parse(cart));
          props.setUpdate(!props.update);
      }
    });
    
  };
  const deleteCartItem = async() =>{
    const config = {
      method: 'delete',
      url: Constant.API_URL + '/api/cartitem/'+props.CartId,
   
    }
    const result = await axios(config);
    return result;
  }
  const UpdateCartItem = async (value) =>{
    const config = {
      method: 'put',
      url: Constant.API_URL + '/api/cartitem/'+props.CartId,
      data :{
        qty :value 
      }
    }
    const result = await axios(config);
    return result;
  }
  const setNumericInputCustome = async(value) =>{
    await UpdateCartItem(value).then(function (response){
      let result = response.data;
      if(result.status == 1){
          const deleteIndex = cartItems.findIndex(
            (x,i) => i === props.rowid
          );
          
          const item = {
            product,
            qty: value,
            price: product.price * value,
            uniqueID : props.uniqueID,
            CartId : props.CartId
          };
          const updatedEmployees = update(cartItems, {$splice: [[deleteIndex, 1, item]]});  // array.splice(start, deleteCount, item1)
          
          setCartItems(updatedEmployees)
          setNumerictest(!numerictest)
          localStorage.setItem("cart", JSON.stringify(updatedEmployees));
          props.setCartItemscustome(updatedEmployees)
          
          props.setUpdate(!props.update);
         // history.go(0);
        }
    })
 
}
  return (
    <>
    <ProductDetails id={props.productId} modal={modal2} products={products} cart={true} setModal={setModal2} />
      <div className={styles.wishitem_container}>
        <div className={styles.wishitem_left_section} onClick={(e) => showDetails(props.productId)}>
          <img src={props.image} className={styles.image2} alt="food" />
          <div className={styles.wishitem_detail}>
            <h6>{props.name}</h6>
            <p>{`${props.price}$ USD`}</p>
          </div>
        </div>
        <div className={isLoading ? "loader" : "loaderhide"}></div>
        <hr className={`${styles.seprater} d-block d-md-none`} />
        {/* <div className={`${styles.wishitem_qty_status} d-block d-md-none`}> */}
        <Row>
          <Col xs="6">
            <div className={styles.wishitem_qty}>
              <h6 >Qty </h6>
              {/* <p>{`${props.qty}x`} </p> */}
            
              <NumericInputCart setNumericInput={setNumericInputCustome} productId={props.productId} currentValue={props.qty} remaining_stock={product.remaining_stock} />
            </div>
          </Col>

          <Col xs="6">
            <div className={styles.wishitem_status}>
              <h6> Status</h6>
              <p>Incomplete</p>
            </div>
          </Col>
        </Row>

        {/* </div> */}
        <hr className={`${styles.seprater} d-block d-md-none mb-4`} />

        <div className={styles.wishitem_btn_main}>
          <div className={styles.wishitem_right_section}>
            <button className="btn" onClick={(e) => removeProduct()}>
              {/* <img src={trash} alt="trash" /> */}
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default Cart;
