import React, { useState,useEffect } from "react";
import styles from "./productpage.module.scss";
import SideNav from "../../Components/Admin/SideNav/SideNav";
import TopNav from "../../Components/TopNav/TopNav";
import location from "../../Assets/location.svg";
import searchIcon from "../../Assets/search.svg";
import { Container, Row, Col, Button, Table } from "reactstrap";
import { Card, CardBody, CardTitle, Badge } from "reactstrap";
import trendChart from "../../Assets/top-trend.svg";
import dp from "../../Assets/mari.png";
import axios from "axios";
import Constant from '../../constant.json';
import NavVenderSm from "../../Components/Admin/NavVenderSm/NavVenderSm";
import overallTrendChart from "../../Assets/overall-trend.svg";
import essentialImage from "../../Assets/essential-tips.svg";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Pagination from '../../Components/paginetion';
import MyProduct from "../../Components/Admin/MyProduct/MyProduct";
//import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./override.scss";
import { useAuth } from "../../context/auth";
import { Input } from "reactstrap";
import Product from "../../Components/Products/Product";

const AdminProductPage = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [ProductType, setProductType] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle2 = () => setModal(!modal);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [state, setState] = useState({
    ProductData: {},
    pageSize : 10,
    pageOfItems: [],
    
    
  })
  const {
    ProductData,pageSize,pageOfItems
  } = state;
  useEffect(() => {
    GetProductType();
    GetProductList();
  },[]);
  const GetProductType = async () => {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/product_type',
      headers: {
        'Content-Type': 'application/json',
        
    },
    }
    await axios(config)
    .then(async function (response) {
      let data_result = response.data;
      setProductType(data_result.data)
      
    }) 
    setIsLoading(false);
  }
  async function GetProductList() {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/products/all',
      headers: {
        'Content-Type': 'application/json',
        
    },
    }
    await axios(config)
    .then(function (response) {
      let result = response.data;

      setState({
        ...state,
        ProductData: result.data
      });
      
    }) 
    setIsLoading(false);
  }
  let reloadtable = () => {
    
    setState({
      ...state,
      ProductData: {}
    });
      GetProductList();
  }
  function onproductpageChange(pageOfItems) {
  
    setState({
      ...state,
      pageOfItems: pageOfItems
    });
    
  }
  return (
    <div className={styles.main_div} >
      <Product modal={modal} toggle={toggle2} reloadtable={reloadtable}  />
      <span className="d-none d-md-block">
        <TopNav />
      </span>
      <span className="d-block d-md-none">
        <NavVenderSm />
      </span>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Container className={`${styles.container} pt-4  mr-0 ml-0 p-0`} style={{ background: 'white' }}>
        
        <Row>
          <Col xs={2} className="d-none d-md-block">
            <SideNav />
          </Col>
          <Col xs={12} md={12} sm={12}>
            <div>
              <Card className={`${styles.card} mt-4  `} >
                <CardTitle className={`${styles.headingOrder} ml-4 mt-2`}>
                  Products
                </CardTitle>
                <CardBody>
                  <Container className="p-0" className={styles.tablecontainer}>
                    <Row className={styles.row}>
                      <Col xs={3} md={3}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Product
                        </h6>
                      </Col>
                      <Col xs={2} md={2}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Seller
                        </h6>
                      </Col>

                      <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.saleHead)}>
                          Sale Price
                        </h6>
                      </Col>
                      <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                          Qty Available
                        </h6>
                      </Col>
                      <Col xs={1} md={1}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                          Status
                        </h6>
                      </Col>
                      <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.acttHead)}>
                          Action
                        </h6>
                      </Col>
                    </Row>
                    {
                      
                      ProductData.length > 0 ?
                      pageOfItems.map((item) => (
                        <MyProduct image={dp} imgDetail="Mariuana" reloadtable={reloadtable}  ProductData={item} ProductType={ProductType} />
                      )
                      ) : "No data found"
                    }
                    
                  </Container>
                  {
                  ProductData.length > 0 ?
                  <div className="pagination-row">
                    <Pagination items={ProductData} onChangePage={onproductpageChange}  pageSize={pageSize} />
                </div>
                : ''
                }
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminProductPage;
