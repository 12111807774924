import React, { useState } from "react";
import style from "./product.module.scss";
import ProductInfo from "../ProductInfo/ProductInfo";
import ProductDetail from "../ProductDetail/ProductDetail";
import Stock from "../stock/Stock";
import style_upload from "../ProductDetail/productdetail.module.scss";
import { Stepper, Step } from "react-form-stepper";
import { Container, Row, Col } from "reactstrap";

import trendChart from "../../Assets/top-trend.svg";
import overallTrendChart from "../../Assets/overall-trend.svg";
import essentialImage from "../../Assets/essential-tips.svg";
import { Button, Form, FormGroup, Label, Input,FormFeedback, FormText } from "reactstrap";
import Pencil from "../../Assets/pencil.svg";
import { useAuth } from "../../context/auth";
import axios from "axios";
import Constant from '../../constant.json';
import { Modal } from "reactstrap";
import Swal from 'sweetalert2';
import dp from "../../Assets/mari.png";
import { validateAll } from 'indicative/validator';
const ImportProducts = (props) => {
  const auth = useAuth();
  
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    fileData: '',
    file : dp,
    errors: {},
    disabled : false
  })

  
  const {
  fileData,file,errors,disabled
  } = state;
  
  
  const fileSelect =  (e) => {
    
    const file = e.target.files[0];
    
    setState(prevState => ({
      ...prevState,
      fileData: file,
      file :URL.createObjectURL(file)
    }));
    
}
const onSaveProduct = (e) => {
  if (disabled) {
    return;
}

setState(prevState => ({
  ...prevState,
  disabled: true,
}));
  const messages = {
    'fileData.required': 'Please select product csv files.',
    
  };
  const rules = {
    fileData: 'required',
    
  };
  validateAll(state, rules, messages).then(() => {
    setIsLoading(true);
    e.preventDefault();
    
    postImportProduct().then(async (response) => {
      let result = response.data;
      if(result.status == "1") {
        setState({
          ...state,
          errors: {},
          fileData : ''
        });
        props.toggle();
        props.reloadtable();
        setState(prevState => ({
          ...prevState,
          disabled: false,
        }));
        Swal.fire({
          html:true,
          icon: 'success',
          html: result.message,
        });
      }
    }).catch(err => {
      props.toggle();
      props.reloadtable();
      setState(prevState => ({
        ...prevState,
        disabled: false,
      }));
      let formattedErrors = {};
      formattedErrors['error_info'] = "There is some error please contact admin."
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: formattedErrors['error_info'],
      });
      
    }) 
    
    setIsLoading(false);
  }).catch(errors => {
    const formattedErrors = {

    };
    setState(prevState => ({
      ...prevState,
      disabled: false,
    }));
    errors.forEach(error => formattedErrors[error.field] = error.message);

    setState({
      ...state,
      errors: formattedErrors
    });
  })
}

async function postImportProduct() {
  const data = new FormData();
  data.append('productimport', fileData);
  
  const config = {
    method: 'post',
    url: Constant.API_URL + '/api/products/importproduct',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': auth.authTokens
    },
  }
  const result = await axios(config);
  return result;

}
const closemodel = () =>{
  setState({
    ...state,
    errors: {}
  });
  props.toggle()
} 

  return (
    <Modal centered isOpen={props.modal} toggle={closemodel}>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <div className={` ${style.productMain} `}>
        
        <div className={style.main}>
          <Row>
            <Col xs="12" sm="12" md="12" className={`${style.formMain}  `}>
              <Container className={style.stepperContainer}>
              <a className="close" href="#" onClick={()=>closemodel()}>×</a>
                <div className={style.stepperMain}>
                  <h1> Import PRODUCT </h1>
                 
                    <div>
                      <div className={style_upload.container}>
                        <Container>
                          <Row>
                            <Col xs="12" md="12">
                              <Form className={style_upload.form}>
                           
                                <div className={style_upload.Input}>
                                  <FormGroup row className={style_upload.inputContainer}>
                                    <Label for="exampleFile" sm={4} lg={4}>
                                      Add Product CSV<span className={style_upload.star}>*</span>
                                      
                                    </Label>
                                    <Col sm={8} lg={8} className={style_upload.fileButtonContainer}>
                                      <Input
                                        type="file"
                                        invalid = {errors.fileData}
                                        name="file"
                                        id="file"
                                        accept=".csv"
                                        // className={style_upload.inputfile}
                                        onChange = {(e) => fileSelect(e)}
                                      />
                                      <FormFeedback style={{display:'block'}}>{errors.fileData}</FormFeedback>
                                      {/* <label for="file" className={style_upload.label}>
                                        Select file
                                      </label> */}
                                      <FormText color="muted"></FormText>
                                    </Col>
                                  </FormGroup>
                                  <FormGroup  row>
                                  <Col
                                      sm={12}
                                      lg={12}
                                      className = {style.validationtext}
                                    >

                                      <p>Require all Field.! With same title name as sample file.!</p>
                                      <p>Product description must be smaller than 400 char.!</p>
                                      <p>Product Price must be bigger than 0.!</p>
                                      <p>Product Stock must be bigger than 0.!</p>
                                      <p>Product sativa must be number.!</p>

                                    </Col>
                                    </FormGroup>
                                  <FormGroup  row>
                                    <Col
                                      sm={12}
                                      lg={6}
                                      className={style_upload.buttonContainer}
                                      style={{ display: "flex", justifyContent: "flex-start" }}
                                    >
                                      <Button
                                        onClick={(e) => onSaveProduct(e)}
                                        className="btn"
                                        disabled={disabled}
                                      >
                                        {disabled ? 'Submiting...' : 'Submit'}
                                        
                                      </Button>{" "}
                                     
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={6}
                                      className={style_upload.buttonContainerback}
                                      style={{ display: "flex", justifyContent: "flex-start" }}
                                    >
                                      <a href={Constant.API_URL + Constant.SAMPLE_IMPORT_PRODUCT}>
                                        Download Sample File
                                        </a>
                                    </Col>
                                    
                                  </FormGroup>
                                </div>
                              </Form>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                  
                    </div>
                  
                </div>
              </Container>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
export default ImportProducts;
