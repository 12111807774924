import React, { useState } from "react";
import styles from "./card.module.scss";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";


const Cards = (props) => {
  const [numericInput, setNumericInput] = useState();
  const [select, setSelect] = useState(false);

  return (
    <div>
      <Card className={styles.cards}>
        <CardImg
          src={props.image}
          className={`${styles.image} img img-fluid mt-2`}
          alt="Card image cap"
        />
        <CardBody className={styles.cardbody}>
          <CardTitle style={{lineHeight:'normal'}} className={`${styles.title} pt-0 text-center`}>
            {props.name}
          </CardTitle>

          <CardText
            className={`${styles.beforeDis} ${
              props.changeAvailable ? styles.changeText : ""
            } pt-0  text-center`}
          >
          </CardText>

          <CardText
            className={`${styles.afterDis} text-center`}
          >{`${props.price}$ USD`}</CardText>
        </CardBody>
      </Card>
      <br />
    </div>
  );
};

export default Cards;
