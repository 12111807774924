import React from "react";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import styles from "./form-input.module.scss";

const FormInput = (props) => {
  return (
    <Form>
      <FormGroup row className={`${styles.main}`}>
        <Label
          for="exampleEmail"
          className={`${styles.label} ${
            props.labelSize ? styles.labelSize : ""
          }`}
          sm={4}
        >
          {props.label}
          <span className={styles.star}>*</span>{" "}
        </Label>
        <Col sm={8}>
          <Input
            type={props.type}
            name="email"
            id="exampleEmail"
            className={`${styles.input}`}
            placeholder={props.place}
          />
        </Col>
      </FormGroup>
    </Form>
  );
};
export default FormInput;
