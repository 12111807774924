import React, { useEffect, useState } from "react";
import styles from "./nav.module.scss";
import Logo from "../../Assets/logo.svg";
import cartIcon from "../../Assets/shopping-cart.svg";
import { Button, Badge } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/auth";
import FontAwesome from 'react-fontawesome'
import {dflt, noop, ajax, l, isset,blank, ref,makeuniqueID} from '../../helpers';
import axios from "axios";
import Constant from '../../constant.json';
const Nav = () => {
  const auth = useAuth();
  const [badge, setBadge] = useState(0);
  const history = useHistory();
  const [cartItem, setCartItem] = useState({});
  const [uniqueID, setuniqueID] = useState(makeuniqueID());
  const cart = localStorage.getItem("cart");
  
  const { setAuthTokens,setAuthRole } = useAuth();

  const logout = (e) => {
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('profile_image_url','');
    history.push("/login");
  }
  const _tokenexpering = localStorage.getItem('_expertime');
const current_time = new Date();
if(isset(_tokenexpering)){
   
  if(Date.parse(current_time) > Date.parse(_tokenexpering)){
    
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('role','');
    localStorage.setItem('profile_image_url','');
    const sevenDaysFromNow = new Date(new Date().setHours(new Date().getHours() + 1));
    
    localStorage.setItem('_expertime',sevenDaysFromNow);
  }
}

  useEffect(() => {
  
      
      if(cart){
        
        if (JSON.parse(cart)) {
          setBadge(JSON.parse(cart).length);
        }
      }
      
  
  },[cart]);
 
  return (
    <div className={styles.nav_container}>
      <div onClick={(e) => history.push("/")}>
        <img src={Logo} alt="logo" className={styles.logo} />
      </div>

      { typeof auth.authTokens =='undefined' ||  auth.authTokens ==null ||  auth.authTokens =='null' ||  auth.authTokens =='' ?
        (<div className={styles.button_container}>
              <Link to="/login" className={`btn ${styles.button}`}>
                Login
              </Link>
              <Link to="/register" className={`btn ${styles.button}`}>
                Sign Up
              </Link>  
        </div>)
        :   
        (<div className={styles.button_container}>
          {
            auth.authRole == 'ROLE_USER' ? 
            <Link to="/dashboard" className={`btn ${styles.button}`}>
                Welcome ({localStorage.getItem('name')})
              </Link>  
               : ''
          }
          {
            auth.authRole == 'ROLE_ADMIN' ? 
            <Link to="/admin/dashboard" className={`btn ${styles.button}`}>
                Welcome ({localStorage.getItem('name')})
              </Link>  
               : ''
          }
              
              <Link to="#" className={`btn ${styles.button}`} onClick={(e) => logout(e)}>
                Logout
              </Link>
        </div>)      
      }
      {badge > 0 ? (
        <Link to="/my-cart" className="mr-5">
          <div className={styles.cart}>
          <i class={`${styles.checkout} fa fa-shopping-cart`} aria-hidden="true"></i>
            <Badge
              className={styles.badge}
              color="danger"
              className="badge ml-n1"
              pill
            >
              {badge}
            </Badge>
            
            {/* <img src={cartIcon} className={styles.image} /> */}
          </div>
        </Link>
      ) : null}

      <div className={styles.bar}>
        <i class="fa fa-bars"></i>
      </div>
    </div>
  );
};

export default Nav;
