import React, { useState } from "react";
import styles from "./resetpassword.module.scss";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const [focused, setFocus] = useState("");
  return (
    <React.Fragment>
      <span className="d-none d-md-block">
        <Nav />
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      <section className={styles.register_container} >
        <div className={styles.shadow_box}>
          <div className={styles.content_container}>
            <h1 className={styles.top_heading}>Reset Password</h1>
            {/* EMAIL FIELD */}
            <div
              className={`${
                focused === "password"
                  ? styles.custom_input_focus
                  : styles.custom_input
              }`}
            >
              <span>
                <i class="fa fa-key" aria-hidden="true"></i>
              </span>
              <input
                onFocus={() => setFocus("password")}
                onBlur={() => setFocus("")}
                type="text"
                placeholder="Enter New Password"
              />
            </div>

            <div
              className={`${
                focused === "confirmPassword"
                  ? styles.custom_input_focus
                  : styles.custom_input
              }`}
            >
              <span>
                <i class="fa fa-key" aria-hidden="true"></i>
              </span>
              <input
                onFocus={() => setFocus("confirmPassword")}
                onBlur={() => setFocus("")}
                type="text"
                placeholder="Enter Confirm Password"
              />
            </div>

            <div className={styles.btn_container}>
              <Link to="/">
                <button className="btn">Reset Password</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default ResetPassword;
