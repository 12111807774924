import React,{ useState } from "react";
import style from "./stock.module.scss";
import { Button, Form, FormGroup, Label, Input,FormFeedback, FormText } from "reactstrap";
import Pencil from "../../Assets/pencil.svg";
import { Container, Row, Col } from "reactstrap";
import { validateAll } from 'indicative/validator';
const Stock = (props) => {
  const [state, setState] = useState({
    ProductPrice: props.ProductPrice,
    ProductStock : props.ProductStock,
    errors: {},
  })

  
  const {
    ProductPrice, ProductStock,errors
  } = state;
  const onChangeEvent = (e) => {

    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
    if(e.target.name == 'ProductPrice'){
      props.onProductPrice(value);
    }
    if(e.target.name == 'ProductStock'){
      props.onProductStock(value);
    }
    
      
  };
  const validateStepthree = (e) => {
    e.preventDefault();
    
    const messages = {
      'ProductPrice.required': 'Product Price cannot be empty.',
      'ProductPrice.number': 'Product Price cannot be valid.',
      'ProductPrice.above': 'Product Price cannot be valid.',
      'ProductStock.required': 'Product Stock cannot be empty.',
      'ProductStock.number': 'Product Stock cannot be valid.',
      'ProductStock.above': 'Product Stock cannot be valid.',
      
    };
    const rules = {
      ProductPrice: 'required|number|above:0',
      ProductStock: 'required|number|above:0',
      
    };
    validateAll(state, rules, messages).then(() => {
      props.onProductPrice(ProductPrice);
      props.onProductStock(ProductStock);
      props.setActiveStep(3);
    }).catch(errors => {
      const formattedErrors = {

      };

      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
    })
    
  }
  return (
    <div className={style.container}>
      <Container>
        <Row>
          <Col xs="12">
            <Form className={style.form}>
              <div className={style.topHeading}>
                <Row className={style.topHeadingRow}>
                  <Col xs="9" className={style.left}>
                    <h5> Price & Stock </h5>
                  </Col>
                  <Col xs="3" className={style.right}>
                    <img src={Pencil} />
                  </Col>
                </Row>
              </div>

              <div className={style.Input}>
                <FormGroup row className={style.inputContainer}>
                  <Label for="exampleEmail" sm={4} lg={4}>
                    Price<span className={style.star}>*</span>
                  </Label>
                  <Col sm={8} lg={8}>
                    <Input
                      type="number"
                      invalid = {errors.ProductPrice}
                      name="ProductPrice"
                      id="ProductPrice"
                      className={style.input}
                      placeholder="Rs."
                      defaultValue={ProductPrice}
                      onChange={(e) => onChangeEvent(e)}
                    />
                    <FormFeedback style={{display:'block'}}>{errors.ProductPrice}</FormFeedback>
                  </Col>
                </FormGroup>

                <FormGroup row className={style.inputContainer}>
                  <Label for="exampleEmail" sm={4} lg={4}>
                    Stock<span className={style.star}>*</span>
                  </Label>
                  <Col sm={8} lg={8}>
                    <Input
                      invalid = {errors.ProductStock}
                      type="number"
                      name="ProductStock"
                      id="ProductStock"
                      className={style.input}
                      placeholder="Pcs"
                      defaultValue={ProductStock}
                      onChange={(e) => onChangeEvent(e)}
                    />
                    <FormFeedback style={{display:'block'}}>{errors.ProductStock}</FormFeedback>
                  </Col>
                </FormGroup>

                <FormGroup  row>
                <Col
                    sm={12}
                    lg={6}
                    className={style.buttonContainerback}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Button
                      onClick={(e) => props.setActiveStep(1)}
                      className="btn"
                    >
                      Back
                    </Button>{" "}
                  </Col>
                  <Col
                    sm={12}
                    lg={6}
                    className={style.buttonContainer}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Button
                      onClick={(e) => validateStepthree(e)}
                      className="btn"
                    >
                      Next
                    </Button>{" "}
                  </Col>
                  
                </FormGroup>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Stock;
