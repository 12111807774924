import React, { useState } from "react";
import style from "./productInfo.module.scss";
import { Button, Form, FormGroup, Label, Input,FormFeedback, FormText } from "reactstrap";
import { Container, Row, Col } from "reactstrap";
import { validateAll } from 'indicative/validator';
import edit from "../../Assets/edit.svg";

const ProductInfo = (props) => {
  const [focused, setFocus] = useState("");
  const [state, setState] = useState({
    ProductName: props.ProductName,
    ProductSativa : props.ProductSativa,
    ProductContent : props.ProductContent,
    ProductType : props.ProductType,
    errors: {},
  })

  
  const {
    ProductName, ProductSativa,ProductType,ProductContent,errors
  } = state;
  const onChangeEvent = (e) => {
 
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  };
  const validateStepone = (e) => {
    e.preventDefault();
    
    const messages = {
      'ProductName.required': 'Product Name cannot be empty.',
      // 'ProductSativa.required': 'Sativa cannot be empty.',
      'ProductSativa.above': 'Product sativa Must be bigger then 0.',
      'ProductSativa.under': 'Product sativa Must be smaller then 100.',
      'ProductContent.required': 'Content no cannot be empty.',
      'ProductType.required': 'Product Type cannot be empty.',
      
    };
    const rules = {
      ProductName: 'required',
      ProductSativa: 'above:0|under:100',
      ProductContent: 'required',
      ProductType: 'required',
      
    };
    validateAll(state, rules, messages).then(() => {
      props.onProductName(ProductName);
      props.onProductSativa(ProductSativa);
      props.onProductContent(ProductContent);
      props.onProductType(ProductType);
      props.setActiveStep(1);
    }).catch(errors => {
      const formattedErrors = {

      };

      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
    })
    
  }
  return (
    <Container className={style.container}>
      <Row>
        <Col xs="12">
          <Form className={style.form}>
            
            <FormGroup row>
              <Col sm={6}>
                <h1>Product Information</h1>
              </Col>
              <Col sm={6}>
                <img className={style.image} src={edit} />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="name" sm={4}>
                Product Name<span className={style.star}>*</span>
              </Label>
              <Col sm={8}>
                <div>
                  <Input
                    invalid = {errors.ProductName}
                    className={style.select}
                    type="name"
                    name="ProductName"
                    defaultValue = {ProductName}
                    placeholder="Ex. Medicine Name"
                    onChange={(e) => onChangeEvent(e)}
                  />
                </div>
                <FormFeedback style={{display:'block'}}>{errors.ProductName}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="name" sm={4}>
                Product Type<span className={style.star}>*</span>
              </Label>
              <Col sm={8}>
                <div>
                <Input
                      invalid = {errors.ProductType}
                      className={style.input}
                      type="select"
                      name="ProductType"
                      id="ProductType"
                      value={ProductType}
                      onChange={(e) => onChangeEvent(e)}
                      defaultValue={ProductType}
                    >
                      <option value="">Select Product Type</option>
                      {
                        
                        props.ProductTypeList.map((item,index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                          ))
                        
                      }
                    </Input>
                </div>
                <FormFeedback style={{display:'block'}}>{errors.ProductType}</FormFeedback>
              </Col>
            </FormGroup>
            
            <FormGroup row>
              <Label for="text" sm={4}>
                % Sativa to Indica
                {/* <span className={style.star}>*</span> */}
              </Label>
              <Col sm={8}>
                <div>
                  <Input
                    invalid = {errors.ProductSativa}
                    type="number"
                    className={style.select}
                    name="ProductSativa"
                    defaultValue={ProductSativa}
                    placeholder="Insert % Sativa to Indica"
                    onChange={(e) => onChangeEvent(e)}
                  />
                </div>
                <FormFeedback style={{display:'block'}}>{errors.ProductSativa}</FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="text" sm={4}>
                THC Content<span className={style.star}>*</span>
              </Label>
              <Col sm={8}>
                <div>
                  <Input
                  invalid = {errors.ProductContent}
                    type="num"
                    className={style.select}
                    name="ProductContent"
                    defaultValue={ProductContent}
                    placeholder="Insert THC Content"
                    onChange={(e) => onChangeEvent(e)}
                  />
                </div>
                <FormFeedback style={{display:'block'}}>{errors.ProductContent}</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col sm={11}>
                <Button onClick={(e) => validateStepone(e)} className="btn">
                  Next
                </Button>{" "}
              </Col>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductInfo;
