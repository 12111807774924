import React, { useState } from "react";
import style from "./product.module.scss";
import ProductInfo from "../ProductInfo/ProductInfo";
import ProductDetail from "../ProductDetail/ProductDetail";
import Stock from "../stock/Stock";
import style_upload from "../ProductDetail/productdetail.module.scss";
import { Stepper, Step } from "react-form-stepper";
import { Container, Row, Col } from "reactstrap";
import trendChart from "../../Assets/top-trend.svg";
import overallTrendChart from "../../Assets/overall-trend.svg";
import essentialImage from "../../Assets/essential-tips.svg";
import { Button, Form, FormGroup, Label, Input,FormFeedback, FormText } from "reactstrap";
import Pencil from "../../Assets/pencil.svg";
import { useAuth } from "../../context/auth";
import axios from "axios";
import Constant from '../../constant.json';
import { Modal } from "reactstrap";
import Swal from 'sweetalert2';
import dp from "../../Assets/mari.png";
import { validateAll } from 'indicative/validator';
const Products = (props) => {
  const auth = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    ProductName: '',
    ProductSativa : '',
    ProductContent : '',
    ProductDescription : '',
    ProductPrice : '',
    ProductStock : '',
    ProductType : '',
    fileData: '',
    file : dp,
    errors: {},
    disabled : false
  })

  
  const {
    ProductName,disabled, ProductSativa,ProductType,ProductContent,ProductDescription,ProductPrice,ProductStock,fileData,file,errors
  } = state;
  
  const onProductName = (value) => {

    setState(prevState => ({
      ...prevState,
      ProductName: value
    }));
  };
  const onProductType = (value) => {

    setState(prevState => ({
      ...prevState,
      ProductType: value
    }));
  };
  const onProductSativa = (value) => {
    
    setState(prevState => ({
      ...prevState,
      ProductSativa: value
    }));
  };
  const onProductContent = (value) => {
    setState(prevState => ({
      ...prevState,
      ProductContent: value
    }));
  };
  const onProductDescription = (value) => {
    setState(prevState => ({
      ...prevState,
      ProductDescription: value
    }));
  };
  const onProductPrice = (value) => {
    setState(prevState => ({
      ...prevState,
      ProductPrice: value
    }));
  };
  const onProductStock = (value) => {
    setState(prevState => ({
      ...prevState,
      ProductStock: value
    }));
  };
  const fileSelect =  (e) => {
    
    const file = e.target.files[0];
    
    setState(prevState => ({
      ...prevState,
      fileData: file,
      file :URL.createObjectURL(file)
    }));
    
}
const onSaveProduct = (e) => {
  if (disabled) {
    return;
}

setState(prevState => ({
  ...prevState,
  disabled: true,
}));
  const messages = {
    'fileData.required': 'Please add image of product.',
    
  };
  const rules = {
    fileData: 'required',
    
  };
  validateAll(state, rules, messages).then(() => {
    setIsLoading(true);
    e.preventDefault();
    postProductDetails()
    .then(function (response) {
      if(response.data.status == "1") {
        
        let result = response.data;
        if(fileData != ''){
          postUploadProductImage(result.data.id)
          .then(function (response) {
            if(response.data.status == "1") {
              setState(prevState => ({
                ...prevState,
                disabled: false,
              }));
              let result_image = response.data;
              Swal.fire({
                icon: 'success',
                text: 'Product Create successfully',
              });
              setState(prevState => ({
                ...prevState,
                ProductName: '',
                ProductSativa : '',
                ProductContent : '',
                ProductDescription : '',
                ProductPrice : '',
                ProductStock : '',
                fileData: '',
                file : dp,
                errors: {},
              }));
              setActiveStep(0);
              props.toggle();
              props.reloadtable();
            }
          }).catch(err => {
            setState(prevState => ({
              ...prevState,
              disabled: false,
            }));
            let formattedErrors = {};
            formattedErrors['error_info'] = "There is some error please contact admin."
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: formattedErrors['error_info'],
            });
            
          }) 
        }else{
          setState(prevState => ({
            ...prevState,
            disabled: false,
          }));
          Swal.fire({
            icon: 'success',
            text: "Porduct Create Successfully.!",
          });
          setActiveStep(0);
          props.toggle();
          props.reloadtable();
        }
        
      }
    }).catch(err => {
      setState(prevState => ({
        ...prevState,
        disabled: false,
      }));  
      let formattedErrors = {};
      formattedErrors['error_info'] = "There is some error please contact admin."
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: formattedErrors['error_info'],
      });
      
    }) 
    setIsLoading(false);
  }).catch(errors => {
    const formattedErrors = {

    };
    setState(prevState => ({
      ...prevState,
      disabled: false,
    }));
    errors.forEach(error => formattedErrors[error.field] = error.message);

    setState({
      ...state,
      errors: formattedErrors
    });
  })
}
async function postProductDetails() {
  

  const config = {
    method: 'post',
    url: Constant.API_URL + '/api/products',
    data: {
      name: ProductName,
      sativa: ProductSativa,
      thc: ProductContent,
      description: ProductDescription,
      price: ProductPrice,
      stock: ProductStock,
      product_type : ProductType
    },
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': auth.authTokens
    },
  }
  const result = await axios(config);
  return result;

}
async function postUploadProductImage(id) {
  const data = new FormData();
  data.append('productimage', fileData);
  data.append('id',id);

  const config = {
    method: 'post',
    url: Constant.API_URL + '/api/products/image',
    data: data,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': auth.authTokens
    },
  }
  const result = await axios(config);
  return result;

}

  return (
    <Modal centered isOpen={props.modal} toggle={props.toggle}>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <div className={` ${style.productMain} `}>
        
        <div className={style.main}>
          <Row>
            <Col xs="12" sm="12" md="12" className={`${style.formMain}  `}>
              <Container className={style.stepperContainer}>
              <a className="close" href="#" onClick={()=>props.toggle()}>×</a>
                <div className={style.stepperMain}>
                  <h1> ADD PRODUCT </h1>
                  
                  <Stepper
                    steps={[
                      { label: "Product Information" },
                      { label: "Detail description" },
                      { label: "price & Stock" },
                      { label: "Product Upload" },
                    ]}
                    activeStep={activeStep}
                    className={style.stepper}
                  />
                  {activeStep === 0 ? (
                    <div>
                      <ProductInfo setActiveStep={setActiveStep} onProductName={onProductName} ProductName={ProductName}  onProductSativa={onProductSativa} ProductSativa={ProductSativa} onProductContent={onProductContent} ProductContent={ProductContent} ProductType={ProductType} onProductType={onProductType} ProductTypeList={props.ProductTypeList} />
                    </div>
                  ) : null}

                  {activeStep === 1 ? (
                    <div>
                      <ProductDetail setActiveStep={setActiveStep} onProductDescription={onProductDescription} ProductDescription={ProductDescription} />
                    </div>
                  ) : null}

                  {activeStep === 2 ? (
                    <div>
                      <Stock setActiveStep={setActiveStep} onProductPrice={onProductPrice} ProductPrice={ProductPrice} onProductStock={onProductStock}  ProductStock={ProductStock} />
                    </div>
                  ) : null}

                  {activeStep === 3 ? (
                    <div>
                      <div className={style_upload.container}>
                        <Container>
                          <Row>
                            <Col xs="12" md="12">
                              <Form className={style_upload.form}>
                                <div className={style_upload.topHeading}>
                                  <Row className={style_upload.topHeadingRow}>
                                    <Col xs="9" className={style_upload.left}>
                                      <h5>Upload Images</h5>
                                    </Col>
                                    <Col xs="3" className={style_upload.right}>
                                      <img src={Pencil} />
                                    </Col>
                                  </Row>
                                </div>

                                <div className={style_upload.Input}>
                                  <FormGroup row className={style_upload.inputContainer}>
                                    <Label for="exampleFile" sm={4} lg={3}>
                                      Add Image<span className={style_upload.star}>*</span>
                                      <img src={file}   className={`${style_upload.image} img img-fluid`}
                                        alt="image" />
                                    </Label>
                                    <Col sm={6} lg={6} className={style_upload.fileButtonContainer}>
                                      {/* <span>
                                        <input
                                          type="file"
                                          name="file"
                                          id="file"
                                          onChange = {(e) => fileSelect(e)}
                                          className={style_upload.inputfile}
                                        />
                                      </span> */}
                                      <Input
                                        type="file"
                                        invalid = {errors.fileData}
                                        name="file"
                                        id="file"
                                        accept="image/*"
                                        className={style_upload.inputfile}
                                        onChange = {(e) => fileSelect(e)}
                                      />
                                      <FormFeedback style={{display:'block'}}>{errors.fileData}</FormFeedback>
                                      <label for="file" className={style_upload.label}>
                                        Select file
                                      </label>
                                      <FormText color="muted"></FormText>
                                    </Col>
                                  </FormGroup>

                                  <FormGroup  row>
                                  <Col
                                      sm={12}
                                      lg={6}
                                      className={style_upload.buttonContainerback}
                                      style={{ display: "flex", justifyContent: "flex-start" }}
                                    >
                                      <Button
                                        onClick={(e) => setActiveStep(2)}
                                        className="btn"
                                      >
                                        Back
                                      </Button>{" "}
                                    </Col>
                                    <Col
                                      sm={12}
                                      lg={6}
                                      className={style_upload.buttonContainer}
                                      style={{ display: "flex", justifyContent: "flex-start" }}
                                    >
                                      <Button
                                        onClick={(e) => onSaveProduct(e)}
                                        className="btn"
                                        disabled={disabled}
                                      >
                                        {disabled ? 'Submiting...' : 'Submit'}
                                        
                                      </Button>{" "}
                                    </Col>
                                    
                                  </FormGroup>
                                </div>
                              </Form>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                  
                    </div>
                  ) : null}
                </div>
              </Container>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
export default Products;
