import React,{useState} from "react";
import styles from "./myOrder.module.scss";
import { Container, Row, Col, Button } from "reactstrap";
import food from "../../Assets/mari.png";
import Constant from '../../constant.json';
import { Badge } from "reactstrap";
import check from "../../Assets/check.svg";
import Moment from 'react-moment';
import axios from "axios";
import OrderDetailsInfo from "../OrderDetailsInfo/OrderDetailsInfo";
import {dflt, noop, ajax, l, isset, ref} from '../../helpers';
import Swal from 'sweetalert2';
import { useAuth } from "../../context/auth";
const MyOrder = (props) => {
  const auth = useAuth();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  let productinfo = props.OrderDatas;
  
  if(typeof  productinfo == 'undefined'){
    productinfo = {};
  }
  
  const orderConfirm = (e) => {
    let props_new = props;
    const remaining_stock = (productinfo.remaining_stock - productinfo.qty);
    if(isset(productinfo.item_id)){
      Swal.fire({
        title: 'Are you sure to confirm?',
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Save`,
        reverseButtons: true
      }).then((result) => {
        
        if (result.isConfirmed) {

          const config = {
            method: 'post',
            url: Constant.API_URL + '/api/products/orderconfirm',
            headers: {
              'Content-Type': 'application/json',
              'x-access-token': auth.authTokens
          },
          data : {
            id:productinfo.item_id,
            product_id : productinfo.id,
            remaining_stock : remaining_stock,
          }
          }
          
           axios(config)
          .then(function (response) {
            props.reloadorders();
            let result = response.data;
            if(result.status == 1){
              
              Swal.fire({
                text: result.message,
                icon : 'success'
              })
            }else{
              Swal.fire({
                text: result.message,
                type : 'error'
              })
            }
          },[props]).catch(err => {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: "please contact admin there is a some error.",
            });
            
          }) 
          
        } 
      })  
    }else{
      Swal.fire({
        text: 'There is a some error..! please contact admin.',
        icon : 'error'
      })
    }
    
  }
  const checkImage = (imageSrc, good, bad) =>{
    var img = new Image();
    img.onload = good; 
    img.onerror = bad;
    img.src = imageSrc;
}
  return (
    
    <div>
      <OrderDetailsInfo modal={modal} productinfo={productinfo} toggle={toggle} />
       <Row className={`${styles.main} mt-3`}>
       {/* <Col xs="1" md="1" sm="1">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
            {productinfo.order_id}
          </h6>
        </Col> */}
        <Col xs="3" md="3" sm="3">
          <div className={styles.order}>
            <img
              src={
                
                productinfo.image  ? Constant.API_URL + Constant.RESIZE_IMAGE +productinfo.image :
                      props.image
              }
              className={`${styles.image} img img-fluid`}
              alt="image"
            />

            <h6>{productinfo.name} </h6>
          </div>
        </Col>
        <Col xs="1" md="1" sm="1">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
          {
                productinfo.is_confirm  ?
                <Badge color="secondary" className={styles.yes}>YES</Badge>
          : <Badge color="secondary" className={styles.no}>NO</Badge>
          }
          </h6>
        </Col>
        <Col xs="1" md="1" sm="1">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
          <Badge color="secondary" className={styles.yes}>Yes</Badge>
          </h6>
        </Col>
        <Col xs="1" md="1" sm="1">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
          {productinfo.qty}
          </h6>
        </Col>
        <Col xs="3" md="3" sm="3">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
          {productinfo.user_email}
          </h6>
        </Col>
        {/* <Col xs="2" md="2" sm="2">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
          {productinfo.user_mobile}
          </h6>
        </Col> */}
        {/* <Col xs="1" md="1" sm="1">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
          <Moment format="D MMM YYYY" withTitle>
                            {productinfo.order_date}
                        </Moment>
          </h6>
        </Col> */}
        <Col xs="3" md="3" sm="3">
          
          {
                productinfo.is_confirm 
                ? <Badge color="secondary" style={{cursor:'default'}} className="btn-success">Order confirm</Badge> 
                : 
                <div>
                <Button className={styles.ButtonPadding} style={{padding:'5px'}} onClick={(e) => orderConfirm(e)}>Confirm</Button>
                { " " }
                <Button className={styles.ButtonPadding} 
                style={{padding:'5px'}}
                onClick={(e) => setModal(true)}
                >Details</Button>
                </div>
              }
          
        </Col>
        
      </Row>
      <hr />
      
    </div>
  );
};

export default MyOrder;
