import React, { useEffect, useState } from "react";
import styles from "./productComponent.module.scss";
import { Container, Row, Col, Button, Table } from "reactstrap";
import { Progress } from "reactstrap";
import axios from "axios";
import img1 from "../../Assets/mari.png";
import addToCart from "../../Assets/add-to-cart.svg";
import location from "../../Assets/location.svg";
import check from "../../Assets/check.svg";
import phone from "../../Assets/phone-call.svg";
import Constant from '../../constant.json';
import NumericInput from "../NumericInput/NumericInput";
import "./override.scss";
import Parser from 'html-react-parser';
import Swal from 'sweetalert2';
import ShowMoreText from 'react-show-more-text';
import {dflt, noop, ajax, l, isset,blank, ref,makeuniqueID} from '../../helpers';
const ProductComponent = (props) => {
  
  const [numericInput, setNumericInput] = useState(0);
  
  const [disabled, setDisabled] = useState(false);
  const cart = localStorage.getItem("cart");
  
  const [cartItem, setCartItem] = useState(JSON.parse(cart));
  
  const [uniqueID, setuniqueID] = useState(makeuniqueID());
  const productId = props.id;
  // const [state, setState] = useState({
  //   product: props.product,
  //   products : []
    
  // })
  // const {
  //   product,products
  // } = state;
  const data = localStorage.getItem("data");

  const product = props.products;
  let products=[];
  products.push(props.products);
  //  const products = JSON.parse(data).filter((item) => item.id === productId);
  
  //  const product = products[0];
  // useEffect(() => {
  //   (async () => {
  //     await GetProductDetails().then(async () => {
          
  //     });
  //   })();
  // },[]);
  // const GetProductDetails = async () => {
  //   const config = {
  //     method: 'get',
  //     url: Constant.API_URL + '/api/products/'+productId,
      
  //   }
  //   await axios(config)
  //   .then( async (response) => {
  //     let result = response.data;
  //     if(result.status == 1){
  //       setState({
  //         ...state,
  //         product: result.data
  //       });
  //       products.push(result.data);
  //       return true;
  //     }
  //   });
    
  // }
  const UpdateCartItem = async (value,oldCartitem) =>{
    
    const config = {
      method: 'put',
      url: Constant.API_URL + '/api/cartitem/'+oldCartitem.CartId,
      data :{
        qty :value 
      }
    }
    const result = await axios(config);
    return result;
  }
  const addToCart = async() => {
    if (disabled) {
        return;
    }
    setDisabled(true);
    let deleteIndex = -1;
    
    if(cartItem != null){
      deleteIndex = cartItem.findIndex(
        (x,i) => x.product.id === product.id
      );
    }
    
    if (deleteIndex > -1) {
      var oldCartitem = cartItem[deleteIndex];
      
      var new_qty = oldCartitem.qty + numericInput;
      await UpdateCartItem(new_qty,oldCartitem).then(function (response){
        let result = response.data;
        if(result.status == 1){
          const item = {
            product,
            qty: new_qty,
            price: product.price * new_qty,
            uniqueID,
            CartId : oldCartitem.CartId
          };
            
          cartItem.splice(deleteIndex, 1);
          if (cartItem) {
            setDisabled(false);
            cartItem.push(item);
            localStorage.setItem("cart", JSON.stringify(cartItem));
            Swal.fire({
              icon: 'success',
              text: 'Product add successfully.!',
            });
          } else {
            setDisabled(false);
            localStorage.setItem("cart", JSON.stringify([item]));
            Swal.fire({
              icon: 'success',
              text: 'Product add successfully.!',
            });
          }
        }
      })
      
      
    }else{
      
     await addToCartApi()
     .then(function (response) {
      let result = response.data;
      
     if(result.status == 1){
      const item = {
        product,
        qty: numericInput,
        price: product.price * numericInput,
        uniqueID,
        CartId : result.insertId
      };    
      
      if (cartItem) {
        cartItem.push(item);
        localStorage.setItem("cart", JSON.stringify(cartItem));
        setDisabled(false);
        Swal.fire({
          icon: 'success',
          text: 'Product add successfully.!',
        });
      } else {
        localStorage.setItem("cart", JSON.stringify([item]));
        setDisabled(false);
        Swal.fire({
          icon: 'success',
          text: 'Product add successfully.!',
        });
      }
     }else{
      setDisabled(false);
        Swal.fire({
          icon: 'error',
          text: 'There is some issue.',
        });
     }
    })
    }
    
    
    props.toggle()
  };
 
    const addToCartApi = async () => {
      
      const config = {
        method: 'post',
        url: Constant.API_URL + '/api/cartitem',
        data: {
          session_id: uniqueID,
          product_id : product.id,
          qty : numericInput,
          product : JSON.stringify(product)
        },
      }
      const result = await axios(config);
      return result;
      
    }
  return (
    <>
      {products.map((item) => (
        <div key={item.id} className="h-100" >
          <a className="close" style={{'padding':'10px'}} href="#" onClick={()=>props.toggle()}>×</a>
          <Row className={`${styles.row} h-100`}>
            <Col xs="4" className="col-12 col-lg-4 my-auto">
              <div className={`${styles.main}`}>
                <div className={`${styles.mainImage}`}>
                  <img src={item.image ? Constant.API_URL + Constant.MAIN_IMAGE+item.image : 'https://www.visitcalifornia.com/sites/visitcalifornia.com/files/styles/welcome_image/public/vc_spotlightrooftopretreats_hero_sandiego_su_theandaz_rooftoppool_1280x640.jpg'} className={`${styles.image}`} />
                </div>
                <br />
                <br />
              </div>
            </Col>

            <Col xs="5" className="col-12 col-lg-4 pt-5 mb-3">
              <div className={`${styles.secondMain}`}>
                <div className={`${styles.head}`}>
                  {/* <h1>{props.mainHeading}</h1> */}
                  <h1>{item.name}</h1>
                </div>

                <h6>Stock : {item.remaining_stock}</h6>
                <p className={`${styles.paraColor}`}>
                <ShowMoreText
                /* Default options */
                lines={3}
                more='Show more'
                less='Show less'
                className='content-css'
                anchorClass='my-anchor-css-class'
                expanded={false}
                width={400}
            >
                {Parser(item.description)}
                </ShowMoreText>
                </p>
                <p
                  className={`${styles.rupee} m-0`}
                >{`Price: ${item.price ? item.price : 0}$ / Unit`}</p>
                <p
                  className={`${styles.paraColor} m-0`}
                >{`THC Content: ${item.thc ? item.thc : 'test'}`}</p>
                <div className={`row ${styles.paraColor} mt-2`}>
                  <div className="col-5" style={{paddingRight:'0'}}>Sativa to Indica</div>
                  <div className="col-7" style={{marginTop:'6px',padding: '0'}}>
                  <Progress multi>
                <Progress bar color="success" value={item.sativa} >{item.sativa}%</Progress>
                <Progress bar color="warning" value={100-item.sativa} >{100-item.sativa}%</Progress>
                
              </Progress>

                  </div>
                  
                
                
                {/* <Progress value={item.sativa} /> */}
                
              </div>
              {
                (props.cart) ? '' : (
                  <div className={styles.numericInput}>
                    <NumericInput setNumericInput={setNumericInput} remaining_stock={item.remaining_stock} />
                  </div>
                )
              }
               
               {
                (props.cart) ? '' : (
                <div className={` mt-3 `}>
                  {
                    item.remaining_stock > 0 ? 
                    <div>
                      <Button style={{'marginRight' : '10px'}} className={styles.btn}  onClick={()=>props.toggle()}>
                    
                    Close
                  </Button>
                    <Button className={styles.btn}  disabled={disabled} onClick={(e) => addToCart()}>
                    {disabled ? 'Wait...' : 'Add To Cart'}
                    
                  </Button>
                  
                </div>
                  :
                  <h5 className={styles.outofstock}>Out of Stock</h5>
                  }
                </div>
                 )
                }
              </div>
            </Col>

            <Col xs="3" className="col-12 col-lg-4 my-auto">
              <div className={styles.info}>
                {/* <div className={styles.dp}>
                  <img src={item.userImageURL} className={`img img-fluid`} />
                </div>
                <p className={`${styles.rupee} mb-0 `}>
                  {item.user}
                  
                </p> */}
                <p
                  className={`${styles.paraColor} m-0`}
                >{`Total Products: ${item.stock}`}</p>

                <p className={`${styles.rupee} mb-0 `}>
                  <span>
                    {/* <img
                      src={location}
                      className={`img img-fluid ${styles.location}`}
                    /> */}
                    <i className={`fa fa-map-marker img img-fluid ${styles.location}`} aria-hidden="true"></i>
                  </span>
                  Store Location
                </p>
                <p className={`${styles.paraColor} m-0`}>{item.user}</p>

                <div className={` mt-2`}>
                  <a href="javascript:void(0)">
                    <Button className={styles.btn}  >
                      {/* <img src={phone} className={`${styles.image} mr-2  `} /> */}
                      <i class="fa fa-phone fa-rotate-90 mr-2" aria-hidden="true"></i>
                      Contact
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}
      
    </>
  );
};
export default ProductComponent;
