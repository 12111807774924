import React, { useState, useEffect } from "react";
import styles from "./SideNav.module.scss";
import "./override.scss";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";
import sideLineWhite from "../../../Assets/side-line-white.svg";
import { Container, Row, Col } from "reactstrap";
import Logo from "../../../Assets/logo.svg";

const SideNav = (props) => {
  const openNav = () => {
    document.getElementById("mySidenav").style.width = "22%";
    document.getElementById("Overly").style.display = "block";
  };
  // props.setOpenCart(openNav);
  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("Overly").style.display = "none";
  };

  const [selected, set_selected] = useState("dashboard");
  useEffect(() => {
    if (window.location.pathname === "/admin/dashboard") {
      set_selected("dashboard");
    } else if (window.location.pathname === "/admin/seller") {
      set_selected("seller");
    } else if (window.location.pathname === "/admin/product-type") {
      set_selected("producttype");
    } else if (window.location.pathname === "/admin/product-page") {
      set_selected("product");
    } else if (window.location.pathname === "/admin/orders") {
      set_selected("orders");
    } else if (window.location.pathname === "/admin/setting") {
      set_selected("settings");
    } else {
      set_selected("");
    }
  }, []);
  return (
    <Container>
      <Row>
        <Col xs="4">
          <div id="mySidenav" className={styles.sidenav} > 
          {/* style={{ borderRight : '1px solid #e2e2e2' }} */}
              <Link to="/"><img src={Logo} className={`${styles.logo} img img-fluid`} style={{margin:'0.5rem'}} /></Link>
            
            <ListGroup style={{paddingLeft:'10px'}}>
              <Link className={`${styles.items}`} to="/admin/dashboard">
                <ListGroupItem
                  className={`${
                    selected === "dashboard" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "dashboard" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "dashboard" ? (
                    <img
                      
                      className="img img-fluid mr-2 ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2 ml-4" />
                  )}
                  Dashboard
                </ListGroupItem>
              </Link>
              <Link className={`${styles.items}`} to="/admin/seller">
                <ListGroupItem
                  className={`${
                    selected === "seller" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "seller" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "seller" ? (
                    <img
                      
                      className="img img-fluid mr-2 ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2 ml-4" />
                  )}
                  Seller
                </ListGroupItem>
              </Link>
              <Link className={`${styles.items}`} to="/admin/product-type">
                <ListGroupItem
                  className={`${
                    selected === "producttype" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "producttype" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "producttype" ? (
                    <img
                      
                      className="img img-fluid mr-2  ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2  ml-4" />
                  )}
                  Products Type
                </ListGroupItem>
              </Link>
              <Link className={`${styles.items}`} to="/admin/product-page">
                <ListGroupItem
                  className={`${
                    selected === "product" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "product" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "product" ? (
                    <img
                      
                      className="img img-fluid mr-2  ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2  ml-4" />
                  )}
                  Products
                </ListGroupItem>
              </Link>
              <Link className={`${styles.items}`} to="/admin/orders">
                <ListGroupItem
                  className={`${
                    selected === "orders" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "orders" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "orders" ? (
                    <img
                      
                      className="img img-fluid mr-2  ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2  ml-4" />
                  )}
                  Orders
                </ListGroupItem>
              </Link>

              <Link className={`${styles.items}`} to="/admin/setting">
                <ListGroupItem
                  className={`${
                    selected === "settings" ? styles.changeColor : ""
                  }`}
                >
                  {selected === "settings" ? (
                    <img src={sideLineWhite} style={{paddingLeft:'10px'}} className="img img-fluid" />
                  ) : (
                    <img src={sideLineWhite} className="img img-fluid" />
                  )}
                  {selected === "settings" ? (
                    <img
                      
                      className="img img-fluid mr-2  ml-4"
                    />
                  ) : (
                    <img  className="img img-fluid mr-2  ml-4" />
                  )}
                  Settings
                </ListGroupItem>
              </Link>
            </ListGroup>
          </div>
        </Col>
        <Col xs="8">
          <div className={styles.overly} id="Overly" onClick={closeNav}></div>
        </Col>
      </Row>
    </Container>
  );
};

export default SideNav;
