import React, { useState } from "react";
import styles from "./myproduct.module.scss";

import { Row, Col, Container, Table, CustomInput, Button } from "reactstrap";
import edit from "../../Assets/edit.svg";
import del from "../../Assets/delete.svg";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import InfoDetail from "../InfoDetail/InfoDetail";
import axios from 'axios';
import { useAuth } from "../../context/auth";
import Constant from '../../constant.json';
import Swal from 'sweetalert2';
import "./override.scss";
const MyProduct = (props) => {
  const auth = useAuth();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    
    setModal(!modal)
    props.reloadtable();

  };
  let productinfo = props.ProductData;
  
  if(typeof  productinfo == 'undefined'){
    productinfo = {};
  }

  const deleteProduct = async(product_id) => {
    let data = [];
     const config = {
      method: 'delete',
      url: Constant.API_URL + '/api/products/'+product_id,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    await axios(config)
    .then(function (response) {
      let result = response.data;
          if(result.status == 1) {
            
            Swal.fire({
              icon: 'success',
              text: result.message,
            });
            props.reloadtable();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: result.message,
            });
            
          }
      
    }).catch(err => {
          
      let formattedErrors = {};
      formattedErrors['error_info'] = "There is some error please contact admin."
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: formattedErrors['error_info'],
      });
      
    }) 
  }
  return (
    <>
      <InfoDetail modal={modal}  toggle={toggle}  productinfo={productinfo} ProductType={props.ProductType} />
      <Row className={`${styles.main} mt-3`}>
        <Col xs="3" md="3" sm="3">
          <div className={styles.product}>
            <img
              src={
                
                productinfo.image  ? Constant.API_URL + Constant.RESIZE_IMAGE+productinfo.image :
                props.image
              }
              className={`${styles.image} img img-fluid`}
              alt="image"
            />

            <h6>{productinfo.name} </h6>
          </div>
        </Col>

        <Col xs="3" md="3" sm="3">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
            {productinfo.price}
          </h6>
        </Col>
        <Col xs="3" md="3" sm="3">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
          {productinfo.remaining_stock - productinfo.total_product_in_cart} Pcs
          </h6>
        </Col>
        <Col xs="3" md="3" sm="3" className={styles.tablePic}>
          <Link>
            <Button
              className={styles.iconButton}
              onClick={(e) => setModal(true)}
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
              {/* <img src={edit} /> */}
            </Button>
          </Link>
          <Button className={styles.iconButton}  onClick={(e) => deleteProduct(productinfo.id)}>
            {/* <img src={del} /> */}
            <i class="fa fa-trash" aria-hidden="true"></i>
          </Button>
        </Col>
      </Row>

      <hr />
    </>
  );
};
export default MyProduct;
