import React from "react";
import styles from "./productDetails.module.scss";

import Footer from "../../Components/Footer/Footer";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Modal, Alert, Button ,ModalFooter } from "reactstrap";

import { Container, Row, Col } from "reactstrap";
import ProductComponent from "../../Components/ProductComponent/ProductComponent";

const ProductDetails = (props) => {
  const toggle = () => props.setModal(!props.modal);
  
  return (
    <Modal
      centered
      contentClassName={styles.modal}
      className={styles.modal}
      toggle={toggle}
      isOpen={props.modal}
    >
      <ProductComponent id={props.id} cart={props.cart} products={props.products} cartitem={props.cartitem} toggle={toggle} />
      {/* <ModalFooter>
          <Button className={styles.btn} onClick={toggle}>Cancel</Button>
        </ModalFooter> */}
    </Modal>
  );
};

export default ProductDetails;
