import React, { useState } from "react";
import styles from "./OrderDetailsInfo.module.scss";
import { Container, Modal,ModalHeader,ModalBody,ModalFooter,Button,Row,Col } from "reactstrap";
import Constant from '../../constant.json';
const OrderDetailsInfo = (props) => {
  const productinfo = props.productinfo;
  console.log(productinfo)
  const [isLoading, setIsLoading] = useState(false);
  const toggle = () => props.toggle();
  return (
    <div>
      <Modal centered isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader className={styles.ModalHeader} toggle={toggle}>Order Details</ModalHeader>
      <ModalBody>
        <Container className={styles.container}>
          <Row>
            <Col xs="4">
               <h6 className={styles.label} > User Email :</h6>
            </Col>
            <Col xs="8">
            <h6 className={styles.details} style={{wordWrap:'break-word'}}>{productinfo.user_email} </h6>
            </Col>
          </Row>
          <Row>
            <Col xs="4">
               <h6 className={styles.label}> User Contact :</h6>
            </Col>
            <Col xs="8">
            <h6 className={styles.details} style={{wordWrap:'break-word'}}>{productinfo.user_mobile}</h6>
            </Col>
          </Row>
          <Row>
            <Col xs="4">
            <h6 className={styles.label}>User Name :</h6>
            </Col>
            <Col xs="8">
            <h6 className={styles.details} style={{wordWrap:'break-word'}}>{productinfo.first_name}{" "} {productinfo.last_name}</h6>
            </Col>
          </Row>
          <Row>
            <Col xs="4">
            <h6 className={styles.label}>Address :</h6>
            </Col>
            <Col xs="8">
            <h6 className={styles.details} style={{wordWrap:'break-word'}}>{productinfo.address}{","} {productinfo.city}{","}{productinfo.state}</h6>
            </Col>
          </Row>
          {
            productinfo.id_proof != '' ? 
            <Row>
            <Col xs="4">
             <h6 className={styles.label}>Document :</h6>
            </Col>
            <Col xs="8">
            <img
              src={
                
                productinfo.id_proof  ? Constant.API_URL + Constant.DOCUMENT_IMAGE +productinfo.id_proof :
                productinfo.id_proof
              }
              className={`${styles.image} img img-fluid`}
              alt="image not found"
            />
               
            </Col>
          </Row>
           : ''
          }
          
          </Container>
         
        </ModalBody>
        <ModalFooter className={styles.ModalFooter}>
          <Button className={styles.button} onClick={toggle}>close</Button>
        </ModalFooter>
       
      </Modal>
    </div>
  );
};

export default OrderDetailsInfo;
