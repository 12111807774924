import React, { useState,useEffect } from "react";
import styles from "./seller.module.scss";
import SideNav from "../../Components/Admin/SideNav/SideNav";
import TopNav from "../../Components/TopNav/TopNav";
import { Container, Row, Col, Button, Table } from "reactstrap";
import { Card, CardBody, CardTitle, Badge } from "reactstrap";
import dp from "../../Assets/mari.png";
import axios from "axios";
import Constant from '../../constant.json';
import NavVenderSm from "../../Components/Admin/NavVenderSm/NavVenderSm";
import Pagination from '../../Components/paginetion';
import SellerTable from "../../Components/Admin/Seller/SellerTable";
import "./override.scss";
import { useAuth } from "../../context/auth";
import Product from "../../Components/Products/Product";

const AdminSeller = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [StateList, setStateList] = useState([]);
  const [modal, setModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const toggle2 = () => setModal(!modal);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [state, setState] = useState({
    SellerData: {},
    pageSize : 10,
    pageOfItems: [],
    
    
  })
  const {
    SellerData,pageSize,pageOfItems
  } = state;
  useEffect(() => {
    (async () => {
      await GetStateList();
   await GetSellerList();
    })();
  },[update]);
  const GetStateList = async () => {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/state/1',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    await axios(config)
    .then(async function (response) {
      let data_result = response.data;
      setStateList(data_result.data)
      
    }) 
    setIsLoading(false);
  }
  async function GetSellerList() {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/auth/userlist',
      headers: {
        'Content-Type': 'application/json',
        
    },
    }
    await axios(config)
    .then(function (response) {
      let result = response.data;

      setState({
        ...state,
        SellerData: result.data
      });
      
    }) 
    setIsLoading(false);
  }
  let reloadtable = () => {
    
    setState({
      ...state,
      SellerData: {}
    });
    setUpdate(!update)
   // GetSellerList();
  }
  function onpageChange(pageOfItems) {
  
    setState({
      ...state,
      pageOfItems: pageOfItems
    });
    
  }
  return (
    <div className={styles.main_div} >
      
      <span className="d-none d-md-block">
        <TopNav />
      </span>
      <span className="d-block d-md-none">
        <NavVenderSm />
      </span>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Container className={`${styles.container} pt-4  mr-0 ml-0 p-0`} style={{ background: 'white' }}>
        
        <Row>
          <Col xs={2} className="d-none d-md-block">
            <SideNav />
          </Col>
          <Col xs={12} md={12} sm={12}>
            <div>
              <Card className={`${styles.card} mt-4  `} >
                <CardTitle className={`${styles.headingOrder} ml-4 mt-2`}>
                  Seller List
                  
                </CardTitle>

                <CardBody>
                  <Container className="p-0" className={styles.tablecontainer}>
                    <Row className={styles.row}>
                      <Col xs={2} md={2}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Name
                        </h6>
                      </Col>
                      <Col xs={2} md={2}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Email
                        </h6>
                      </Col>

                      <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.saleHead)}>
                        Contact
                        </h6>
                      </Col>
                      <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Shop Name
                        </h6>
                      </Col>
                      <Col xs={1} md={1}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Status
                        </h6>
                      </Col>
                      <Col xs={3} md={3}>
                        <h6 className={(styles.table_heading, styles.acttHead)}>
                          Action
                        </h6>
                      </Col>
                    </Row>
                    {
                      
                      SellerData.length > 0 ?
                      pageOfItems.map((item) => (
                        <SellerTable image={dp} imgDetail="Mariuana" reloadtable={reloadtable}  Data={item} StateList={StateList} />
                      )
                      ) : "No data found"
                    }
                    
                  </Container>
                  {
                  SellerData.length > 0 ?
                  <div className="pagination-row">
                    <Pagination items={SellerData} onChangePage={onpageChange}  pageSize={pageSize} />
                </div>
                : ''
                }
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminSeller;
