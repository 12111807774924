import React, { useState } from "react";
import styles from "./sendemail.module.scss";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { useAuth } from "../../context/auth";
import { Link, useHistory  } from "react-router-dom";
import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';
import Swal from 'sweetalert2';
import axios from 'axios';
import Constant from '../../constant.json';
import { validateAll } from 'indicative/validator';
import { Form, FormGroup, Label, Input, FormFeedback, FormText,Alert } from 'reactstrap';

const SendEmail = () => {
  const [focused, setFocus] = useState("");
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const { setAuthTokens } = useAuth();
  const [state, setState] = useState({
    itemEmail: '',
    errors: {},
  })

  const reducers = {
    session: sessionReducer
  };
  const reducer = combineReducers(reducers);
  
  const {
    itemEmail, errors,
  } = state;
  const onChangeEmail = (value) => {

    setState(prevState => ({
      ...prevState,
      itemEmail: value
    }));
  };

  async function postLink() {
    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/auth/forgotpassword',
      data: {
        email: itemEmail,
      }
    }
    let rs = await axios(config);
    return rs
  }

  // Login button Click
  const onSubmit = (e) => {
    e.preventDefault();
    
    const messages = {
      'itemEmail.required': 'Email cannot be empty.',
    };
    const rules = {
      itemEmail: 'required|string',
    };
    validateAll(state, rules, messages)
      .then(() => {
        setIsLoading(true);
        postLink()
          .then(function (response) {
            
            if (response.data.status == "1") {
              setState(prevState => ({
                ...prevState,
                itemEmail: ''
              }));
              Swal.fire({
                icon: 'success',
                title: 'Reset password link sent successfully',
                showConfirmButton: false,
                timer: 1500
              });    
              setIsLoading(false);
            } else {
              const formattedErrors = {};
              formattedErrors['login_msg'] = response.data.message;
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: formattedErrors['login_msg'],
              });
              setIsLoading(false);
            }
          })
          .catch(err => {    
            console.log(err);
            const formattedErrors = {};
            formattedErrors['login_msg'] = "Something went wrong"
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: formattedErrors['login_msg'],
            });
            setIsLoading(false);
          }) 

      })
      .catch(errors => {
        const formattedErrors = {
        };
        errors.forEach(error => formattedErrors[error.field] = error.message);
        setState({
          ...state,
          errors: formattedErrors
        });
      })

  }
  return (
    <React.Fragment>
      <span className="d-none d-md-block">
        <Nav />
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      <section className={styles.register_container}>
        <div className={styles.shadow_box}>
          <div className={styles.content_container}>
            <h1 className={styles.top_heading}>ENTER YOUR EMAIL</h1>
            <h6 className={styles.small_heading}>
              We will send you a link to reset password
            </h6>
            {/* EMAIL FIELD */}
            <div
              className={`${
                focused === "email"
                  ? styles.custom_input_focus
                  : styles.custom_input
              }`}
            >
              <span>
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </span>
              <input
                onFocus={() => setFocus("email")}
                onBlur={() => setFocus("")}
                type="text"
                placeholder="Enter Your Email"
                onChange={(e) => onChangeEmail(e.target.value)}
                value = {itemEmail}
              />
            </div>
            <FormFeedback style={{display:'block',textAlign:'center'}}>{errors.itemEmail}</FormFeedback>

            <div className={styles.btn_container}>
              <Link to="/verify-code">
                <button className="btn" onClick={(e) => onSubmit(e)}>Send Email</button>
              </Link>
            </div>
          </div>
          {/* <div className={styles.register_footer}>
          
          </div> */}
        </div>
      </section>
    </React.Fragment>
  );
};
export default SendEmail;
