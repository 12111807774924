import React, { useState } from "react";
import styles from "./signup.module.scss";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Link,useHistory } from "react-router-dom";
import { Stepper, Step } from "react-form-stepper";
import { validateAll } from 'indicative/validator';
import Constant from '../../constant.json';
import { Form, FormGroup, Label, Input, FormFeedback, FormText,Alert } from 'reactstrap';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import Swal from 'sweetalert2';
import { useAuth } from "../../context/auth";

const SignUp = () => {
  let history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [focused, setFocus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const { setAuthTokens,setAuthRole } = useAuth();
  const [isSuccess, setIsSuccess] = useState(false);
  const [state, setState] = useState({
    itemEmail: '',
    itemBussinessmName: '',
    itemName: '',
    itemContactNo: '',
    itemPassword: '',
    errors: {},
    fileData : '',
    disabled : false
  })

  
  const {
    fileData,itemEmail, itemPassword,itemContactNo,itemBussinessmName,disabled,itemName,itemZip,itemJobTitle,itemCompanyName,errors,
  } = state;
  const onChangeEmail = (value) => {

    setState(prevState => ({
      ...prevState,
      itemEmail: value
    }));
  };
  const onChangeName = (value) => {

    setState(prevState => ({
      ...prevState,
      itemName : value
    }));
  };
  const onChangecontactNo = (value) => {
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setState(prevState => ({
        ...prevState,
        itemContactNo: value
      }));
    }
  };
  const onChangePassword = (value) => {

    setState(prevState => ({
      ...prevState,
      itemPassword: value
    }));
  };
  const onChangeCompanyName = (value) => {

    setState(prevState => ({
      ...prevState,
      itemCompanyName: value
    }));
  };
  const onChangeJobTitle = (value) => {

    setState(prevState => ({
      ...prevState,
      itemJobTitle: value
    }));
  };

  const onChangeZip = (value) => {

    setState(prevState => ({
      ...prevState,
      itemZip: value
    }));
  };
  const Changestep = (e,step) => {
    e.preventDefault();
    if(step == 0){
      setState({
        ...state,
        errors: {}
      });
      setActiveStep(step);
    }else{
      const messages = {
        'itemEmail.required': 'Email cannot be empty.',
        'itemName.required': 'Name cannot be empty.',
        'itemContactNo.required': 'Contact no cannot be empty.',
        'itemContactNo.min': 'Contact no too short',
        'itemEmail.email': 'Email cannot be valid.',
        'itemPassword.required': 'Password cannot be empty.',
        'itemPassword.min': 'Password is too short',
        
      };
      const rules = {
        itemEmail: 'required|email',
        itemPassword: 'required|min:6',
        itemName : 'required',
        itemContactNo: 'required|min:9',
      };
      
      validateAll(state, rules, messages)
        .then(() => {
          setActiveStep(step);
        }).catch(errors => {
          const formattedErrors = {
  
          };
  
          errors.forEach(error => formattedErrors[error.field] = error.message);
  
          setState({
            ...state,
            errors: formattedErrors
          });
        })
    }
  
  }
  const onSubmitSave = (e) => {
    if (disabled) {
        return;
    }
    setState(prevState => ({
      ...prevState,
      disabled: true,
    }));
    
    e.preventDefault();
    
    const messages = {
      'itemEmail.required': 'Email cannot be empty.',
      'itemName.required': 'Name cannot be empty.',
      'itemContactNo.required': 'Contact no cannot be empty.',
      'itemEmail.email': 'Email cannot be valid.',
      'itemPassword.required': 'Password cannot be empty.',
      'itemPassword.min': 'Password is too short',
      'itemCompanyName.required': 'Company name cannot be empty.',
      'itemJobTitle.required': 'Job title cannot be empty.',
      'itemZip.required': 'Zip cannot be empty.',
      'itemZip.min': 'Zip is min 5 char.',
    };
    const rules = {
      itemEmail: 'required|email',
      itemPassword: 'required|min:6',
      itemName: 'required',
      itemContactNo: 'required',
      itemCompanyName: 'required',
      itemJobTitle: 'required',
      itemZip: 'required|min:5',

    };
    validateAll(state, rules, messages)
      .then(() => {
        setIsLoading(true);
        postRegister()
          .then(function (response) {
            
            if(response.data.status == "1") {
              if(fileData != ''){
                const documentconfig = {
                  method: 'post',
                  url: Constant.API_URL + '/api/auth/uploaddocument',
                  data: fileData
                }
                axios(documentconfig)
                .then(function (response) { 
                  Swal.fire({
                    icon: 'success',
                    title: 'Signup successfully',
                    showConfirmButton: false,
                    timer: 1500
                  });
                  setIsFileUpload(true)
                  setIsLoading(false);
                  history.push("/login");
                  setIsSuccess(true);
                  LoginNow();
                }).catch(err => {
                  let formattedErrors = {};
                  formattedErrors['error_info'] = "There is some error please contact admin."
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: formattedErrors['error_info'],
                  });
                  setIsLoading(false);
                });
              }else{
                LoginNow();
              }
              
            } else {
              setState(prevState => ({
                ...prevState,
                disabled: false,
              }));
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.data.message,
              });
              setIsLoading(false);
            }
           

          }).catch(err => {
            
            let formattedErrors = {};
            formattedErrors['error_info'] = "There is some error please contact admin."
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: formattedErrors['error_info'],
            });
            setIsLoading(false);
          }) 

      })
      .catch(errors => {
        const formattedErrors = {

        };
        setState(prevState => ({
          ...prevState,
          disabled: false,
        }));
        errors.forEach(error => formattedErrors[error.field] = error.message);

        setState({
          ...state,
          errors: formattedErrors
        });
      })

  }
  async function postLogin() {
    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/auth/signin',
      data: {
        email: itemEmail,
        password: itemPassword,
      }
    }
    let rs = await axios(config);
    return rs
  }
  function LoginNow(){
    postLogin()
          .then(function (response) {
            if (response.data.status == "1") {
              Swal.fire({
                icon: 'success',
                title: 'Logged in successfully',
                showConfirmButton: false,
                timer: 1500
              });
              setAuthTokens(response.data.accessToken);
              setIsLoading(false);
              setAuthRole(response.data.roles)
              localStorage.setItem('token',response.data.accessToken);
              localStorage.setItem('name',response.data.name);
              localStorage.setItem('email',response.data.email);
              localStorage.setItem('role',response.data.roles);
              if(response.data.roles == 'ROLE_ADMIN'){
                history.push("/admin/dashboard");
              }else{
                history.push("/dashboard");
              }
              
              
            } else {
              const formattedErrors = {};
              formattedErrors['login_msg'] = response.data.message;
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: formattedErrors['login_msg'],
              });
              /*setState({
                ...state,
                errors: formattedErrors
              });*/
              setIsLoading(false);
            }
          })
          .catch(err => {
            const formattedErrors = {};
            formattedErrors['login_msg'] = "Email or Password not match."
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: formattedErrors['login_msg'],
            });
            /*setState({
              ...state,
              errors: formattedErrors
            });*/
            setIsLoading(false);
          }) 

  }
  async function postRegister() {

    setIsLoading(true);  
    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/auth/signup',
      data: {
        name: itemName,
        contact: itemContactNo.toString(),
        email: itemEmail,
        company_name: itemCompanyName,
        title: itemJobTitle,
        zip: itemZip,
        password: itemPassword,
      }
    }
    const result = await axios(config);
    
    return result;

  }
  const fileSelect =  (e) => {
    
    const file = e.target.files[0];
    const data = new FormData();
    data.append('signupdocument', file);
    data.append('email',itemEmail);

    setState(prevState => ({
      ...prevState,
      fileData: data
    }));
    
}

  return (
    <React.Fragment>
      <MetaTags>
        <title>Signup</title>
        <meta name="description" content="Blaze Broker" />
        <meta property="og:title" content="Blaze Broker - Signup" />
      </MetaTags>
      <span className="d-none d-md-block">
        <Nav />
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Stepper
        steps={[{ label: "Basic Info",}, { label: "Company details"}]}
        activeStep={activeStep}
      />
        {/* <Step label="Basic Info" style={{ backgroundColor:"orange" }} />
        <Step label="Company details" style={{ backgroundColor:"green" }} />  */}
      {
              isSuccess ? (
                <Alert color="success">
                  SignUp Success.! please verify your email.!
                </Alert>
              ) :
              ''
            }
      {
              errors.error_info ? (
                <Alert color="danger">
                  {errors.error_info}
                </Alert>
              ) : ''
            }
      {activeStep === 0 ? (
        <section className={styles.register_container}>
          <div className={styles.shadow_box}>
            <div className={styles.content_container}>
              <h1 className={styles.top_heading}>SIGN UP</h1>
              {/* NAME FIELD */}
              <div  
                className={`${
                  focused === "name"
                    ? styles.custom_input_focus
                    : styles.custom_input
                }`}
              >
                <span>
                  <i class="fa fa-user" aria-hidden="true"></i>
                </span>
                <Input
                invalid = {errors.itemName}
                  onFocus={() => setFocus("name")}
                  onBlur={() => setFocus("")}
                  type="text"
                  value = {itemName}
                  placeholder="Enter Your Name"
                  onChange={(e) => onChangeName(e.target.value)}
                />
              </div>
              <FormFeedback style={{display:'block',textAlign:'center'}}>{errors.itemName}</FormFeedback>
              {/* EMAIL FIELD */}
              <div
                className={`${
                  focused === "email"
                    ? styles.custom_input_focus
                    : styles.custom_input
                }`}
              >
                <span>
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </span>
                <Input
                invalid = {errors.itemEmail}
                  onFocus={() => setFocus("email")}
                  onBlur={() => setFocus("")}
                  type="text"
                  value = {itemEmail}
                  placeholder="Enter Your Email"
                  onChange={(e) => onChangeEmail(e.target.value)}
                />
              </div>
              <FormFeedback style={{display:'block',textAlign:'center'}}>{errors.itemEmail}</FormFeedback>
              {/* PHONE FIELD */}
              <div
                className={`${
                  focused === "mob"
                    ? styles.custom_input_focus
                    : styles.custom_input
                }`}
              >
                <span>
                  <i class="fa fa-mobile" aria-hidden="true"></i>
                </span>
                <Input
                  invalid = {errors.itemContactNo}
                  onFocus={() => setFocus("mob")}
                  onBlur={() => setFocus("")}
                  type="text"
                  value = {itemContactNo}
                  placeholder="Enter Your Contact No"
                  onChange={(e) => onChangecontactNo(e.target.value)}
                />
              </div>
              <FormFeedback style={{display:'block',textAlign:'center'}}>{errors.itemContactNo}</FormFeedback>
              {/* PASSWORD FIELD */}
              <div
                className={`${
                  focused === "password"
                    ? styles.custom_input_focus
                    : styles.custom_input
                }`}
              >
                <span>
                  <i class="fa fa-lock" aria-hidden="true"></i>
                </span>
                <Input
                  invalid = {errors.itemPassword}
                  onFocus={() => setFocus("password")}
                  onBlur={() => setFocus("")}
                  type="password"
                  value = {itemPassword}
                  placeholder="Enter Your Password"
                  onChange={(e) => onChangePassword(e.target.value)}
                />
              </div>
              <FormFeedback style={{display:'block',textAlign:'center'}}>{errors.itemPassword}</FormFeedback>
              <div className={styles.btn_container}>
                <button onClick={(e) => Changestep(e,1)} className="btn">
                  NEXT
                </button>
              </div>
              <small className={styles.text_bottom}>
                By creating an account, you agree to the BlazeBroker.com
                <br />
                <Link to="terms-and-condition"> Free Membership Agreement </Link> and
                <Link to="suppliers-policy"> Privacy Policy</Link>
              </small>
            </div>
            <div className={styles.register_footer} style={{ background : 'white' }}>
              <small className={styles.text_bottom} style={{ fontWeight : 'bold', fontSize:'16px' }}>
                Already have an account? <Link to="login">Sign in Now</Link>
              </small>
            </div>
          </div>
        </section>
      ) : null}
      {activeStep === 1 ? (
        <section className={styles.register_container}>
          <div className={styles.shadow_box}>
            <div className={styles.content_container}>
              <h1 className={styles.top_heading}>Company Details</h1>
              {/* NAME FIELD */}

              <div className="row">
                  <div className="col-md-4">
                      <label>Choose Document</label>
                  </div>
                  <div className="col-md-8">
                      <input type="file" className="form-control" onChange = {(e) => fileSelect(e)} />
                  </div>
                  <FormFeedback>{errors.itemCompanyName}</FormFeedback>
              </div>
              <div
                className={`${
                  focused === "company"
                    ? styles.custom_input_focus
                    : styles.custom_input
                }`}
              >
                <span>
                  <i class="fa fa-user" aria-hidden="true"></i>
                </span>
                <Input
                invalid = {errors.itemCompanyName}
                  onFocus={() => setFocus("company")}
                  onBlur={() => setFocus("")}
                  type="text"
                  value = {itemCompanyName}
                  placeholder="Enter Your Company Name"
                  onChange={(e) => onChangeCompanyName(e.target.value)}
                />
                <FormFeedback>{errors.itemCompanyName}</FormFeedback>
              </div>
              <div
                className={`${
                  focused === "job"
                    ? styles.custom_input_focus
                    : styles.custom_input
                }`}
              >
                <span>
                  <i class="fa fa-user" aria-hidden="true"></i>
                </span>
                <Input
                invalid = {errors.itemJobTitle}
                  onFocus={() => setFocus("job")}
                  onBlur={() => setFocus("")}
                  type="text"
                  value = {itemJobTitle}
                  placeholder="Enter Your Job Title"
                  onChange={(e) => onChangeJobTitle(e.target.value)}
                />
                <FormFeedback>{errors.itemJobTitle}</FormFeedback>
              </div>
              <div
                className={`${
                  focused === "zip"
                    ? styles.custom_input_focus
                    : styles.custom_input
                }`}
              >
                <span>
                  <i class="fa fa-user" aria-hidden="true"></i>
                </span>
                <Input
                invalid = {errors.itemZip}
                  onFocus={() => setFocus("zip")}
                  onBlur={() => setFocus("")}
                  type="text"
                  value = {itemZip}
                  placeholder="Enter zip"
                  onChange={(e) => onChangeZip(e.target.value)}
                />
                <FormFeedback>{errors.itemZip}</FormFeedback>
              </div>
              {/* NAME FIELD */}
              { /* <span>
                <input
                  style={{ display: "none" }}
                  type="file"
                  name="file"
                  id="file"
                  className={styles.inputfile}
                />
              </span>
              <label for="file" className={styles.label}>
                Upload
              </label> */ }
              <div className={styles.btn_container}>
                {/* <Link to="/dashboard"> */}
                <button style={{width:'180px'}} onClick={(e) => Changestep(e,0)} className="btn">
                  Previous
                </button>
                  <button className="btn" disabled={disabled} style={{width:'180px'}} onClick={(e) => onSubmitSave(e)}>
                    
                    {disabled ? 'Sending...' : 'Sign Up'}
                    </button>
                {/* </Link> */}
                
              </div>
              <small className={styles.text_bottom}>
                By creating an account, you agree to the BlazeBroker.com
                <br />
                <Link> Free Membership Agreement </Link> and
                <Link> Privacy Policy</Link>
              </small>
            </div>
            <div className={styles.register_footer} style={{ background : 'white' }}>
              <small className={styles.text_bottom} style={{ fontWeight : 'bold', fontSize:'16px' }}>
                Already have an account? <Link to="login">Sign in Now</Link>
              </small>
            </div>
          </div>
       
        </section>
      ) : null}
    </React.Fragment>
  );
};
export default SignUp;
