import React,{useState} from "react";
import { Route } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  FormFeedback, FormText, Alert
} from "reactstrap";
import Swal from 'sweetalert2';
import styles from "./infodetail.module.scss";
import FormInput from "../../../Components/FormInput/FormInput";
import { validateAll } from 'indicative/validator';
import axios from 'axios';
import { useAuth } from "../../../context/auth";
import Constant from '../../../constant.json';
import edit from "../../../Assets/edit.svg";
import dp from "../../../Assets/mari.png";
import styles_text from "../../FormInput/form-input.module.scss";
import { Modal } from "reactstrap";

const SellerInfoDetail = (props) => {
  const auth = useAuth();
  const info = props.info;
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    name: props.info.name,
    email: props.info.email,
    contact: props.info.contact,
    title: props.info.title,
    company_name: props.info.company_name,
    zip: props.info.zip,
    phone: props.info.phone,
    address: props.info.address,
    province: props.info.province,
    city: props.info.city,
    area: props.info.area,
    shop_name: props.info.shop_name,
    status: props.info.status,
    id:props.info.id,
    errors: {},
  })

  
  const {
    id,name,email,contact,title,company_name,zip,phone,address,province,city,area,shop_name,status,errors,
  } = state;
  const onChangeEvent = (e) => {

    const value = e.target.value;
    const re = /^[0-9\b]+$/;
    if(e.target.name == 'contact' || e.target.name == 'phone'){
      if (value === '' || re.test(value)) {
        setState({
          ...state,
          [e.target.name]: value
        });
      }
    }else{
      setState({
        ...state,
        [e.target.name]: value
      });
    }
    
  };
  const onSubmitSave = (e) => {
    
    e.preventDefault();
    setIsLoading(true);
    const messages = {
      'name.required': 'Name cannot be empty.',
      'name.max': 'Name cannot be more then 15 char.',
      'shop_name.required': 'Shop Name cannot be empty.',
      'shop_name.max': 'Shop name cannot be more then 20 char.',
      'email.required': 'Email cannot be empty.',
      'email.email': 'Please enter valid email.',
      'contact.required': 'Contact cannot be empty.',
      'contact.min': 'please enter valid number',
      'contact.max': 'please enter valid number',
      // 'title.required': 'Title cannot be empty.',
      'company_name.required': 'Company name cannot be empty.',
      // 'zip.required': 'Zip cannot be empty.',
      'phone.required': 'Phone cannot be empty.',
      'address.required': 'Address cannot be empty.',
      'address.max': 'Address cannot be more then 50 char.',
      'province.required': 'State cannot be empty.',
      'city.required': 'City cannot be empty.',
      'city.max': 'City cannot be more then 15 char.',
      
    };
    const rules = {
      email: 'required | email',
      company_name: 'required',
      
      phone: 'required',
      name: 'required|max:15',
      shop_name: 'required|max:20',
      contact: 'required|max:10|min:10',
      address: 'required|max:50',
      city: 'required|max:15',
      province: 'required',
      
      
    };
    validateAll(state, rules, messages)
    .then(() => {
      
      postUpdateSeller()
        .then(function (response) {
          if(response.data.status == "1") {
            let result = response.data;
            props.toggle();
            Swal.fire({
              icon: 'success',
              text: result.message,
            });
          }
          
        }).catch(err => {
          let formattedErrors = {};
          formattedErrors['error_info'] = "There is some error please contact admin."
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: formattedErrors['error_info'],
          });
          props.toggle();
        }) 
        setIsLoading(false);
    })
    .catch(errors => {
      const formattedErrors = {

      };
      
      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
      setIsLoading(false);
    })
  }
  async function postUpdateSeller() {

    
    const config = {
      method: 'put',
      url: Constant.API_URL + '/api/auth/sellerupdate',
      data: {
        name: name,
        shop_name : shop_name,
        email: email,
        contact: contact,
        // title: title,
        company_name: company_name,
        // zip: zip,
        phone: phone,
        address: address,
        city : city,
        province : province,
        id:id
        
      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
      
    }
    const result = await axios(config);
    return result;

  }
  
  return (
    <Modal centered isOpen={props.modal} toggle={props.toggle} className={styles.modal_contaner}>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Container className="mt-3">
        <div className={styles.main}>
          <Row className={styles.row}>
            <Col xs="12" md="12" sm="12">
              <div className={styles.topHeading}>
                <Row>
                  <Col xs="8" md="8" sm="8" className={styles.left}>
                    <h3>Edit Seller</h3>
                  </Col>
                  <Col xs="4" xs="4" sm="4" className={styles.right}>
                    {/* <img src={edit} /> */}
                    <a className="close" href="#" onClick={()=>props.toggle()}>×</a>
                  </Col>
                </Row>
              </div>
              <hr />

              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Owner Name
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.name}
                    type='text'
                    name="name"
                    id="name"
                    className={`${styles_text.input}`}
                    placeholder="Enter Name"
                    defaultValue={props.info.name}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.name}</FormFeedback>
                </Col>
                
              </FormGroup>
              
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Shop Name
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.shop_name}
                    type='text'
                    name="shop_name"
                    id="shop_name"
                    className={`${styles_text.input}`}
                    placeholder="Shop name"
                    defaultValue={props.info.shop_name}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.shop_name}</FormFeedback>
                </Col>
              </FormGroup>
              
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Email
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.email}
                    type='email'
                    name="email"
                    id="email"
                    className={`${styles_text.input}`}
                    placeholder="email"
                    value={props.info.email}
                    disabled={true}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.email}</FormFeedback>
                </Col>
              </FormGroup>
              
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Mobile Number
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.contact}
                    type='text'
                    name="contact"
                    id="contact"
                    className={`${styles_text.input}`}
                    placeholder="Mobile Number"
                    defaultValue={props.info.contact}
                    value = {contact}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.contact}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Phone Number
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.phone}
                    type='text'
                    name="phone"
                    id="phone"
                    className={`${styles_text.input}`}
                    placeholder="Phone Number"
                    defaultValue={props.info.phone}
                    value = {phone}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.phone}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Shop Address
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.address}
                    type='text'
                    name="address"
                    id="address"
                    className={`${styles_text.input}`}
                    placeholder="Shop Address"
                    defaultValue={props.info.address}
                    value = {address}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.address}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  State
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.province}
                    type='select'
                    name="province"
                    id="province"
                    className={`${styles_text.input}`}
                    placeholder="State"
                    defaultValue={props.info.province}
                    onChange={(e) => onChangeEvent(e)}
                  >
                    <option>Please choose your state</option>
                    {
                                
                                props.StateList.map((item,index) => (
                                  <option key={index} value={item.id}>{item.name}</option>
                                  ))
                                
                              }
                    </Input>
                  <FormFeedback style={{display:'block'}}>{errors.province}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  City
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.city}
                    type='text'
                    name="city"
                    id="city"
                    value = {city}
                    className={`${styles_text.input}`}
                    placeholder="City"
                    defaultValue={props.info.city}
                    onChange={(e) => onChangeEvent(e)}
                  >
                    
                    </Input>
                  <FormFeedback style={{display:'block'}}>{errors.city}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Area
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.area}
                    type='select'
                    name="area"
                    id="area"
                    className={`${styles_text.input}`}
                    placeholder="Area"
                    defaultValue={props.info.area}
                    onChange={(e) => onChangeEvent(e)}
                  >
                    <option>Please choose your Area</option>
                    </Input>
                  <FormFeedback style={{display:'block'}}>{errors.area}</FormFeedback>
                </Col>
              </FormGroup>
                  
             </Col>

            <Button className={styles.btn} onClick={(e) => onSubmitSave(e)}>Save</Button>
          </Row>
          <br />
        </div>
      </Container>
    </Modal>
  );
};
export default SellerInfoDetail;
