import React,  { useState,useEffect } from "react";
import styles from "./dashboard.module.scss";
import SideNav from "../../Components/Admin/SideNav/SideNav";
import TopNav from "../../Components/TopNav/TopNav";
import dp from "../../Assets/dp.jpg";
import { Link } from "react-router-dom";
import { Row, Col,  Container, Button } from "reactstrap";
import { useAuth } from "../../context/auth";
import { Card, CardBody, CardTitle } from "reactstrap";
import NavVenderSm from "../../Components/Admin/NavVenderSm/NavVenderSm";
import MetaTags from 'react-meta-tags';
import Pagination from '../../Components/paginetion';
import MyProduct from "../../Components/Admin/MyProduct/MyProduct";
import axios from "axios";
import Constant from '../../constant.json';
import OrdersList from "../../Components/Admin/Orders/OrdersList";
import SellerTable from "../../Components/Admin/Seller/SellerTable";

const AdminDashboard = () => {
  const auth = useAuth();
  let image_path = localStorage.getItem('profile_image_url');
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [ProductData, setProductData] = useState({});
  const [OrderData, setOrderData] = useState({});
  const [SellerData, setSellerData] = useState({});
  const [StateList, setStateList] = useState([]);
  const [ProductType, setProductType] = useState([]);
  const [state, setState] = useState({
    pageSize : 5,
    pageOfItems: [],
    pageOfOrder : [],
    pageOfSeller : []
    
  })
  const {
    pageSize,pageOfItems,pageOfOrder,pageOfSeller
  } = state;
  useEffect(() => {
    GetStateList();
    GetProductType();
    GetProductList();
    
    GetOrderList();
    GetSellerList();
  },[reload]);
  const GetProductType = async () => {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/product_type',
      headers: {
        'Content-Type': 'application/json',
        
    },
    }
    await axios(config)
    .then(async function (response) {
      let data_result = response.data;
      setProductType(data_result.data)
      
    }) 
    setIsLoading(false);
  }
  const GetStateList = async () => {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/state/1',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    await axios(config)
    .then(async function (response) {
      let data_result = response.data;
      setStateList(data_result.data)
      
    }) 
    setIsLoading(false);
  }
  async function GetSellerList() {
    setIsLoading(true);
    
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/auth/userlist',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    return await axios(config)
    .then(function (response) {
      let result = response.data;
      setSellerData(result.data);
      setIsLoading(false);  
    });
    
  }
  async function GetProductList() {
    setIsLoading(true);
    
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/products/all',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    return await axios(config)
    .then(function (response) {
      let result = response.data;
      setProductData(result.data);
      setIsLoading(false);  
    });
    
  }
  async function GetOrderList() {
    setIsLoading(true);
    
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/products/allorderlist',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    return await axios(config)
    .then(function (response) {
      let result = response.data;
      
      setOrderData(result.data);
      setIsLoading(false);
    }) 
    
  }
  function onproductpageChange(pageOfItems) {
  
    setState({
      ...state,
      pageOfItems: pageOfItems
    });
    
  }
  function onorderpageChange(pageOfOrder) {
    
    setState({
      ...state,
      pageOfOrder: pageOfOrder
    });
    
  }
  function onsellerpageChange(pageOfSeller) {
    
    setState({
      ...state,
      pageOfSeller: pageOfSeller
    });
    
  }
  let reloadsellertable = () => {
    // GetProductList();
    // GetOrderList();
    // GetSellerList();
    setSellerData({});
    setReload(!reload)
  }
  let reloadordertable = () => {
    setOrderData({});
    setReload(!reload)
  }
  let reloadproducttable = () => {
    setProductData({});
    setReload(!reload)
  }
  return (
    <div className={styles.main_div} >
      
      <MetaTags>
        <title>Dashboard</title>
        <meta name="description" content="Blaze Broker" />
        <meta property="og:title" content="Blaze Broker - Dashboard" />
      </MetaTags>
      <span className="d-none d-md-block">
        <TopNav />
      </span>
      <span className="d-block d-md-none">
        <NavVenderSm />
      </span>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Container className={`${styles.container} pt-4 mr-0 ml-0 p-0`} style={{ background: 'white' }}>
        <div className={styles.main}>
          <div className={styles.dp}>
            <img src={image_path != '' ? image_path : dp} className="img img-fluid" />
          </div>

          <h3>{localStorage.getItem('name')}</h3>
        </div>

        <Row>
          <Col xs={2} className="d-none d-md-block">
            <SideNav />
          </Col>
          <Col xs={12} md={12} sm={12}>
          <Card xs={12} md={12} sm={12} className={`${styles.card} mt-4  `}>
              <CardTitle className={`${styles.headingOrder} ml-4 mt-2`}>
                SELLER
                <Link to="seller">
                  <Button className={styles.butt}>View All</Button>
                </Link>
              </CardTitle>
              <hr />

              <CardBody>
              <Container className="p-0" className={styles.tablecontainer}>
                    <Row className={styles.row}>
                      <Col xs={2} md={2}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Name
                        </h6>
                      </Col>
                      <Col xs={2} md={2}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Email
                        </h6>
                      </Col>

                      <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.productHead)}>
                        Contact
                        </h6>
                      </Col>
                      <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Shop Name
                        </h6>
                      </Col>
                      <Col xs={1} md={1}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Status
                        </h6>
                      </Col>
                      <Col xs={3} md={3}>
                        <h6 className={(styles.table_heading, styles.acttHead)}>
                          Action
                        </h6>
                      </Col>
                    </Row>
                    {
                      
                      SellerData.length > 0 ?
                      pageOfSeller.map((item) => (
                        <SellerTable image={dp} imgDetail="Mariuana" key={item.id} reloadtable={reloadsellertable}  Data={item} StateList={StateList} />
                      )
                      ) : "No data found"
                    }
                    
                  </Container>
                  {
                  SellerData.length > 0 ?
                  <div className="pagination-row">
                    <Pagination items={SellerData} onChangePage={onsellerpageChange}  pageSize={pageSize} />
                </div>
                : ''
                }
              </CardBody>
            </Card>
            
            <Card xs={12} md={12} sm={12} className={`${styles.card} mt-4  `}>
              <CardTitle className={`${styles.headingOrder} ml-4 mt-2`}>
                PRODUCTS
                <Link to="product-page">
                  <Button className={styles.butt}>View All</Button>
                </Link>
              </CardTitle>
              <hr />

              <CardBody>
                <Container className="p-0" className={styles.tablecontainer}>
                  <Row className={styles.row}>
                    <Col xs={3} md={3}>
                      {" "}
                      <h6
                        className={(styles.table_heading, styles.productHead)}
                      >
                        Product
                      </h6>
                    </Col>
                    <Col xs={2} md={2}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Seller
                        </h6>
                      </Col>
                    <Col xs={2} md={2}>
                      <h6 className={(styles.table_heading, styles.saleHead)}>
                        Sale Price
                      </h6>
                    </Col>
                    <Col xs={2} md={2}>
                      <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Qty Available
                      </h6>
                    </Col>
                    <Col xs={1} md={1}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                          Status
                        </h6>
                      </Col>
                    <Col xs={2} md={2}>
                      <h6 className={(styles.table_heading, styles.acttHead)}>
                        Action
                      </h6>
                    </Col>
                  </Row>
                  {
                      
                      ProductData.length > 0 ?
                      pageOfItems.map((item) => (
                        <MyProduct image={dp} imgDetail="Mariuana" reloadtable={reloadproducttable}  ProductData={item} ProductType={ProductType} />
                      )
                      ) : "No data found"
                    }
                    
                </Container>
                {
                  ProductData.length > 0 ?
                  <div className="pagination-row">
                    <Pagination items={ProductData} onChangePage={onproductpageChange}  pageSize={pageSize} />
                </div>
                : ''
                }
              </CardBody>
            </Card>
            
            <div>
              <Card xs={12} md={12} sm={12} className={`${styles.card} mt-4  `}>
                <CardTitle className={`${styles.headingOrder} ml-4 mt-2`}>
                  ORDERS
                  <Link to="orders">
                    <Button className={styles.butt}>View All</Button>
                  </Link>
                </CardTitle>
                

                <CardBody>
                <Container className="p-0" className={styles.tablecontainer}>
                    <Row className={styles.row}>
                    {/* <Col xs={1} md={1}>
                        
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          No.
                        </h6>
                      </Col> */}
                      <Col xs={3} md={3}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Product Name
                        </h6>
                      </Col>
                      <Col xs={2} md={2}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Seller Name
                        </h6>
                      </Col>
                      <Col xs={1} md={1}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Price
                        </h6>
                      </Col>

                      <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.productHead)}>
                        Email
                        </h6>
                      </Col>
                      {/* <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.productHead)}>
                        Contact
                        </h6>
                      </Col> */}
                      <Col xs={1} md={1}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Qty
                        </h6>
                      </Col>
                      <Col xs={3} md={3}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Confirm
                        </h6>
                      </Col>
                      
                    </Row>
                    {
                      
                      OrderData.length > 0 ?
                      pageOfOrder.map((item) => (
                        <OrdersList image={dp} imgDetail="Mariuana" reloadtable={reloadordertable}  OrderData={item} />
                      )
                      ) : "No data found"
                    }
                    
                  </Container>
                  {
                  OrderData.length > 0 ?
                  <div className="pagination-row">
                    <Pagination items={OrderData} onChangePage={onorderpageChange}  pageSize={pageSize} />
                </div>
                : ''
                }
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AdminDashboard;
