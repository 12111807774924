import React, { useState, useEffect } from "react";
import styles from "./mycart.module.scss";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import Footer from "../../Components/Footer/Footer";
import Cart from "../../Components/Cart/Cart";
import { Container, Row, Col, Button } from "reactstrap";
import Checkout from "../../Components/Checkout/Checkout";
import Constant from '../../constant.json';
import {dflt, noop, ajax, l, isset,blank, ref,makeuniqueID} from '../../helpers';
import axios from "axios";

const MyCart = (props) => {
  const cart = localStorage.getItem("cart");
  const [uniqueID, setuniqueID] = useState(makeuniqueID());
  const [cartItems, setCartItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [productPrice, setProductPrice] = useState([]);
  const [totalPrice, setTotalPrice] = useState();
  const [update, setUpdate] = useState(true);
  
  const toggle = () => setModal(!modal);
  
  async function GetCartList() {
      
    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/cartitem/list',
      data: {
        session_id: uniqueID,
      },
    }
    await axios(config)
      .then(async (response) => {
        let result = response.data;
        if (result) {
          let obj = result.data;
          var rows = [];
           for (var i = 0; i < obj.length; i++) {
             const item = {
               product : JSON.parse(obj[i].product),
               qty: obj[i].cart_qty,
               price: obj[i].price * obj[i].cart_qty,
               uniqueID : obj[i].uniqueID,
               CartId : obj[i].cart_id,
             };
            await rows.push(item);
             
           }
           localStorage.setItem("cart", JSON.stringify(rows));
         
          return true;
         
        }
      })
  }
  
  useEffect(() => {
    
    setCartItems([]);
    (async () => {
      
      await GetCartList().then(async function(response){
  
        const cart = localStorage.getItem("cart");
        const data = JSON.parse(cart)
        setProductPrice([])
        
        data.forEach((item) => {
        productPrice.push(item.price);
      });
      const sum = productPrice.reduce(function (a, b) {
        return a + b;
      }, 0);
      
      setTotalPrice(sum);
      setCartItems(JSON.parse(cart));
      });
     // await setProductPrice([])
      
     
   // setCartItems(JSON.parse(cart));
 })();

    
  }, [update]);

  return (
    <div
    style={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
    >
      <span className="d-none d-md-block">
        <Nav />
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      
      <Container className={styles.container}>
        <div className={`${styles.mainHeading}`}>
          <h1>
            My Cart <span>
              {
                isset(totalPrice) ? `(Total: ${totalPrice}$ USD)` : ''
              }
              </span>
          </h1>
          {cartItems.length > 0 ? (
          <div className={styles.wishitem_btn}>
            <Button   onClick={(e) => setModal(true)}>
              
              Check Out
            </Button>
          </div>
           ) : '' }
        </div>
        <hr />

        <div className="pt-3 mb-4">
          {cartItems.length === 0 ? (
             <h1 className={`${styles.empty_cart} pb-5`}></h1>
            //  NO ITEMS FOUND
          ) : 
          
          cartItems.map((item,i) => (
            <Row>
              
              <Col xs="12" >
                <Cart
                  key={i}
                  rowid={i}
                  CartId = {item.CartId}
                  uniqueID = {item.uniqueID}
                  product={item.product}
                  price={item.price ? item.price : 0}
                  qty={item.qty}
                  name={item.product.name}
                  image={item.product.image ? Constant.API_URL + Constant.RESIZE_IMAGE+ item.product.image : 'https://www.visitcalifornia.com/sites/visitcalifornia.com/files/styles/welcome_image/public/vc_spotlightrooftopretreats_hero_sandiego_su_theandaz_rooftoppool_1280x640.jpg'}
                  productId={item.product.id}
                  setUpdate={setUpdate}
                  update={update}
                  setCartItemscustome={setCartItems}
                />
              </Col>
            </Row>
          ))
      }
        </div>

        <Checkout modal={modal} totalPrice={totalPrice} cartItems={cartItems} toggle={toggle} />
      </Container>

      <Footer />
    </div>
  );
};

export default MyCart;
