import React, {useState} from "react";
import styles from "./paymentmethod.module.scss";
import { Container, Row, Col } from "reactstrap";
import { Card, CardBody, CardTitle, Button,Input,FormFeedback } from "reactstrap";
import CreditCardInput from 'react-credit-card-input';
// import flag from "../../Assets/pakFlag.svg";

import { validateAll } from 'indicative/validator';
const PaymentMethod = (props) => {
  
  const [state, setState] = useState({
    CardHolderName: props.CardHolderName,
    cardExpiry : props.ExpiryMonth,
    cardCVC : props.CVC,
    cardNumber : props.CardNumber,
    paymenttypemethod : props.paymenttypemethod,
    errors: {},
  })

  
  const {
    CardHolderName, cardExpiry,cardCVC,cardNumber,paymenttypemethod,errors
  } = state;
  const onChangeEvent = (e) => {

    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  };
  const validateStepTwo = (e) => {
    e.preventDefault();
    


    const messages = {
      'CardHolderName.required': 'Card Holder Name cannot be empty.',
      'cardExpiry.required': 'Expiry Month cannot be empty.',
      'cardCVC.required': 'CVC cannot be empty.',
      'cardCVC.min': 'CVC cannot be valid.',
      'cardNumber.required': 'Card Number cannot be empty.',
      
      
    };
    let rules = {}
    if(paymenttypemethod == 'card'){
       rules = {
        CardHolderName: 'required',
        cardCVC: 'required|min:3',
        cardExpiry: 'required',
        cardNumber: 'required',
        
        
      };
    }
    
    validateAll(state, rules, messages).then(() => {
      props.onCardHolderName(CardHolderName);
      props.onCVC(cardCVC);
      props.onExpiryMonth(cardExpiry);
      props.onCardNumber(cardNumber);
      props.onpaymenttypemethod(paymenttypemethod);
      props.setActiveStep(2);
      
      
    }).catch(errors => {
      const formattedErrors = {

      };

      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
    })
    
  }
  const onCVC = (e) => {
    const value = e.target.value;
    setState(prevState => ({
      ...prevState,
      cardCVC: value
    }));
  }
  const onExpiryMonth = (e) => {
    const value = e.target.value;
    setState(prevState => ({
      ...prevState,
      cardExpiry: value
    }));
  }
  const onCardNumber = (e) => {
    const value = e.target.value;
    setState(prevState => ({
      ...prevState,
      cardNumber: value
    }));
  }
  const onSiteChanged =  (e) =>{
    setState(prevState => ({
      ...prevState,
      paymenttypemethod: e.currentTarget.value
    }));
    
  }
  return (
    <Container>
      <Row>
        <Col xs="12">
          <Card className={`${styles.cardPayment} mt-4 mb-4 `}>
            <CardTitle className={`${styles.headingMain} ml-4 mt-4 mb-0`}>
              PAYMENT METHOD
            </CardTitle>
            <hr />

            <CardBody className=" pt-0">
              <Row>
                <Col xs="12">
                <div class="form-group">
                  <input type="radio" name="paymenttypemethod" value='card'
                      checked={state.paymenttypemethod === 'card'} 
                      onChange={onSiteChanged}
                  /> Card Payment
                </div>
                </Col>
                <Col xs="12">
                <div class="form-group">
                  <input type="radio" name="paymenttypemethod" value='bitcoin'
                      checked={state.paymenttypemethod === 'bitcoin'} 
                      onChange={onSiteChanged}
                  /> BitCoin
                </div>
                </Col>
              </Row>
              {
                paymenttypemethod == 'card' 
                ?
                <Row>
                <Col xs="12">
                <div class="form-group">
                      <label className={styles.label} for="exampleInputEmail1">
                        Cardholder Name
                      </label>
                      <Input
                        invalid = {errors.CardHolderName}
                        className={`${styles.input} form-control inside bg-light`}
                        type="text"
                        name="CardHolderName"
                        placeholder="Name"
                        onChange={(e) => onChangeEvent(e)}
                        defaultValue={CardHolderName}
                      />
                    <FormFeedback style={{display:'block'}}>{errors.CardHolderName}</FormFeedback>
                    </div>
                    <div class="form-group">
                      <label className={styles.label} for="exampleInputEmail1">
                      Card Details
                      </label>
                      
                      {/* <CreditCardInput
                          cardNumberInputProps={{ value: CardNumber, onChange: onCardNumber }}
                          cardExpiryInputProps={{ value: ExpiryMonth, onChange: onExpiryMonth }}
                          cardCVCInputProps={{ value: CVC, onChange: onCVC }}
                          fieldClassName="input"
                        /> */}
                        <CreditCardInput style={{width:'100%',border:'1px solid #009600 !important',borderRadius:'3px'}}
                            cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
                              <input
                                {...props}
                                onChange={handleCardCVCChange(e => onCVC(e))}
                                defaultValue={cardCVC}
                                invalid = {errors.cardCVC}
                              />
                            )}
                            cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
                              <input
                                {...props}
                                onChange={handleCardExpiryChange(e =>
                                  onExpiryMonth(e)
                                )}
                                defaultValue={cardExpiry}
                                invalid = {errors.cardExpiry}
                              />
                            )}
                            cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
                              <input
                                {...props}
                                onChange={handleCardNumberChange(e =>
                                  onCardNumber(e)
                                )}
                                defaultValue={cardNumber}
                                invalid = {errors.cardNumber}
                              />
                            )}
                          />
                          <FormFeedback style={{display:'block'}}><p>{errors.cardNumber}</p></FormFeedback>
                      <FormFeedback style={{display:'block'}}><p>{errors.cardExpiry}</p></FormFeedback>
                      <FormFeedback style={{display:'block'}}><p>{errors.cardCVC}</p></FormFeedback>
                    
                    </div>
                </Col>
                  
                </Row>
  
            : ''  
              }
              <Row>
              <Col xs="6">
                    <Button
                      className={styles.buttonBack}
                      onClick={()=>props.setActiveStep(0)}
                    >
                    Back
                  </Button>
                </Col>
                <Col xs="6">
                  <Button
                    className={styles.buttonPayment}
                    onClick={(e) => validateStepTwo(e)}
                  >
                    Next
                  </Button>
                  </Col>
                  
              </Row>
            </CardBody>
            {/* <img src={payment} className={`img img-fluid pl-4 pr-4 pb-3`} /> */}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default PaymentMethod;
