import React, { useState } from "react";
import styles from "./myproduct.module.scss";

import { Row, Col, Container, Table, CustomInput, Button } from "reactstrap";

import { Link } from "react-router-dom";
import ProductTypeInfoDetail from "../../ProductTypeInfoDetail/ProductTypeInfoDetail";
import axios from 'axios';
import { useAuth } from "../../../context/auth";
import Constant from '../../../constant.json';
import Swal from 'sweetalert2';
import "./override.scss";
const MyProductType = (props) => {
  const auth = useAuth();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    
    setModal(!modal)
    props.reloadtable();

  };
  let productinfo = props.ProductData;
  
  if(typeof  productinfo == 'undefined'){
    productinfo = {};
  }

  const deleteProduct = async(product_id) => {
    let data = [];
     const config = {
      method: 'delete',
      url: Constant.API_URL + '/api/product_type/'+product_id,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    await axios(config)
    .then(function (response) {
      let result = response.data;
          if(result.status == 1) {
            
            Swal.fire({
              icon: 'success',
              text: result.message,
            });
            props.reloadtable();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: result.message,
            });
            
          }
      
    }).catch(err => {
          
      let formattedErrors = {};
      formattedErrors['error_info'] = "There is some error please contact admin."
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: formattedErrors['error_info'],
      });
      
    }) 
  }

  const changeProductstatus = async(id,status) => {
    
    const config = {
     method: 'put',
     url: Constant.API_URL + '/api/product_type/'+id,
     headers: {
       'Content-Type': 'application/json',
       'x-access-token': auth.authTokens
     },
     data:{
       status : status
     }
   }
   await axios(config)
   .then(function (response) {
     let result = response.data;
     if(result.status == 1) {
       props.reloadtable();    
       Swal.fire({
         icon: 'success',
         text: result.message,
       });
       
     } else {
       Swal.fire({
         icon: 'error',
         title: 'Oops...',
         text: result.message,
       });
       
     }
     
   }).catch(err => {
         
     let formattedErrors = {};
     formattedErrors['error_info'] = "There is some error please contact admin."
     Swal.fire({
       icon: 'error',
       title: 'Oops...',
       text: formattedErrors['error_info'],
     });
     
   })  
   
 }
  return (
    <>
      <ProductTypeInfoDetail modal={modal}  toggle={toggle}  productinfo={productinfo} />
      <Row className={`${styles.main} mt-3`}>
        <Col xs="4" md="4" sm="4">
          <div className={styles.product}>
            <h6>{productinfo.name} </h6>
          </div>
        </Col>
        
        <Col xs="4" md="4" sm="4">
          {/* <h6 className={`${styles.table_heading} ${styles.salePrice}`}> */}
          <CustomInput type="switch" id={`productstatusswitch_${productinfo.id}`} name="productstatusswitch" 
            checked={productinfo.status} 
            onChange={(e)=> changeProductstatus(productinfo.id,!productinfo.status)}
            />
          {/* </h6> */}
        </Col>
        <Col xs="4" md="4" sm="4" className={styles.tablePic}>
          <Link>
            <Button
              className={styles.iconButton}
              onClick={(e) => setModal(true)}
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
              {/* <img src={edit} /> */}
            </Button>
          </Link>
          <Button className={styles.iconButton}  onClick={(e) => deleteProduct(productinfo.id)}>
            {/* <img src={del} /> */}
            <i class="fa fa-trash" aria-hidden="true"></i>
          </Button>
        </Col>
      </Row>

      <hr />
    </>
  );
};
export default MyProductType;
