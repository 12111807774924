import React,  { useState,useEffect } from "react";

import { useAuth } from "../../context/auth";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Link, useHistory  } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
const NotFoundPage = () => {
  const auth = useAuth();
  let history = useHistory();
  
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Container style={{height:'100vh'}}>
      <div class="align-middle">
  <div class="row justify-content-center align-self-center">
      <div class="col-md-12">
          <div class="error-template">
              <h1>
                  Oops!</h1>
              <h2>
                  404 Not Found</h2>
              <div class="error-details">
                  Sorry, an error has occured, Requested page not found!
              </div>
              <div class="error-actions">
              <button style={{background:'transparent',color:'#009600',border:'1px solid #009600'}} onClick={ (e)=> history.push("/") } >Continue to homepage</button>
              </div>
          </div>
      </div>
  </div>
  </div>
      {/* <div class="text-center" style={{paddingBottom:'10rem'}}>
        <h1 class="display-3">Thank You For Order!</h1>
        <hr />
        <p>
          Having trouble? please Contact us
        </p>
        <p class="lead">
          
          <button style={{background:'transparent',color:'#009600',border:'1px solid #009600'}} onClick={ (e)=> history.push("/") } >Continue to homepage</button>
        </p>
      </div> */}
      </Container>
      
  </div>
  
  );
};

export default NotFoundPage;
