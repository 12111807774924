import React,  { useState,useEffect } from "react";

import { useAuth } from "../../context/auth";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Link, useHistory  } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Footer from "../../Components/Footer/Footer";
const TermsAndCondition = () => {
  const auth = useAuth();
  let history = useHistory();
  
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <span className="d-none d-md-block">
        <Nav />
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      <Container style={{height:'100vh'}}>
      <div class="align-middle">
  <div class="row justify-content-center align-self-center">
      <div class="text-center" style={{paddingBottom:'10rem'}}>
            <article id="post-108" class="post-108 page type-page status-publish hentry">
                  <header class="entry-header">
                      <h1 class="entry-title">Terms and Condition</h1>
                  </header>

                <div class="entry-content">
                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sodales suscipit cursus. Nulla semper tortor non aliquam malesuada. Curabitur est lacus, venenatis vitae metus sit amet, posuere tincidunt orci. Integer scelerisque risus non sem ultricies ultricies. Integer vel ligula et metus consequat placerat. Curabitur aliquam velit diam, quis egestas ante porttitor tempor. Sed pellentesque orci in massa ultrices dapibus.</p>

<p>Donec fermentum est vitae porttitor sollicitudin. Proin ultricies, leo sit amet rhoncus mattis, dui risus facilisis lacus, quis scelerisque nunc elit id risus. Vestibulum ac diam sollicitudin elit sodales vulputate et quis dolor. Proin quis tristique lorem. Fusce a dapibus lectus. Aenean dapibus mollis lacus eu posuere. Vestibulum viverra lacus nec fringilla maximus. Morbi lorem felis, pharetra in facilisis quis, malesuada vitae ante. Etiam dolor nunc, facilisis sit amet quam ullamcorper, rutrum aliquam massa. Aliquam erat volutpat. Nullam et felis a mi finibus efficitur. Etiam turpis lacus, venenatis sed sodales a, dapibus eu lectus. In feugiat fermentum interdum. Phasellus et tempus ligula, ac rhoncus dolor. Ut volutpat viverra blandit. Cras vel sagittis purus.</p>

<p>Mauris non quam ornare quam luctus auctor eget in augue. Nulla facilisi. Integer scelerisque lectus ut dapibus gravida. Praesent scelerisque enim ac quam laoreet pretium. In posuere elementum libero ut porta. Pellentesque blandit non dui sed vestibulum. Donec ut lectus volutpat, porttitor diam quis, sollicitudin velit. Nunc vel sagittis libero. Vestibulum non bibendum velit, at molestie magna. Nulla vulputate egestas maximus. </p>
                </div>
            </article>
        </div>
        </div>
        </div>
      </Container>
      <Footer />
  </div>
  );
};

export default TermsAndCondition;
