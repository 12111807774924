import React, { useState } from "react";
import style from "./productdetail.module.scss";
import { Button, Form, FormGroup, Label, Input,FormFeedback, FormText } from "reactstrap";
import Pencil from "../../Assets/pencil.svg";
import { Container, Row, Col } from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { validateAll } from 'indicative/validator';
const ProductDetail = (props) => {
  
  const [state, setState] = useState({
    ProductDescription: props.ProductDescription,
    
    errors: {},
  })

  
  const {
    ProductDescription,errors
  } = state;
  const onChangeEvent = (e) => {
const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
    // setState(prevState => ({
    //   ...prevState,
    //   ProductDescription: e.target.value
    // }));
    props.onProductDescription(value);
  };
  
  const validateSteptwo = (e) => {
    e.preventDefault();
    
    const messages = {
      'ProductDescription.required': 'Product Description cannot be empty.',
      'ProductDescription.max': 'Product description cannot be more than 400 char...',
      
      
    };
    const rules = {
      ProductDescription: 'required|max:400',
      
    };
    validateAll(state, rules, messages).then(() => {
      props.onProductDescription(ProductDescription);
      props.setActiveStep(2);
    }).catch(errors => {
      const formattedErrors = {

      };

      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
    })
    
  }
  return (
    <div className={style.container}>
      <Container>
        <Row>
          <Col xs="12" md="12">
            <Form className={style.form}>
              <div className={style.topHeading}>
                <Row className={style.topHeadingRow}>
                  <Col xs="9" className={style.left}>
                    <h5>Detail Description </h5>
                  </Col>
                  <Col xs="3" className={style.right}>
                    <img src={Pencil} />
                  </Col>
                </Row>
              </div>

              <div className={style.Input}>
                <FormGroup row className={style.inputContainer}>
                  <Label for="exampleFile" sm={12} lg={12}>
                    Product Description<span className={style.star}>*</span>
                  </Label>
                  <Col sm={12} lg={12}>
                    <textarea 
                    id="ProductDescription"
                    name="ProductDescription"
                    placeholder ="Please Enter Description max 400 char"
                    onChange={(e) => onChangeEvent(e)}
                    invalid = {errors.ProductDescription}
                    defaultValue={ProductDescription}
                    style={{ height: 200}}
                    />
                     
                    
                    <FormFeedback style={{display:'block'}}>{errors.ProductDescription}</FormFeedback>
                  </Col>
                </FormGroup>


                <FormGroup  row>
                <Col
                    sm={12}
                    lg={6}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    className={style.buttonContainerback}
                  >
                    <Button
                      onClick={(e) => props.setActiveStep(0)}
                      className="button"
                    >
                      Back
                    </Button>{" "}
                  </Col>
                  <Col
                    sm={12}
                    lg={6}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    className={style.buttonContainer}
                  >
                    <Button
                      onClick={(e) => validateSteptwo(e)}
                      className="btn"
                    >
                      Next
                    </Button>{" "}
                  </Col>
                  
                </FormGroup>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ProductDetail.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['image'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
ProductDetail.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'image'
]
export default ProductDetail;
