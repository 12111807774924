import React,  { useState,useEffect } from "react";

import { useAuth } from "../../context/auth";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Link, useHistory  } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import {dflt, noop, ajax, l, isset, ref} from '../../helpers';
import Swal from 'sweetalert2';
import Constant from '../../constant.json';
import axios from "axios";

const OrderConfirm = ({ match }) => {
  const productId = match.params.productId;
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  let history = useHistory();
  const ConfirmOrder = (e) => {
    if(isset(productId)){
      Swal.fire({
        title: 'Are you sure to confirm?',
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Save`,
      }).then((result) => {
        
        if (result.isConfirmed) {

          const config = {
            method: 'post',
            url: Constant.API_URL + '/api/products/mailorderconfirm',
            headers: {
                'Content-Type': 'application/json',
                
            },
            data : {
              id:productId,
              
            }
          }
          
           axios(config)
          .then(function (response) {
            let result = response.data;
            if(result.status == 1){
              setIsLoading(true)
              Swal.fire({
                text: result.message,
                icon : 'success'
              })
            }else{
              Swal.fire({
                text: result.message,
                type : 'error'
              })
            }
          },).catch(err => {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: "please contact admin there is a some error.",
            });
            
          }) 
          
        } 
      })  
    }else{
      Swal.fire({
        text: 'There is a some error..! please contact admin.',
        icon : 'error'
      })
    }
    
  }
  useEffect(() => {
    ConfirmOrder();
  },[productId]);
  
  return (
    <div>
    {
      isLoading ? 

            <div
            style={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            
            <span className="d-none d-md-block">
              <Nav />
            </span>
            <span className="d-block d-md-none">
              <SmallNav />
            </span>
            <Container style={{height:'auto'}}>
            <div class="text-center" style={{paddingBottom:'10rem'}}>
              <h1 class="display-3">Order Confirm!</h1>
              <hr />
              
              <p class="lead">
                <button style={{background:'transparent',color:'#009600',border:'1px solid #009600'}} onClick={ (e)=> history.push("/") } >Continue to homepage</button>
              </p>
            </div>
            </Container>
            
        </div> : ''
    }
    </div>
  );
};

export default OrderConfirm;
