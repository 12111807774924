import Dashboard from './Pages/Dashboard/Dashboard';
import MyOrders from "./Pages/MyOrders/MyOrders";
import Bussiness from "./Pages/Business/Business";
import Personalinfo from "./Components/Personalinfo/Personalinfo";
import OrderSummary from "./Components/OrderSummary/OrderSummary";
import ProductPage from "./Pages/ProductPage/ProductPage";
import Seller from "./Pages/Seller/Seller";



import AdminDashboard from './Admin/Dashboard/AdminDashboard';
import AdminSeller from './Admin/Seller/AdminSeller';
import AdminProductPage from "./Admin/ProductPage/AdminProductPage";
import AdminProductTypePage from "./Admin/ProductTypePage/AdminProductTypePage";
import Setting from "./Admin/Setting/Setting";
import Orders from "./Admin/Orders/Orders";


const ProtectedRoute = [

  { path: '/dashboard', exact: true,type:'ROLE_USER',name: 'Dashboard', component: Dashboard },
  { path: '/my-orders', exact: true,type:'ROLE_USER', name: 'MyOrders', component: MyOrders },
  { path: '/personal-info', exact: true,type:'ROLE_USER',name: 'PersonalInfo', component: Personalinfo },
  { path: '/order-summary', exact: true,type:'ROLE_USER',name: 'OrderSummary', component: OrderSummary },
  { path: '/setting', exact: true,type:'ROLE_USER',name: 'setting', component: Bussiness },
  { path: '/product-page', exact: true,type:'ROLE_USER',name: 'ProductPage', component: ProductPage },
  { path: '/seller', exact: true,type:'ROLE_USER',name: 'seller', component: Seller },
  

  /************* Admin Route */
  { path: '/admin/dashboard',type:'ROLE_ADMIN', exact: true,name: 'Dashboard', component: AdminDashboard },
  { path: '/admin/product-page', exact: true,type:'ROLE_ADMIN',name: 'ProductPage', component: AdminProductPage },
  { path: '/admin/product-type', exact: true,type:'ROLE_ADMIN',name: 'ProductType', component: AdminProductTypePage },
   { path: '/admin/seller', exact: true,type:'ROLE_ADMIN',name: 'seller', component: AdminSeller },
   { path: '/admin/orders', exact: true,type:'ROLE_ADMIN',name: 'seller', component: Orders },
   { path: '/admin/setting', exact: true,type:'ROLE_ADMIN',name: 'setting', component: Setting },
  
  /*************End Admin Route */
];

export default ProtectedRoute;
