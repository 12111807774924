import React ,{ useState, useEffect }from "react";
import { Container } from "reactstrap";
import searchIcon from "../../Assets/search.svg";
import "./search.scss";

import ReactSearchBox from 'react-search-box'
import $ from 'jquery'; 
const Search = ({ query,queryproduct, handleSearch,handleSearchproduct, fetchData,product,fetchDatasearch }) => {
  
  const [focused, setFocus] = useState("");
  
  useEffect(() => {

  },[focused])
  $("input[type=search]").focusout(function () {
    setFocus('');
  });
  
  // $('#search_btn').hover(function(){

  //   $('input[type=search]').addClass('custom_text_focus');
  // });
  // $('#search_btn').mouseout(function(){

  //   $('input[type=search]').removeClass('custom_text_focus');
  // });
  
const _handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    fetchData(1, query,queryproduct)
  }
}
  return (
    <Container fluid className="p-0 main_container" style={{zIndex:"0",position:"relative"}}>
      <h1>What are you looking for? </h1>
      <section className="search-container">
        <div className="row no-gutters h-10">
      
          <div className="col-md-10 col-10" style={{marginLeft:'-1px'}}>
            <div className="input-field-container" style= {{zIndex:"99999999999999999999999",position:"relative"}}>               
              <input
              type="search"
                placeholder="Search product ..."
                value={query}
                data={product}
                onFocus={() => setFocus("search")}
                onChange = {(e)=> handleSearch(e.target.value)}
                onKeyDown={_handleKeyDown}
                onMouseEnter={() => setFocus("search")}
                onMouseLeave={() => setFocus("")}
                id="textbox"
                className={`${
                  focused === "search"
                    ? "custom_text_focus"
                    : ''
                }`}
              /> 
            </div>
          </div> 

           <div className="col-md-2 col-2" style={{marginLeft:'-1%'}}>
            <div className="search-btn-container">
              <button
                // onhover={() => setFocus("search")}
                onMouseEnter={() => setFocus("search")}
                onMouseLeave={() => setFocus("")}
                id="search_btn"
                className={`${
                  focused === "search"
                    ? "btn button custom_button_focus"
                    : 'btn button'
                }`}
                onClick={() => fetchData(1, query,queryproduct)}
              >
                <i class="fa fa-search" aria-hidden="true"></i>

              </button>
            </div>
          </div> 
        </div>
      </section>
    </Container>
  );
};

export default Search;
