import React, { useState } from "react";
import styles from "./checkout.module.scss";
import axios from "axios";
import { Container, Modal } from "reactstrap";
import PersonalInfo from "../Personalinfo/Personalinfo";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import OrderSummary from "../OrderSummary/OrderSummary";
import { Stepper } from "react-form-stepper";
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import Constant from '../../constant.json';
const Checkout = (props) => {
  const cart = props.cartItems;
  
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  
  let ItemsCart = [];
  var cartcount = cart.length;
      
  if(cartcount > 0){
    for (var i = 0; i < cartcount; i++) {
        let product = cart[i].product;
        let lineItem = {}
        lineItem['product_id'] = product.id;
        lineItem['qty'] = cart[i].qty;
        lineItem['CartId'] = cart[i].CartId;
        ItemsCart.push(lineItem);
      }
  }
  
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState({
    MobileNumber: '',
    email : '',
    StreetAddress : '',
    FirstName : '',
    LastName : '',
    City : '',
    StateName : '',
    CardHolderName : '',
    CVC : '',
    ExpiryMonth : '',
    CardNumber : '',
    ExpiryYear : '',
    totalPrice : props.totalPrice,
    UserName : '',
    fileData : '',
    paymenttypemethod : 'card',
    errors: {},
  })
  var ApiContracts = require('authorizenet').APIContracts;
  var ApiControllers = require('authorizenet').APIControllers;
  var SDKConstants = require('authorizenet').Constants;
  
  const {
    MobileNumber, email,StreetAddress,FirstName,LastName,City,StateName,CardHolderName,CVC,ExpiryMonth,CardNumber,ExpiryYear,totalPrice,UserName,fileData,paymenttypemethod,errors
  } = state;
  
  const onMobileNumber = (value) => {

    setState(prevState => ({
      ...prevState,
      MobileNumber: value
    }));
  };
  const onemail = (value) => {
    setState(prevState => ({
      ...prevState,
      email: value
    }));
  }
  const onStreetAddress = (value) => {

    setState(prevState => ({
      ...prevState,
      StreetAddress: value
    }));
  };
  const onFirstName = (value) => {

    setState(prevState => ({
      ...prevState,
      FirstName: value
    }));
  };
  const onLastName = (value) => {

    setState(prevState => ({
      ...prevState,
      LastName: value
    }));
  };
  const onCity = (value) => {

    setState(prevState => ({
      ...prevState,
      City: value
    }));
  };
  const onStateName = (value) => {

    setState(prevState => ({
      ...prevState,
      StateName: value
    }));
  };
  const onfileData =  (value) => {
    setState(prevState => ({
      ...prevState,
      fileData: value,
    }));
    
}
  const onCardHolderName = (value) => {
    setState(prevState => ({
      ...prevState,
      CardHolderName: value
    }));
  }
  const onCVC = (value) => {
    setState(prevState => ({
      ...prevState,
      CVC: value
    }));
  }
  const onExpiryMonth = (value) => {
    setState(prevState => ({
      ...prevState,
      ExpiryMonth: value
    }));
  }
  const onCardNumber = (value) => {
    setState(prevState => ({
      ...prevState,
      CardNumber: value
    }));
  }
  const onUserName = (value) => {
    setState(prevState => ({
      ...prevState,
      UserName: value
    }));
  }
  const onpaymenttypemethod = (value) => {
    setState(prevState => ({
      ...prevState,
      paymenttypemethod: value
    }));
  }
  const cardpayment = () => {
    var merchantAuthenticationType = new ApiContracts.MerchantAuthenticationType();
      merchantAuthenticationType.setName(Constant.AUTHNETID);
      merchantAuthenticationType.setTransactionKey(Constant.AUTHNETKEY);

      var creditCard = new ApiContracts.CreditCardType();
      var str = CardNumber.toString();
      const cardnumber = str.replace(/ /g, '');
      var Expiry = ExpiryMonth.toString();
      let expiry = Expiry.replace("/", "");
      const finalexpiry = expiry.replace(/ /g, '');
      
      creditCard.setCardNumber(cardnumber);
      creditCard.setExpirationDate(finalexpiry);
      creditCard.setCardCode(CVC.toString());

      var paymentType = new ApiContracts.PaymentType();
      paymentType.setCreditCard(creditCard);

      var orderDetails = new ApiContracts.OrderType();
      orderDetails.setInvoiceNumber('INV-12345');
      orderDetails.setDescription('Product Description');

      var billTo = new ApiContracts.CustomerAddressType();
      billTo.setFirstName(FirstName);
      billTo.setLastName(LastName);
      billTo.setAddress(StreetAddress);
      billTo.setCity(City);
      billTo.setState(StateName);
      billTo.setCountry("USA");
      
      var shipTo = new ApiContracts.CustomerAddressType();
      shipTo.setFirstName(FirstName);
      shipTo.setLastName(LastName);
      shipTo.setAddress(StreetAddress);
      shipTo.setCity(City);
      shipTo.setState(StateName);
      shipTo.setCountry("USA");
      
      
      var rows = [];
      var lineItemList = [];
      var cartcount = cart.length;
      
      if(cartcount > 0){
        for (var i = 0; i < cartcount; i++) {
            var lineItem_id1 = new ApiContracts.LineItemType();              
            var product = cart[i].product;
            lineItem_id1.setItemId(product.id);
            
            var name = product.name;
            var variable2 = name.substring(0, 10);
            lineItem_id1.setName(variable2);
            lineItem_id1.setDescription(product.name);
            lineItem_id1.setQuantity(cart[i].qty);
            lineItem_id1.setUnitPrice(cart[i].price);
            lineItemList.push(lineItem_id1);
          }
      }

      var lineItems = new ApiContracts.ArrayOfLineItem();
      lineItems.setLineItem(lineItemList);

      var userField_a = new ApiContracts.UserField();
      userField_a.setName('A');
      userField_a.setValue('Aval');

      var userField_b = new ApiContracts.UserField();
      userField_b.setName('B');
      userField_b.setValue('Bval');

      var userFieldList = [];
      userFieldList.push(userField_a);
      userFieldList.push(userField_b);

      var userFields = new ApiContracts.TransactionRequestType.UserFields();
      userFields.setUserField(userFieldList);

      var transactionSetting1 = new ApiContracts.SettingType();
      transactionSetting1.setSettingName('duplicateWindow');
      transactionSetting1.setSettingValue('120');
    
      var transactionSetting2 = new ApiContracts.SettingType();
      transactionSetting2.setSettingName('recurringBilling');
      transactionSetting2.setSettingValue('false');
    
      var transactionSettingList = [];
      transactionSettingList.push(transactionSetting1);
      transactionSettingList.push(transactionSetting2);
    
      var transactionSettings = new ApiContracts.ArrayOfSetting();
      transactionSettings.setSetting(transactionSettingList);
    
      var transactionRequestType = new ApiContracts.TransactionRequestType();
      transactionRequestType.setTransactionType(ApiContracts.TransactionTypeEnum.AUTHCAPTURETRANSACTION);
      transactionRequestType.setPayment(paymentType);
      transactionRequestType.setAmount(props.totalPrice);
      transactionRequestType.setLineItems(lineItems);
      transactionRequestType.setUserFields(userFields);
      transactionRequestType.setOrder(orderDetails);
      transactionRequestType.setBillTo(billTo);
      transactionRequestType.setShipTo(shipTo);
      transactionRequestType.setTransactionSettings(transactionSettings);
    
      var createRequest = new ApiContracts.CreateTransactionRequest();
      createRequest.setMerchantAuthentication(merchantAuthenticationType);
      createRequest.setTransactionRequest(transactionRequestType);
    
  
    var ctrl = new ApiControllers.CreateTransactionController(createRequest.getJSON());
	
	  ctrl.execute(function(){

		var apiResponse = ctrl.getResponse();

		var response = new ApiContracts.CreateTransactionResponse(apiResponse);

		if(response != null){
			if(response.getMessages().getResultCode() == ApiContracts.MessageTypeEnum.OK){
				if(response.getTransactionResponse().getMessages() != null){
          InsertDataOFCheckOut(JSON.stringify(response),response.getTransactionResponse().getTransId())
          .then(function (res) {
            if (res.data.status == "1") {
              setIsLoading(false);
              setDisabled(false);
              Swal.fire({
                icon: 'success',
                text: 'Successfully created transaction.',
                confirmButtonText: `OK`,
                
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  localStorage.setItem("cart", JSON.stringify([]));
                  history.push("/thanks")
                  props.toggle();
                }else{
                  localStorage.setItem("cart", JSON.stringify([]));
                  history.push("/thanks")
                  props.toggle();
                }
              })
            }else{
              setDisabled(false);
              setIsLoading(false);
              Swal.fire({
                icon: 'error',
                text: res.data.message,
                
              })
            }
            
          })
          .catch(err => {
            setDisabled(false);
            setIsLoading(false);
            const formattedErrors = {};
            formattedErrors['login_msg'] = "There is a some issue to store data."
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: formattedErrors['login_msg'],
            });
          })
          
          
					console.log('Successfully created transaction with Transaction ID: ' + response.getTransactionResponse().getTransId());
					console.log('Response Code: ' + response.getTransactionResponse().getResponseCode());
					console.log('Message Code: ' + response.getTransactionResponse().getMessages().getMessage()[0].getCode());
					console.log('Description: ' + response.getTransactionResponse().getMessages().getMessage()[0].getDescription());
          
				}
				else {
          setDisabled(false);
          setIsLoading(false);
					console.log('Failed Transaction.');
          console.log('Failed Transaction.1');
          console.log(response)
					if(response.getTransactionResponse().getErrors() != null){
            Swal.fire({
              icon: 'error',
              text: response.getTransactionResponse().getErrors().getError()[0].getErrorText(),
            });
						console.log('Error Code: ' + response.getTransactionResponse().getErrors().getError()[0].getErrorCode());
						console.log('Error message: ' + response.getTransactionResponse().getErrors().getError()[0].getErrorText());
					}
				}
			}
			else {
        setDisabled(false);
        setIsLoading(false);
				console.log('Failed Transaction. ');
        if(response.getTransactionResponse() != null && response.getTransactionResponse().getErrors() != null){
          
          Swal.fire({
            icon: 'error',
            text: "Please Enter Valid Card Details.!",
          });
				
					console.log('Error Code: ' + response.getTransactionResponse().getErrors().getError()[0].getErrorCode());
					console.log('Error message: ' + response.getTransactionResponse().getErrors().getError()[0].getErrorText());
				}
				else {
          
          Swal.fire({
            icon: 'error',
            text: "Please Enter Valid Card Details.!",
          });
					console.log('Error Code: ' + response.getMessages().getMessage()[0].getCode());
					console.log('Error message: ' + response.getMessages().getMessage()[0].getText());
				}
			}
		}
		else {
      setDisabled(false);
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        text: "There is some issue please contact admin.!",
      });
			
		}
    
	});
  }
  const SubmitAllData = async(e) => {
      setDisabled(true);
      setIsLoading(true);
      e.preventDefault();
      if(paymenttypemethod == 'card'){
       await cardpayment();
      }else{
        await bitcoinpaymet();
      }
      
  }
  const bitcoinpaymet = async() =>{
    const data = new FormData();
    data.append('email',email);
    data.append('mobile',MobileNumber);
    data.append('StateName',StateName);
    data.append('StreetAddress',StreetAddress);
    data.append('FirstName',FirstName);
    data.append('LastName',LastName);
    data.append('City',City);
    data.append('fileData',fileData);
    data.append('subtotal',props.totalPrice);
    data.append('total',props.totalPrice);
    data.append('items',JSON.stringify(ItemsCart));
     const config = {
      method: 'post',
      url: Constant.API_URL + '/api/user/createbtcinvoice',
      data :data,
     
    }
    // const config = {
    //   method: 'post',
    //   url: Constant.API_URL + '/api/user/createbtcinvoice',
    //   data :
    //    {
    //     email : email,
    //     FirstName : FirstName,
    //     LastName : LastName,
    //     total : props.totalPrice
    //    }
    // }
    await axios(config).then(function(res){
      if (res.data.status == "1") {
        let result = res.data;
        window.location.href = result.data.url;
      }
      //
    });
    
  }
  async function InsertDataOFCheckOut(APIResponse,TransId) {
    
    
    const data = new FormData();
    data.append('email',email);
    data.append('mobile',MobileNumber);
    data.append('StateName',StateName);
    data.append('StreetAddress',StreetAddress);
    data.append('FirstName',FirstName);
    data.append('LastName',LastName);
    data.append('City',City);
    data.append('fileData',fileData);
    data.append('subtotal',props.totalPrice);
    data.append('total',props.totalPrice);
    data.append('transaction_id',TransId);
    data.append('items',JSON.stringify(ItemsCart));
    data.append('transaction_response',APIResponse);
     const config = {
      method: 'post',
      url: Constant.API_URL + '/api/user/checkout',
      data :data,
      //  {
      //   email : email,
      //   mobile : MobileNumber,
      //   StateName : StateName,
      //   StreetAddress : StreetAddress,
      //   FirstName : FirstName,
      //   LastName : LastName,
      //   City : City,
      //   fileData : fileData,
      //   subtotal : props.totalPrice,
      //   total : props.totalPrice,
      //   transaction_id : TransId,
      //   items : ItemsCart,
      //   transaction_response : APIResponse,
      //   //fileData : fileData
      //  }
    }
    let rs = await axios(config);
    return rs;

  }
  return (
    <div>
      <Modal centered isOpen={props.modal} toggle={props.toggle}>
        <Container className={styles.container}>
        <a class="close" href="#" onClick={()=>props.toggle()}>×</a>
        <div className={isLoading ? "loader" : "loaderhide"}></div>
          <div>
            <Stepper className={styles.steper}
              steps={[
                { label: "Basic Info" },
                { label: "Payment" },
                { label: "Finished" },
              ]}
              activeStep={activeStep}
            />
            {activeStep === 0 ? (
              <PersonalInfo setActiveStep={setActiveStep} 
              onMobileNumber={onMobileNumber} 
              onemail={onemail} 
              onStreetAddress={onStreetAddress}  
              onFirstName={onFirstName}  
              onLastName={onLastName}  
              onCity={onCity}  
              onStateName={onStateName}  
              onfileData={onfileData}  
              MobileNumber={MobileNumber} 
              email={email} 
              StateName={StateName} 
              StreetAddress={StreetAddress} 
              FirstName={FirstName} 
              LastName={LastName} 
              City={City} 
              fileData={fileData}
              
               />
            ) : null}
            {activeStep === 1 ? (
              <PaymentMethod
                totalPrice={props.totalPrice}
                paymenttypemethod = {paymenttypemethod}
                onpaymenttypemethod = {onpaymenttypemethod}
                setActiveStep={setActiveStep}
                onCardHolderName = {onCardHolderName}
                onCVC = {onCVC}
                CVC = {CVC}
                CardHolderName = {CardHolderName}
                ExpiryMonth ={ExpiryMonth}
                CardNumber = {CardNumber}
                onExpiryMonth = {onExpiryMonth}
                onCardNumber = {onCardNumber}
                
              />
            ) : null}
            {activeStep === 2 ? (
              <OrderSummary
                totalPrice={props.totalPrice}
                setActiveStep={setActiveStep}
                onUserName = {onUserName}
                SubmitAllData = {SubmitAllData}
                disabled = {disabled}
              />
            ) : null}
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default Checkout;
