import React, { useState, useEffect } from "react";
import styles from "./numericInput.module.scss";
import {
  Container,
  Badge,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Swal from 'sweetalert2';
const NumericInput = (props) => {
  var [value, setValue] = useState(1);
  if (value < 1) {
    setValue(1);
  }
  const increment = () => {
    if(props.remaining_stock > value){
      setValue(value + 1);
    }else{
      
      Swal.fire({
        icon: 'warning',
        text: 'We are sorry..! Only '+props.remaining_stock+' quantity available.!',
      });
    }
    
  };
  const decrement = () => {
    setValue(value - 1);
  };
  useEffect(() => {
    props.setNumericInput(value);
  }, [value]);

  return (
    <div>
      <InputGroup className={styles.input}>
        <InputGroupAddon addonType="prepend">
          <button
            className={`${styles.btn_search}`}
            style={{ color: "white" }}
            onClick={decrement}
          >
            -
          </button>
        </InputGroupAddon>
        <Input
          value={value}
          readOnly
          className={styles.search_input}
          onChange={(e) => setValue(e.target.value)}
        />
        <InputGroupAddon addonType="append">
          <button
            className={styles.btn_search}
            style={{ color: "white" }}
            onClick={increment}
          >
            +
          </button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};

export default NumericInput;
