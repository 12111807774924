import React,{useState} from "react";
import { Route } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  FormFeedback, FormText, Alert
} from "reactstrap";
import Swal from 'sweetalert2';
import styles from "./infodetail.module.scss";
import FormInput from "../../FormInput/FormInput";
import { validateAll } from 'indicative/validator';
import axios from 'axios';
import { useAuth } from "../../../context/auth";
import Constant from '../../../constant.json';
import edit from "../../../Assets/edit.svg";
import dp from "../../../Assets/mari.png";
import styles_text from "../../FormInput/form-input.module.scss";
import { Modal } from "reactstrap";

const ChangePassword = (props) => {
  const auth = useAuth();
  const info = props.info;
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    password_confirmation : '',
    password : '',
    id : info.id,
    errors: {},
  })

  
  const {
    id,password_confirmation,password,errors,
  } = state;
  const onChangeEvent = (e) => {

    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  };
  async function postChangePassword() {
  

    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/auth/changepassword',
      data: {
        password: password,
        id:id
      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
    }
    const result = await axios(config);
    return result;
  
  }
  const resetpassword = (e) => {
    e.preventDefault();
    
    const messages = {
      'password.required': 'Password cannot be empty.',
      'password.confirmed': 'Password and Confirm Password cannot match.',
      'password_confirmation.required': 'Confirm Password cannot be empty.',
      
    };
    const rules = {
      password: 'required | confirmed',
      password_confirmation: 'required',
      
    };
    validateAll(state, rules, messages).then(() => {
      postChangePassword()
      .then(function (response) {
        if(response.data.status == "1") {
          let result = response.data;
          Swal.fire({
            icon: 'success',
            text: result.message,
          });
        }
        props.toggle();
      }).catch(err => {
                  
        let formattedErrors = {};
        formattedErrors['error_info'] = "There is some error please contact admin."
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: formattedErrors['error_info'],
        });
        props.toggle();
        
      })
    }).catch(errors => {
      const formattedErrors = {

      };

      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
    })
  }
  return (
    <Modal centered isOpen={props.modal} toggle={props.toggle}>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Container className="mt-3">
        <div className={styles.main}>
          <Row className={styles.row}>
            <Col xs="12" md="12" sm="12">
              <div className={styles.topHeading}>
                <Row>
                  <Col xs="8" md="8" sm="8" className={styles.left}>
                    <h3>Change Password</h3>
                  </Col>
                  <Col xs="4" xs="4" sm="4" className={styles.right}>
                    {/* <img src={edit} /> */}
                    <a className="close" href="#" onClick={()=>props.toggle()}>×</a>
                  </Col>
                </Row>
              </div>
              <hr />

              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Password
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.password}
                    type='text'
                    name="password"
                    id="password"
                    className={`${styles_text.input}`}
                    placeholder="Enter password"
                    defaultValue={info.password}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.password}</FormFeedback>
                </Col>
                
              </FormGroup>
              
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Confirm Password
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.password_confirmation}
                    type='text'
                    name="password_confirmation"
                    id="password_confirmation"
                    className={`${styles_text.input}`}
                    placeholder="Confirm password"
                    defaultValue={info.password_confirmation}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.password_confirmation}</FormFeedback>
                </Col>
              </FormGroup>
             
             </Col>

            <Button className={styles.btn} onClick={(e) => resetpassword(e)}>Reset Password</Button>
          </Row>
          <br />
        </div>
      </Container>
    </Modal>
  );
};
export default ChangePassword;
