import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  
} from "reactstrap";
import styles from "./footer.module.scss";

import facebook from "../../Assets/fb.svg";
import twitter from "../../Assets/twitter.svg";
import youtube from "../../Assets/youtube.svg";
import wifi from "../../Assets/wifi.svg";
import logo from "../../Assets/logo.svg";
const Footer = () => {
  return (
    <Container fluid className={styles.main} style={{background:'#ffffff 0% 0% no-repeat padding-box',fontFamily:'arial'}}>
      <Row>
        <Col className="col-lg-3 col-md-3 col-4">
          <div>
            <p className={`${styles.heading}`}>Contact Us</p>
            <div className={`${styles.section}`}>
              <Link to={{pathname:"https://discord.gg/xf2fkyq8Em"}} target="_blank">
              <p className="mr-2" >Our discord</p>
              </Link>
            </div>

            <div className={`${styles.section}`}>
              <p className="mr-2" >{"BlazeBroker.com"}</p>
            </div>
          </div>
        </Col>
        <Col className="col-lg-3 col-md-3 col-4">
          <div>
            <p className={`${styles.heading}`}>Useful Links</p>

            <div className={`${styles.section}`}>
              <Link to="/terms-and-condition">
                <p className="mr-2" >{"Terms & Condition"}</p>
              </Link>
            </div>
            <div className={`${styles.section}`}>
              <Link to="/faq">
                <p className="mr-2" >{"FAQs"}</p>
              </Link>
            </div>
          </div>
        </Col>
        <Col className="col-lg-3 col-md-3 col-4">
          <div>
            <p className={`${styles.heading}`}>My Account</p>

            <div className={`${styles.section}`}>
              <Link to="/suppliers-policy">
                <p className="mr-2" >{"Suppliers Policy"}</p>
              </Link>
            </div>
            {/* <div className={`${styles.social}`}>
              <Link>
                <img
                  src={facebook}
                  className={`${styles.reduceSize}`}
                  alt="fb"
                />
              </Link>
              <Link>
                <img
                  src={twitter}
                  className={`${styles.socialImage}`}
                  alt="twitter"
                />
              </Link>
              <Link>
                <img
                  src={youtube}
                  className={`${styles.socialImage}`}
                  alt="youtube"
                />
              </Link>
              <Link>
                <img
                  src={wifi}
                  className={`${styles.socialImage}`}
                  alt="wifi"
                />
              </Link>
            </div> */}
          </div>
        </Col>
        <Col className="col-lg-3 col-md-3 col-12">
          <div style={{float:'right'}}>
            <img
              src={logo}
              className={`img img-fluid ${styles.logo} `}
              alt="logo"
              style={{color:'black'}}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
