import React, { useState, useEffect } from "react";
import styles from "./numericInput.module.scss";
import {
  Container,
  Badge,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Swal from 'sweetalert2';
import Constant from '../../constant.json';
import axios from "axios";
const NumericInputCart = (props) => {
  
  var [value, setValue] = useState(props.currentValue);
 
  if (value < 1) {
    setValue(1);
  }
  const GetProductDetails = async (productId) => {
    const config = {
      method: 'get',
      url: Constant.API_URL + '/api/products/'+productId,
      
    }
   return  await axios(config)
 
    
  }
  const increment = async() => {
   await GetProductDetails(props.productId).then(async (response) => {
    let result = response.data;
      if(result.status == 1){
        const product_data = result.data;
        let  remaining_stock = product_data.remaining_stock + value;
        if(remaining_stock > value){
          setValue(value + 1);
          props.setNumericInput(value+1);
        }else{
          
          Swal.fire({
            icon: 'warning',
            text: 'We are sorry..! No more  quantity available.!',
          });
        }
      }
     
    });
   
    
  };
  const decrement = () => {
    setValue(value - 1);
    if(value > 1){
      props.setNumericInput(value-1);
    }
    
  };
  useEffect(() => {
   // props.setNumericInput(value);
  }, []);

  return (
    <div>
      <InputGroup className={styles.input}>
        <InputGroupAddon addonType="prepend">
          <button
            className={`${styles.btn_search}`}
            style={{ color: "white" }}
            onClick={decrement}
          >
            -
          </button>
        </InputGroupAddon>
        <Input
          value={value}
          readOnly
          className={styles.search_input}
          onChange={(e) => setValue(e.target.value)}
        />
        <InputGroupAddon addonType="append">
          <button
            className={styles.btn_search}
            style={{ color: "white" }}
            onClick={increment}
          >
            +
          </button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};

export default NumericInputCart;
