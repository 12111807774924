import React,  { useState,useEffect } from "react";
import styles from "./dashboard.module.scss";
import SideNav from "../../Components/SideNav/SideNav";
import TopNav from "../../Components/TopNav/TopNav";
import dp from "../../Assets/dp.jpg";
import { Input } from "reactstrap";
import { Link } from "react-router-dom";
import { Row, Col, Table, Container, Button } from "reactstrap";
import MyOrder from "../../Components/My-Order/MyOrder";
import { useAuth } from "../../context/auth";
import MyProduct from "../../Components/MyProduct/MyProduct";
import { Card, CardBody, CardTitle, Badge } from "reactstrap";
import axios from "axios";
import Pagination from '../../Components/paginetion';
import Constant from '../../constant.json';
import NavVenderSm from "../../Components/NavVenderSm/NavVenderSm";
import MetaTags from 'react-meta-tags';

const Dashboard = () => {
  const auth = useAuth();
  let image_path = localStorage.getItem('profile_image_url');
  const [isLoading, setIsLoading] = useState(false);
  const [ProductData, setProductData] = useState({});
  const [OrderData, setOrderData] = useState({});
  const [ProductType, setProductType] = useState([]);

  const [state, setState] = useState({
    
    pageSize : 10,
    pageOfItems: [],
    pageOfOrder : []
    
  })
  const {
    pageSize,pageOfItems,pageOfOrder
  } = state; //OrderData,ProductData,
  useEffect(() => {
    GetProductType();
    GetProductList();
    
    GetOrderList();
    
 
  },[]);
  const GetProductType = async () => {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/product_type',
      headers: {
        'Content-Type': 'application/json',
        
    },
    }
    await axios(config)
    .then(async function (response) {
      let data_result = response.data;
      setProductType(data_result.data)
      
    }) 
    setIsLoading(false);
  }
  async function GetProductList() {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/products/seller',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    return await axios(config)
    .then(function (response) {
      let result = response.data;
      setProductData(result.data);
      setIsLoading(false);  
    });
    
  }
  async function GetOrderList() {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/products/orderlist',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    return await axios(config)
    .then(function (response) {
      let result = response.data;
      
      setOrderData(result.data);
      setIsLoading(false);
    }) 
    
  }
  let reloadtable = () => {
    setProductData({})
    GetProductList();
  } 
  let reloadorders = () => {
    setOrderData({})
    GetOrderList();
  }
function onproductpageChange(pageOfItems) {
  
  setState({
    ...state,
    pageOfItems: pageOfItems
  });
  
}
function onorderpageChange(pageOfOrder) {
  
  setState({
    ...state,
    pageOfOrder: pageOfOrder
  });
  
}
  return (
    <div className={styles.main_div} >
      <MetaTags>
        <title>Dashboard</title>
        <meta name="description" content="Blaze Broker" />
        <meta property="og:title" content="Blaze Broker - Dashboard" />
      </MetaTags>
      <span className="d-none d-md-block">
        <TopNav />
      </span>
      <span className="d-block d-md-none">
        <NavVenderSm />
      </span>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Container className={`${styles.container} pt-4  mr-0 ml-0 p-0`} style={{ background: 'white' }}>
        <div className={styles.main}>
          <div className={styles.dp}>
            <img src={image_path != '' ? image_path : dp} className="img img-fluid" />
          </div>

          <h3>{localStorage.getItem('name')}</h3>
        </div>

        <Row>
          <Col xs={2} className="d-none d-md-block">
            <SideNav />
          </Col>
          <Col xs={12} md={12} sm={12}>
          <div>
              <Card xs={12} md={12} sm={12} className={`${styles.card} mt-4  `}>
                <CardTitle className={`${styles.headingOrder} ml-4 mt-2`}>
                  MY ORDERS
                  <Link to="my-orders">
                    <Button className={styles.butt}>View All</Button>
                  </Link>
                </CardTitle>
                <hr />

                <CardBody>
                <Container className="p-0" className={styles.tablecontainer}>
                  <Row className={styles.row}>
                  {/* <Col xs={1} md={1} style={{padding:'0'}}>
                        
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          No.
                        </h6>
                      </Col> */}
                      <Col xs={3} md={3} >
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Product Name
                        </h6>
                      </Col>
                      <Col xs={1} md={1} style={{padding:'0'}}>
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Verified
                        </h6>
                      </Col>
                      <Col xs={1} md={1} >
                        {" "}
                        <h6
                          className={(styles.table_heading, styles.productHead)}
                        >
                          Stock
                        </h6>
                      </Col>
                      <Col xs={1} md={1} >
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Qty
                        </h6>
                      </Col>
                      <Col xs={3} md={3} >
                        <h6 className={(styles.table_heading, styles.productHead)}>
                        Email
                        </h6>
                      </Col>
                      {/* <Col xs={2} md={2} style={{padding:'0'}}>
                        <h6 className={(styles.table_heading, styles.productHead)}>
                        Contact
                        </h6>
                      </Col> */}
                      {/* <Col xs={2} md={2}>
                        <h6 className={(styles.table_heading, styles.saleHead)}>
                        Date
                        </h6>
                      </Col>
                       */}
                      <Col xs={3} md={3}>
                        <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Confirm
                        </h6>
                      </Col>
                      
                    </Row>
                
                {
                      
                      OrderData.length > 0 ?
                      pageOfOrder.map((items) => (
                        
                           <MyOrder image={dp} key={items.item_id} imgDetail="Mariuana" reloadorders={reloadorders}  OrderDatas={items} />
                        )
                      ) : "No data found"
                    }
                    </Container>
                  {/* <MyOrder />
                  <MyOrder />
                  <MyOrder /> */}
                  {
                  OrderData.length > 0 ?
                  <div className="pagination-row">
                    <Pagination items={OrderData} onChangePage={onorderpageChange}  pageSize={pageSize} />
                </div>
                : ''
                }
                </CardBody>
              </Card>
            </div>
            {/* <div> */}
            <Card xs={12} md={12} sm={12} className={`${styles.card} mt-4  `}>
              <CardTitle className={`${styles.headingOrder} ml-4 mt-2`}>
                MY PRODUCTS
                <Link to="product-page">
                  <Button className={styles.butt}>View All</Button>
                </Link>
              </CardTitle>
              <hr />

              <CardBody>
              <Container className="p-0" className={styles.tablecontainer}>
                  <Row className={styles.row}>
                    <Col xs={3} md={3} style={{padding:'0'}}>
                      {" "}
                      <h6
                        className={(styles.table_heading, styles.productHead)}
                      >
                        Product
                      </h6>
                    </Col>

                    <Col xs={3} md={3} style={{padding:'0'}}>
                      <h6 className={(styles.table_heading, styles.saleHead)}>
                        Sale Price
                      </h6>
                    </Col>
                    <Col xs={3} md={3} style={{padding:'0'}}>
                      <h6 className={(styles.table_heading, styles.qtyHead)}>
                        Qty Available
                      </h6>
                    </Col>
                    <Col xs={3} md={3} style={{padding:'0'}}>
                      <h6 className={(styles.table_heading, styles.acttHead)}>
                        Action
                      </h6>
                    </Col>
                  </Row>
                  {
                      
                      ProductData.length > 0 ?
                      pageOfItems.map((item) => (
                        <MyProduct image={dp} imgDetail="Mariuana" reloadtable={reloadtable}  ProductData={item} ProductType={ProductType} />
                      )
                      ) : "No data found"
                    }
                  {/* <MyProduct image={dp} imgDetail="Mariuana" />

                  <MyProduct image={dp} imgDetail="Mariuana" />
                  <MyProduct image={dp} imgDetail="Mariuana" />
                  <MyProduct image={dp} imgDetail="Mariuana" />
                  <MyProduct image={dp} imgDetail="Mariuana" />
                  <MyProduct image={dp} imgDetail="Mariuana" />
                  <MyProduct image={dp} imgDetail="Mariuana" /> */}
                </Container>
                {
                  ProductData.length > 0 ?
                  <div className="pagination-row">
                    <Pagination items={ProductData} onChangePage={onproductpageChange}  pageSize={pageSize} />
                </div>
                : ''
                }
                
              </CardBody>
            </Card>
            {/* </div> */} 
            
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
