import React,  { useState,useEffect } from "react";
import styles from "./personalinfo.module.scss";
import { Container, Row, Col } from "reactstrap";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import {  Form, FormGroup, Label, Input,FormFeedback, FormText } from "reactstrap";
import flag from "../../Assets/pakFlag.svg";
import { validateAll } from 'indicative/validator';
import axios from "axios";
import Constant from '../../constant.json';
import { useAuth } from "../../context/auth";
const PersonalInfo = (props) => {
  const auth = useAuth();
  const [StateList, setStateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [photoid, setPhotoid] = useState(false);
  const [state, setState] = useState({
    MobileNumber: props.MobileNumber,
    email : props.email,
    StreetAddress : props.StreetAddress,
    FirstName : props.FirstName,
    LastName : props.LastName,
    City : props.City,
    StateName : props.StateName,
    fileData : props.fileData,
    
    errors: {},
  })

  
  const {
    MobileNumber, email,StreetAddress,FirstName,LastName,City,StateName,fileData,errors
  } = state;
  useEffect(() => {
    (async () => {
      await GetStateList();
      
    })();
  },[]);
  const GetStateList = async () => {
    setIsLoading(true);
    let data = [];
     const config = {
      method: 'get',
      url: Constant.API_URL + '/api/state/1',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    await axios(config)
    .then(async function (response) {
      let data_result = response.data;
      setStateList(data_result.data)
      
    }) 
    setIsLoading(false);
  }
  const onChangeEvent = (e) => {

    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  };
  const onChangecontactNo = (value) => {
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setState(prevState => ({
        ...prevState,
        MobileNumber: value
      }));
    }
  };
  const fileSelect =  (e) => {
    
    const file = e.target.files[0];
    
    setState(prevState => ({
      ...prevState,
      fileData: file,
      
    }));
    
}
  const validateStepone = (e) => {
    e.preventDefault();
    
    const messages = {
      'MobileNumber.required': 'Mobile Number cannot be empty.',
      'MobileNumber.min': 'Please enter valid Mobile Number.',
      'email.required': 'Email cannot be empty.',
      'email.email': 'Email cannot be valid.',
      'StreetAddress.required': 'Street Address cannot be empty.',
      'FirstName.required': 'First Name cannot be empty.',
      'LastName.required': 'Last Name cannot be empty.',
      'City.required': 'City cannot be empty.',
      'StateName.required': 'State cannot be empty.',
      
    };
    const rules = {
      MobileNumber: 'required|min:10',
      email: 'required|email',
      StreetAddress: 'required',
      FirstName: 'required',
      LastName: 'required',
      City: 'required',
      StateName: 'required',
      
    };
    validateAll(state, rules, messages).then(() => {
      
      props.onMobileNumber(MobileNumber);
      props.onemail(email);
      props.onStreetAddress(StreetAddress);
      props.onFirstName(FirstName);
      props.onLastName(LastName);
      props.onCity(City);
      props.onStateName(StateName);
      props.onfileData(fileData);
      props.setActiveStep(1);
      
    }).catch(errors => {
      const formattedErrors = {

      };

      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
    })
    
  }
  return (
    <Container>
      <Card className={`${styles.cardPayment} mt-4 mb-4 `}>
        <CardTitle className={`${styles.headingMain} ml-4 mt-4 mb-0`}>
          Billing Information
        </CardTitle>
        <hr />
        <div className={isLoading ? "loader" : "loaderhide"}></div>
        <CardBody className=" pt-0">
           <div className="form-group icon">
            <label for="exampleInputPassword1" className={styles.label}>
              Mobile Number
            </label>
            <Input
                    invalid = {errors.MobileNumber}
                    className={`${styles.input} form-control inside bg-light`}
                    type="text"
                    name="MobileNumber"
                    placeholder="+1"
                    value={MobileNumber}
                    defaultValue={MobileNumber}
                    onChange={(e) => onChangecontactNo(e.target.value)}
                  />
            <FormFeedback style={{display:'block'}}>{errors.MobileNumber}</FormFeedback>
          </div> 
          <div class="form-group">
            <label className={styles.label} for="exampleInputEmail1">
              Email
            </label>
            <Input
                    invalid = {errors.email}
                    className={`${styles.input} form-control inside bg-light`}
                    type="email"
                    name="email"
                    placeholder="@"
                    defaultValue={email}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.email}</FormFeedback>
          </div>
          <Row>
          <Col xs="6" md="6">
          <div class="form-group">
            <label className={styles.label} for="exampleInputEmail1">
              First Name
            </label>
            <Input
                    invalid = {errors.FirstName}
                    className={`${styles.input} form-control inside bg-light`}
                    type="text"
                    name="FirstName"
                    placeholder="First Name"
                    defaultValue={FirstName}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.FirstName}</FormFeedback>
          </div>
          </Col>
          <Col xs="6" md="6">
          <div class="form-group">
            <label className={styles.label} for="exampleInputEmail1">
              Last Name
            </label>
            <Input
                    invalid = {errors.LastName}
                    className={`${styles.input} form-control inside bg-light`}
                    type="text"
                    name="LastName"
                    placeholder="Last Name"
                    defaultValue={LastName}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.LastName}</FormFeedback>
          </div>
          </Col>
          </Row>
          <div class="form-group">
            <label className={styles.label} for="exampleInputEmail1">
              Street address
            </label>
            <Input
                    invalid = {errors.StreetAddress}
                    className={`${styles.input} form-control inside bg-light`}
                    type="text"
                    name="StreetAddress"
                    placeholder="Street address"
                    defaultValue={StreetAddress}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.StreetAddress}</FormFeedback>
          </div>
          <div class="form-group">
            <label className={styles.label} for="exampleInputEmail1">
              City
            </label>
            <Input
                    invalid = {errors.City}
                    className={`${styles.input} form-control inside bg-light`}
                    type="text"
                    name="City"
                    placeholder="City"
                    defaultValue={City}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.City}</FormFeedback>
          </div>
          
          <div class="form-group">
            <label className={styles.label} for="exampleInputEmail1">
              State
            </label>
            <Input
                              invalid = {errors.province}
                              className={styles.input}
                              type="select"
                              name="StateName"
                              id="StateName"
                              value={StateName}
                              onChange={(e) => onChangeEvent(e)}
                              defaultValue = {StateName}
                            >
                              <option value="">Please choose your State</option>
                              {
                                
                                StateList.map((item,index) => (
                                  <option key={index} value={item.name}>{item.name}</option>
                                  ))
                                
                              }
                            </Input>
                  <FormFeedback style={{display:'block'}}>{errors.StateName}</FormFeedback>
          </div>
          {photoid ? (
          <div class="form-group">
            <label className={styles.label} style={{width:'100%'}} for="exampleInputEmail1">
              ID Proof
            </label>
            <Input
                          invalid = {errors.fileData}
                          style={{ display: "none" }}
                          type="file"
                          name="file"
                          id="file"
                          
                          onChange = {(e) => fileSelect(e)}
                         // className={styles.inputfile}
                          accept="image/*"
                          className={styles.input}
                          //style={{padding:'0'}}
                        />
                        <label for="file" className={styles.filelabel}>
                        Choose File
                      </label>
          </div>
          
          )
           : '' }
           {
            fileData != '' ? <img src={URL.createObjectURL(fileData)} style={{width:'150px',padding:'10px'}} /> : ''
          }
           <Row>
            <Col xs="12">
              <Button
                className={styles.buttonPayment}
                onClick={(e) => validateStepone(e)}
              >
                Next
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};
export default PersonalInfo;
