import React,{useState} from "react";
import { Route } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  FormFeedback, FormText, Alert
} from "reactstrap";
import Swal from 'sweetalert2';
import styles from "./infodetail.module.scss";
import FormInput from "../../Components/FormInput/FormInput";
import { validateAll } from 'indicative/validator';
import axios from 'axios';
import { useAuth } from "../../context/auth";
import Constant from '../../constant.json';
import edit from "../../Assets/edit.svg";
import dp from "../../Assets/mari.png";
import styles_text from "../FormInput/form-input.module.scss";
import { Modal } from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const InfoDetail = (props) => {
  const auth = useAuth();
  const info = props.productinfo;
  
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    product_name: info.name,
    product_price: info.price,
    product_stock: info.stock,
    ProductSativa : info.sativa,
    ProductContent : info.thc,
    ProductDescription : info.description,
    ProductType : info.product_type,
    fileData : '',
    file : info.image ? Constant.API_URL + Constant.MAIN_IMAGE+ info.image : dp,
    errors: {},
  })

  
  const {
    product_name,product_price,product_stock,ProductType,ProductSativa,ProductDescription,ProductContent,fileData,file,errors,
  } = state;
  const onChangeEvent = (e) => {

    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  };
  const onChangeEventDes = (e) => {

    setState(prevState => ({
      ...prevState,
      ProductDescription: e
    }));
  };
  const fileSelect =  (e) => {
    
    const file = e.target.files[0];
    
    setState(prevState => ({
      ...prevState,
      fileData: file,
      file :URL.createObjectURL(file)
    }));
    
}
  const onSubmitSave = (e) => {
    
    e.preventDefault();
    setIsLoading(true);
    const messages = {
      'product_name.required': 'Product name cannot be empty.',
      //'product_name.max': 'Product name cannot be more than 40 char..',
      'product_price.required': 'Product price cannot be empty.',
      'product_price.above': 'Product price Must be bigger then 0.',
      'product_stock.required': 'Product stock cannot be empty.',
      'product_stock.above': 'Product stock Must be bigger then 0.',
      // 'ProductSativa.required': 'Product sativa cannot be empty.',
      'ProductSativa.above': 'Product sativa Must be bigger then 0.',
      'ProductSativa.under': 'Product sativa Must be smaller then 100.',
      'ProductContent.required': 'Product thc cannot be empty.',
      //'ProductContent.max': 'Product thc cannot be more than 40 char...',
      'ProductDescription.required': 'Product description cannot be empty.',
      'ProductDescription.max': 'Product description cannot be more than 400 char...',
      'ProductType.required': 'Product Type cannot be empty.',
    };
    const rules = {
      product_name: 'required',
      product_price: 'required|above:0',
      product_stock: 'required|above:0',
      // ProductSativa: 'required|above:0',
      ProductSativa: 'above:0|under:100',
      ProductContent: 'required',
      ProductDescription: 'required|max:400',
      ProductType :'required',
      
    };
    validateAll(state, rules, messages)
    .then(() => {
      
      postUpdateProduct()
        .then(function (response) {
          let result = response.data;
          
          if(result.status == 1) {
            
            if(fileData != ''){
              postUploadProductImage(info.id)
              .then(function (response) {
                props.toggle();
              }).catch(err => {
                      
                let formattedErrors = {};
                formattedErrors['error_info'] = "There is some error please contact admin."
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: formattedErrors['error_info'],
                });
                
              }) 
            }else{
              props.toggle();
            }
            setState({
              ...state,
              errors: {}
            });
            Swal.fire({
              icon: 'success',
              text: result.message,
            });
            
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: result.message,
            });
            
          }
         

        }).catch(err => {
          
          let formattedErrors = {};
          formattedErrors['error_info'] = "There is some error please contact admin."
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: formattedErrors['error_info'],
          });
          
        }) 
        setIsLoading(false);
    })
    .catch(errors => {
      const formattedErrors = {

      };
      
      errors.forEach(error => formattedErrors[error.field] = error.message);

      setState({
        ...state,
        errors: formattedErrors
      });
      setIsLoading(false);
    })
  }
  async function postUpdateProduct() {

    
    const config = {
      method: 'put',
      url: Constant.API_URL + '/api/products/'+info.id,
      data: {
        name: product_name,
        price: product_price,
        stock: product_stock,
        sativa : ProductSativa,
        product_type : ProductType,
        thc : ProductContent,
        description : ProductDescription,
        remaining_stock : product_stock,
      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
      
    }
    const result = await axios(config);
    return result;

  }
  async function postUploadProductImage(id) {
    const data = new FormData();
    data.append('productimage', fileData);
    data.append('id',id);
  
    const config = {
      method: 'post',
      url: Constant.API_URL + '/api/products/image',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
    }
    const result = await axios(config);
    return result;
  
  }
  return (
    <Modal centered isOpen={props.modal} toggle={props.toggle}>
      <div className={isLoading ? "loader" : "loaderhide"}></div>
      <Container className="mt-3">
        <div className={styles.main}>
          <Row className={styles.row}>
            <Col xs="12" md="12" sm="12">
              <div className={styles.topHeading}>
                <Row>
                  <Col xs="8" md="8" sm="8" className={styles.left}>
                    <h3>Edit Product</h3>
                  </Col>
                  <Col xs="4" xs="4" sm="4" className={styles.right}>
                    {/* <img src={edit} /> */}
                    <a className="close" href="#" onClick={()=>props.toggle()}>×</a>
                  </Col>
                </Row>
              </div>
              <hr />

              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Product Name
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.product_name}
                    type='text'
                    name="product_name"
                    id="product_name"
                    className={`${styles_text.input}`}
                    placeholder="Enter Product Name"
                    defaultValue={info.name}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.product_name}</FormFeedback>
                </Col>
                
              </FormGroup>
              <FormGroup className={`${styles.formInput}`} row>
                  <Label for="exampleSelect" sm={4}>
                    Product Type<span className={styles.star}>*</span>
                  </Label>
                  <Col sm={8}>
                  <Input
                      invalid = {errors.ProductType}
                      className={styles.input}
                      type="select"
                      name="ProductType"
                      id="ProductType"
                      onChange={(e) => onChangeEvent(e)}
                      defaultValue={info.product_type}
                    >
                      <option value="">Select Product Type</option>
                      {
                        
                        props.ProductType.map((item,index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                          ))
                        
                      }
                    </Input>
                    <FormFeedback style={{display:'block'}}>{errors.ProductType}</FormFeedback>
                  </Col>
                </FormGroup>
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                   % Sativa to Indica
                  {/* <span className={styles_text.star}>*</span>{" "} */}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.ProductSativa}
                    type='number'
                    name="ProductSativa"
                    id="ProductSativa"
                    className={`${styles_text.input}`}
                    placeholder="Enter Product Name"
                    defaultValue={info.sativa}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.ProductSativa}</FormFeedback>
                </Col>
                
              </FormGroup>
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                   THC Content
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.ProductContent}
                    type='text'
                    name="ProductContent"
                    id="ProductContent"
                    className={`${styles_text.input}`}
                    placeholder="Enter Product Name"
                    defaultValue={info.thc}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.ProductContent}</FormFeedback>
                </Col>
                
              </FormGroup>
              
              
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Sale Price
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.product_price}
                    type='number'
                    name="product_price"
                    id="product_price"
                    className={`${styles_text.input}`}
                    placeholder="$"
                    defaultValue={info.price}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.product_price}</FormFeedback>
                </Col>
              </FormGroup>
              {/* <FormInput label="Sale Price" place="$" type="number" /> */}

              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                  Qty Available
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                  <Input
                  invalid = {errors.product_stock}
                    type='number'
                    name="product_stock"
                    id="product_stock"
                    className={`${styles_text.input}`}
                    placeholder="Qty"
                    defaultValue={info.stock}
                    onChange={(e) => onChangeEvent(e)}
                  />
                  <FormFeedback style={{display:'block'}}>{errors.product_stock}</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row className={`${styles_text.main}`}>
                <Label
                  for="exampleEmail"
                  className={`${styles_text.label} ${props.labelSize ? styles_text.labelSize : ""
                    }`}
                  sm={4}
                >
                   Product Description
                  <span className={styles_text.star}>*</span>{" "}
                </Label>
                <Col sm={8}>
                <textarea 
                    id="ProductDescription"
                    name="ProductDescription"
                    placeholder ="Please Enter Description max 400 char"
                    onChange={(e) => onChangeEvent(e)}
                    invalid = {errors.ProductDescription}
                    defaultValue={info.description}
                    style={{ height: 200}}
                    />
                {/* <ReactQuill
                    theme='snow'
                    invalid = {errors.ProductDescription}
                    name="ProductDescription"
                    onChange={(e) => onChangeEventDes(e)}
                    modules={InfoDetail.modules}
                    formats={InfoDetail.formats}
                    bounds={'.app'}
                    defaultValue={info.description}
                  /> */}
                  <FormFeedback style={{display:'block'}}>{errors.ProductDescription}</FormFeedback>
                </Col>
                
              </FormGroup>
              {/* <FormInput label="Qty Available" place="Qty" type="number" /> */}

              <FormGroup className={`${styles.formInput} `} row>
                <Col sm={12}>
                  <Row>
                    <Col xs="12" md="12" sm="12">
                      <div className={styles.left}>
                        <img src={
                          file
                          } />
                      </div>
                      <span>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          name="file"
                          id="file"
                          onChange = {(e) => fileSelect(e)}
                          className={styles.inputfile}
                        />
                      </span>
                      <label for="file" className={styles.label}>
                        Choose File
                      </label>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </Col>

            <Button className={styles.btn} onClick={(e) => onSubmitSave(e)}>Save</Button>
          </Row>
          <br />
        </div>
      </Container>
    </Modal>
  );
};
InfoDetail.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['image'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
InfoDetail.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'image'
]
export default InfoDetail;
