import React from "react";
import { Route } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Card,
} from "reactstrap";
import styles from "./seller.module.scss";
import FormInput from "../../Components/FormInput/FormInput";
import SideNav from "../../Components/SideNav/SideNav";
import Navigator from "../../Components/Navigator/Navigator";
// import Cards from '../../Components/Card/card';
import edit from "../../Assets/edit.svg";
import down from "../../Assets/DownArrow.svg";
import up from "../../Assets/UpArrow.svg";
import barChart from "../../Assets/Bar-Chart.svg";
import barChart2 from "../../Assets/Bar-Chart2.svg";
import barChart3 from "../../Assets/Bar-Chart3.svg";
import essential from "../../Assets/essential.svg";
import trendChart from "../../Assets/top-trend.svg";
import overallTrendChart from "../../Assets/overall-trend.svg";
import flag from "../../Assets/flag.svg";
import TopNav from "../../Components/TopNav/TopNav";

const Seller = (props) => {
  return (
    <div className={` ${styles.sellerMain} `}>
      <span className="d-none d-md-block">
        <TopNav />
      </span>
      {/* <Container > */}
      <br />
      <div className={styles.main}>
        <Row>
          <Col xs="2" md="2" className="d-none d-md-block">
            <SideNav />
          </Col>

          <Col xs="12" sm="7" md="7" className={`${styles.formMain}  `}>
            <Container>
              <div className={styles.mainHeading}>
                <h1>My Account</h1>
              </div>
              <Navigator />
              <div className={styles.form}>
                <div className={styles.topHeading}>
                  <Row>
                    <Col xs="9" className={styles.left}>
                      <h5>Seller Information</h5>
                    </Col>
                    <Col xs="3" className={styles.right}>
                      {" "}
                      <img src={edit} />
                    </Col>
                  </Row>
                </div>
                <br />

                <Row>
                  <Col md="9" sm="9" xs="12">
                    <FormInput
                      label="Full Name"
                      type="text"
                      place="Owner Name"
                    />
                  </Col>
                  <Col xs="3"></Col>
                </Row>

                <Row>
                  <Col md="9" sm="9" xs="12">
                    <FormGroup className={`${styles.formInput}`} row>
                      <Label for="exampleSelect" sm={4}>
                        Phone/Mobile Number
                        <span className={styles.star}>*</span>
                      </Label>
                      <Col sm={8}>
                        <Input
                          className={styles.input}
                          type="number"
                          placeholder="        +92"
                          name="select"
                          id="exampleSelect"
                        ></Input>
                        <img className={`${styles.icon}  `} src={flag} />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col xs="3"></Col>
                </Row>

                <Row>
                  <Col md="9" sm="9" xs="12">
                    <FormInput label="Email Address" type="email" place="@" />
                  </Col>
                  <Col xs="3"></Col>
                </Row>

                <Row>
                  <Col md="9" sm="9" xs="12">
                    <FormInput
                      label="National Id Card Number"
                      type="number"
                      place="00000-0000000-0"
                    />
                  </Col>
                  <Col xs="3"></Col>
                </Row>

                <Row>
                  <Col md="9" sm="9" xs="12">
                    <FormInput
                      label="Home Address"
                      type="text"
                      place="House No# 123, 1st Floor, ABC Road"
                    />
                  </Col>
                  <Col xs="3"></Col>
                </Row>

                <Row>
                  <Col md="9" sm="9" xs="12">
                    <FormGroup className={`${styles.formInput}`} row>
                      <Label for="exampleSelect" sm={4}>
                        Province<span className={styles.star}>*</span>
                      </Label>
                      <Col sm={8}>
                        <Input
                          className={styles.input}
                          type="select"
                          name="select"
                          id="exampleSelect"
                        >
                          <option>Please choose your Province</option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col xs="3"></Col>
                </Row>

                <Row>
                  <Col md="9" sm="9" xs="12">
                    <FormGroup className={`${styles.formInput}`} row>
                      <Label for="exampleSelect" sm={4}>
                        City<span className={styles.star}>*</span>
                      </Label>
                      <Col sm={8}>
                        <Input
                          className={styles.input}
                          type="select"
                          name="select"
                          id="exampleSelect"
                        >
                          <option>Please choose your City</option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col xs="3"></Col>
                </Row>

                <Row>
                  <Col md="9" sm="9" xs="12">
                    <FormGroup className={`${styles.formInput} `} row>
                      <Label for="exampleSelect" sm={4}>
                        Area<span className={styles.star}>*</span>
                      </Label>
                      <Col sm={8}>
                        <Input
                          className={styles.input}
                          type="select"
                          name="select"
                          id="exampleSelect"
                        >
                          <option>Please choose your Area</option>
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col xs="3"></Col>
                </Row>

                <Row>
                  <Col md="9" sm="9" xs="12">
                    <FormGroup className={`${styles.formInput} `} row>
                      <Label for="exampleSelect" sm={4}>
                        Holiday Mode
                      </Label>
                      <Col sm={8}>
                        <CustomInput
                          className={styles.customInput}
                          type="switch"
                          id="exampleCustomSwitch"
                          name="customSwitch"
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col xs="3"></Col>
                </Row>

                <Row>
                  <Col md="9" sm="9" xs="12">
                    <FormGroup className={`${styles.formInput} `} row>
                      <Col sm={4}></Col>
                      <Col sm={8}>
                        <Row>
                          <Col xs="6">
                            {" "}
                            <Label for="exampleDate">From Date</Label>
                            <Input
                              className={styles.input}
                              type="date"
                              name="date"
                              id="exampleDate"
                              placeholder="From Date "
                            />
                          </Col>
                          <Col xs="6">
                            {" "}
                            <Label for="exampleDate">To Date</Label>
                            <Input
                              className={styles.input}
                              type="date"
                              name="date"
                              // id="exampleDate"
                              placeholder="To Date"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col xs="3"></Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12">
                    <Button className={styles.btn}>Save</Button>
                  </Col>
                </Row>
                <br />
              </div>
            </Container>
          </Col>
          <Col md="3" sm="3" xs="12">
            <Container>
              <br />
              <br className="d-none d-md-block" />

              <Row>
                <Col xs="12">
                  <div className={styles.topCard}>
                    <Row>
                      <Col xs="7" className={`${styles.alignLeft} `}>
                        <h6>Total Views </h6>
                        {/* <br /> */}
                        <h3>246K</h3>
                        <p className={styles.danger}>
                          <span>
                            <img src={down} className="img img-fluid" />
                          </span>{" "}
                          13.8% This Month
                        </p>
                      </Col>
                      <Col xs="5" className={`${styles.barChart} p-0 pr-3`}>
                        <img src={barChart} className={`img img-fluid`} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" className="pt-2">
                  <div className={styles.topCard}>
                    <Row>
                      <Col xs="7" className={`${styles.alignLeft} `}>
                        <h6>Products Sold </h6>
                        {/* <br /> */}
                        <h3>2453</h3>
                        <p className={styles.success}>
                          <span>
                            <img src={up} className="img img-fluid" />
                          </span>{" "}
                          13.8% This Month
                        </p>
                      </Col>
                      <Col xs="5" className={`${styles.barChart} p-0 pr-3`}>
                        <img src={barChart2} className={`img img-fluid`} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" className="pt-2">
                  <div className={styles.topCard}>
                    <Row>
                      <Col xs="7" className={`${styles.alignLeft} `}>
                        <h6>Total Earnings </h6>
                        {/* <br /> */}
                        <h3>Rs 39,500</h3>
                        <p className={styles.danger}>
                          <span>
                            <img src={down} className="img img-fluid" />
                          </span>{" "}
                          13.8% This Month
                        </p>
                      </Col>
                      <Col xs="5" className={`${styles.barChart} p-0 pr-3`}>
                        <img src={barChart3} className={`img img-fluid`} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs="12" className="pt-2">
                  <div className={styles.topCard}>
                    <Row>
                      <Col xs="12" className={`${styles.alignLeft} `}>
                        <h6>Essential Tips </h6>
                        {/* <br /> */}
                        <h6>
                          Unless it’s a core competency of your brand, don’t try
                          to be clever in your product description, sales page,
                          or product title.{" "}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" className={`${styles.barChart} p-0 pr-3`}>
                        <img src={essential} className={`img img-fluid`} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>
      {/* </Container> */}
    </div>
  );
};
export default Seller;
