import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Container, Card, CardBody,Input,FormFeedback } from "reactstrap";
import Lock from "../../Assets/lock.svg";
import Refund from "../../Assets/refund.svg";
import Product from "../../Assets/product.svg";
import Payment from "../../Assets/payment1.svg";
import styles from "./ordersummary.module.scss";
import { validateAll } from 'indicative/validator';
const OrderSummary = (props) => {
  const [focused, setFocus] = useState("");
  const [state, setState] = useState({
    UserName: '',
    errors: {},
    disabled : props.disabled
  })

  
  const {
    UserName,disabled,errors
  } = state;
  const onChangeEvent = (e) => {

    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  };
  const validateStepThree = (e) => {
    if (disabled) {
        return;
    }
    setState(prevState => ({
      ...prevState,
      disabled: true,
    }));
    
    e.preventDefault();
    
    // const messages = {
    //   'UserName.required': 'Name cannot be empty.',
      
    // };
    // const rules = {
    //   UserName: 'required',
      
    // };
    // validateAll(state, rules, messages).then(() => {
      setState({
        ...state,
        errors: {}
      });
      props.onUserName(UserName);
      props.SubmitAllData(e);
      
      
    // }).catch(errors => {
    //   const formattedErrors = {

    //   };

    //   errors.forEach(error => formattedErrors[error.field] = error.message);

    //   setState({
    //     ...state,
    //     errors: formattedErrors
    //   });
    // })
    
  }
  return (
    <Container>
      <Card className={`${styles.order_summary_card}  mt-4 mb-4`}>
        <div className={styles.order_summary_card_title}>
          <h3>Order Summary</h3>
          <hr />
        </div>
        <CardBody className={styles.order_summary_card_body}>
          <div className={styles.charges_cintainer}>
            <p>Sub Total</p>
            <p>{`${props.totalPrice}$ USD`}</p>
          </div>
          <div
            className={`${
              focused === "voucher"
                ? styles.custom_input_focus
                : styles.custom_input
            }`}
          >
            {/* <span>
              <i class="fas fa-money-bill-alt"></i>
            </span> */}
            {/* <input
              onFocus={() => setFocus("voucher")}
              onBlur={() => setFocus("")}
              type="text"
              placeholder="Enter Your Name"
            /> */}
             {/* <Input
                invalid = {errors.UserName}
                className={`${styles.input} bg-light form-control`}
                type="text"
                name="UserName"
                placeholder="Enter Your Name"
                onChange={(e) => onChangeEvent(e)}
              />
              <FormFeedback style={{display:'block'}}>{errors.UserName}</FormFeedback> */}
          </div>
          <div className={styles.charges_cintainer + " mt-3"}>
            <h2>Total Amount</h2>
            <h2>{`${props.totalPrice}$ USD`}</h2>
          </div>
          <div className={styles.custom_btn_container}>
            <button className="btn btn-block" disabled={props.disabled} onClick={(e) => validateStepThree(e)}>
              
              {props.disabled ? 'Sending...' : 'Place Order'}
              </button>
          </div>
          <div className={styles.custom_btn_container_back}>
            <button className="btn btn-block" onClick={(e) => props.setActiveStep(1)}>Back</button>
          </div>
          <hr />
          <div className={styles.order_summary_card_footer}>
            <img src={Lock} alt="lock" />
            <span>Secure Checkout</span>
          </div>
        </CardBody>
      </Card>

      <div className={styles.properties_container}>
        <img src={Product} alt="product" />
        <span> 100% Genius Product (per vendor) </span>
      </div>
      <div className={styles.properties_container}>
        <img src={Payment} alt="payment" />
        <span> 100% Secure Payments </span>
      </div>
    </Container>
  );
};

export default OrderSummary;
