import React, { useState } from "react";
import styles from "./seller.module.scss";

import { Row, Col, Container, Table, CustomInput, Button } from "reactstrap";
import {dflt, noop, ajax, l, isset, ref} from '../../../helpers';
import { Link } from "react-router-dom";
import SellerInfoDetail from "../../Admin/SellerInfoDetail/SellerInfoDetail";
import ChangePassword from "../../Admin/ChangePassword/ChangePassword";

import axios from 'axios';
import { useAuth } from "../../../context/auth";
import Constant from '../../../constant.json';
import Swal from 'sweetalert2';
import "./override.scss";
const SellerTable = (props) => {
  const auth = useAuth();
  const [modal, setModal] = useState(false);
  const [passwordmodal, setpasswordModal] = useState(false);
  const toggle = () => {
    info = {};
    setModal(false)
    setpasswordModal(false)
    props.reloadtable();

  };
  let info = {};
  
  if(isset(props.Data)){
    info = props.Data;
  }
  
  const changeuserstatus = async(id,status) => {
    
     const config = {
      method: 'put',
      url: Constant.API_URL + '/api/auth/changestatus',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
      },
      data:{
        id : id,
        status : status
      }
    }
    await axios(config)
    .then(function (response) {
      let result = response.data;
      if(result.status == 1) {
        props.reloadtable();    
        Swal.fire({
          icon: 'success',
          text: result.message,
        });
        
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: result.message,
        });
        
      }
      
    }).catch(err => {
          
      let formattedErrors = {};
      formattedErrors['error_info'] = "There is some error please contact admin."
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: formattedErrors['error_info'],
      });
      
    })  
    
  }
  const deleteSeller = async(seller_id) => {
    let data = [];
     const config = {
      method: 'delete',
      url: Constant.API_URL + '/api/auth/delete/'+seller_id,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth.authTokens
    },
    }
    await axios(config)
    .then(function (response) {
      let result = response.data;
          if(result.status == 1) {
            
            Swal.fire({
              icon: 'success',
              text: result.message,
            });
            props.reloadtable();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: result.message,
            });
            
          }
      
    }).catch(err => {
          
      let formattedErrors = {};
      formattedErrors['error_info'] = "There is some error please contact admin."
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: formattedErrors['error_info'],
      });
      
    }) 
  }
  return (
    <>
      <SellerInfoDetail modal={modal}  toggle={toggle}  info={info} StateList={props.StateList} />
      <ChangePassword modal={passwordmodal}  toggle={toggle}  info={info} />
      <Row className={`${styles.main} mt-3`}>
        <Col xs="2" md="2" sm="2">
          <div className={styles.product}>
            <h6>{info.name} </h6>
          </div>
        </Col>
        <Col xs="2" md="2" sm="2">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
            {info.email}
          </h6>
        </Col>
        <Col xs="2" md="2" sm="2">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
            {info.contact}
          </h6>
        </Col>
        <Col xs="2" md="2" sm="2">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
          {info.shop_name} 
          </h6>
        </Col>
        <Col xs="1" md="1" sm="1">
          <h6 className={`${styles.table_heading} ${styles.salePrice}`}>
            <CustomInput type="switch" className={`switch ${styles.table_status}`} id={`userstatusswitch_${info.id}`} name="userstatusswitch" 
            checked={info.status} 
            onChange={(e)=> changeuserstatus(info.id,!info.status)}
            />
          </h6>
        </Col>
        <Col xs="3" md="3" sm="3" className={styles.tablePic}>
          
            <Button
              className={styles.iconButton}
              onClick={(e) => setModal(true)}
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
              {/* <img src={edit} /> */}
            </Button>
          
          <Button className={styles.iconButton}  onClick={(e) => deleteSeller(info.id)} >
            {/* <img src={del} /> */}
            {/* onClick={(e) => deleteProduct(info.id)} */}
            <i class="fa fa-trash" aria-hidden="true"></i>
          </Button>
          <Button className={styles.iconButton}  onClick={(e) => setpasswordModal(true)} >
            <i class="fa fa-key" aria-hidden="true"></i>
          </Button>
        </Col>
      </Row>

      <hr />
    </>
  );
};
export default SellerTable;
