import React, { useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import styles from "./verifycode.module.scss";
import Phone from "../../Assets/Phone-OTP.svg";
import Nav from "../../Components/Nav/Nav";
import SmallNav from "../../Components/navsmall/navsmall";

import "./override.scss";
import { Link } from "react-router-dom";
const VerifyCode = (props) => {
  const [verificationCode, setVerificationCode] = useState(null);

  return (
    <React.Fragment>
      <span className="d-none d-md-block">
        <Nav />
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      <div className={styles.verify_shadow_box}>
        <h1>Enter Your Code </h1>
        <br />
        {/* <p>Sent to your mobile phone</p> */}
        <div className={styles.img_container}>
          <img src={Phone} alt="phone" />
        </div>
        <div className="mt-4 verification_input_container">
          <ReactCodeInput
            //   onChange={(e) => setVerificationCode(e)}
            values={verificationCode}
            type="text"
            fields={4}
          />
        </div>
        <div className={styles.btn_container}>
          <Link to="/reset-password">
            <button className="btn btn-block">Verify</button>
          </Link>

          <Link to="">
            <h6 className={styles.link}>Resend code</h6>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
export default VerifyCode;
