import React,{useState} from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";
import IdleTimer from 'react-idle-timer';
//import  IdleTimeOutModal  from '../src/modal/IdleModal'
import {dflt, noop, ajax, l, isset,blank, ref,makeuniqueID} from './helpers';
function PrivateRoute({ type:type,component: Component, ...rest }) {
  
  const isAuthenticated = useAuth();
  
  const { setAuthTokens,setAuthRole } = useAuth();
  const [state, setState] = useState({
    
    timeout:((1000 * 60)/2)*60,
    showModal: false,
    isTimedOut: false,
    

  
})
const {
  timeout,showModal,isTimedOut
} = state; 
let idleTimer = null;

const _onAction= (e) => {
  const sevenDaysFromNow = new Date(new Date().setHours(new Date().getHours() + 1));

  localStorage.setItem('_expertime',sevenDaysFromNow);
  setState({
    ...state,
    isTimedOut: false
  });
}
const _onActive = (e) => {
  const sevenDaysFromNow = new Date(new Date().setHours(new Date().getHours() + 1));

  localStorage.setItem('_expertime',sevenDaysFromNow);
  setState({
    ...state,
    isTimedOut: false
  });
}

const _onIdle = (e) => {
  if (isTimedOut) {
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('role','');
    localStorage.setItem('profile_image_url','');
  } else {
    // setState({
    //   ...state,
    //   showModal: true
    // });
    idleTimer.reset();
    setState({
      ...state,
      isTimedOut: true
    });
    
  }
  
}
const _tokenexpering = localStorage.getItem('_expertime');
const current_time = new Date();
if(isset(_tokenexpering)){
   
  if(Date.parse(current_time) > Date.parse(_tokenexpering)){
    
    setAuthTokens(null);
    setAuthRole(null);
    localStorage.setItem('token','');
    localStorage.setItem('name','');
    localStorage.setItem('email','');
    localStorage.setItem('role','');
    const sevenDaysFromNow = new Date(new Date().setHours(new Date().getHours() + 1));
    
    localStorage.setItem('_expertime',sevenDaysFromNow);
  }
}
  return (
    isAuthenticated.authRole == type ? 
    <>
    <IdleTimer
         ref={ref => { idleTimer = ref }}
         element={document}
         onActive={(e) => _onActive(e)}
         onIdle={(e) => _onIdle(e)}
         onAction={(e) => _onAction(e)}
         debounce={250}
         timeout={timeout} />
    <Route
      {...rest}
      render={props =>
        isAuthenticated.authTokens ? (
          <Component {...props} />
        ) : ( <Redirect to="/login" /> )
      }
    />
  
     </>
    :
      <Redirect
        to={{
          pathname: "/",

        }}
      />
  );
}
//( <Redirect to="/login" /> )
export default PrivateRoute;