import React from "react";
import styles from "./navigator.module.scss";
import { Link } from "react-router-dom";

const Navigator = (props) => {
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <Link className={styles.link} to="/seller">
          <h4>Seller information</h4>
        </Link>
        <span classNanme={styles.bar}> | </span>
        <Link className={styles.link} to="/business">
          <h4>Business information</h4>
        </Link>
        |
        <Link className={styles.link} to="/bank">
          <h4>Bank detail</h4>
        </Link>
        |
        <Link className={styles.link} to="/return">
          <h4>Return Address</h4>
        </Link>
        |
        <Link className={styles.link} to="/store">
          <h4>Store Indentity</h4>
        </Link>
      </div>
    </div>
  );
};
export default Navigator;
