import React from 'react';
import './App.css';
import AppRoutes from './Routes/AppRoutes';

function App() {
  return (
    <div className="App" style={{ background : 'white' }}>
     <AppRoutes/>
    </div>
  );
}

export default App;
