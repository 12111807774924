import React,  { useState,useEffect } from "react";

import { useAuth } from "../../context/auth";
import ThanksNav from "../../Components/ThanksNav/ThanksNav";
import SmallNav from "../../Components/navsmall/navsmall";
import { Link, useHistory  } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
const Thanks = () => {
  const auth = useAuth();
  let history = useHistory();
  
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <span className="d-none d-md-block">
        <ThanksNav />
      </span>
      <span className="d-block d-md-none">
        <SmallNav />
      </span>
      <Container style={{minHeight:'80vh'}}>
      <div class="text-center" >
        <h1 class="display-3" style={{color:'#009600'}}>Thank you for your order!</h1>
        <hr />
        <div style={{marginTop:'20%'}}>
        <p>
          Having trouble? Please<Link to={{pathname:"https://discord.gg/xf2fkyq8Em"}} target="_blank"> Contact us</Link>
        </p>
        <p class="lead">
          
          <button style={{background:'transparent',color:'#009600',border:'1px solid #009600',borderRadius:'10px',padding:'5px'}} onClick={ (e)=> history.push("/") } >Continue to homepage</button>
        </p>
        </div>
      </div>
      </Container>
      
  </div>
  );
};

export default Thanks;
